import React, {FC, useRef} from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import ChatFilesContextWrapper from 'contexts/chatFiles';

import RequesterCaseChat from 'components/RequesterChat/RequesterCaseChat';
import ChatMessagesList from 'components/RequesterChat/ChatMessagesList';
import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';

import {theme} from 'config/theme';
import ClientChatHeader from './ClientChatHeader';

const ClientChatPageContent: FC = () => {
    const wrapperRef = useRef<HTMLDivElement>();
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
            ref={wrapperRef}
        >
            <ClientScreenWrapper chat>
                <AppBar position="relative" sx={{backgroundColor: theme.palette.background.default}}>
                    <ClientChatHeader />
                </AppBar>
                <ChatFilesContextWrapper>
                    <ChatMessagesList isRequesterChat />
                </ChatFilesContextWrapper>
                <AppBar position="relative" sx={{backgroundColor: theme.palette.background.default, p: 1}}>
                    <RequesterCaseChat wrapperRef={wrapperRef} />
                </AppBar>
            </ClientScreenWrapper>
        </Box>
    );
};

export default ClientChatPageContent;
