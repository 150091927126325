import get from 'lodash/get';

import {tagColors} from 'config/theme';

export interface CheckboxChartDataType {
    data: number[];
    color: string;
}

interface OptionType {
    option: string;
    qty: number;
}

export const getMaximalSeriesLength = (cases: any): number => {
    let result = 0;
    if (Array.isArray(cases)) {
        cases.forEach(item => {
            const options: OptionType[] = get(item, 'options', []);
            if (options.length > result) {
                result = options.length;
            }
        });
    }
    return result;
};

export const getCheckboxAnalyticSeriesArray = (cases: any): CheckboxChartDataType[] => {
    const optionsQtyArray: any = [];

    const maximalSeriesLength = getMaximalSeriesLength(cases);

    for (let i = 0; i < maximalSeriesLength; i++) {
        const dataArray: number[] = [];
        const parameter = cases[i];
        const options = get(parameter, 'options', []);
        for (let j = 0; j < maximalSeriesLength; j++) {
            const option = get(options, j, null);
            const value = option ? get(option, 'qty', 0) : 0;
            dataArray.push(value);
        }
        optionsQtyArray.push(dataArray);
    }

    const maxLen = optionsQtyArray.reduce((max, {length}) => Math.max(max, length), 0);
    const results = Array.from({length: maxLen}, (_, i) => optionsQtyArray.map(col => col[i]));

    const seriesArray: CheckboxChartDataType[] = [];
    results.forEach((result: number[], index: number) => {
        seriesArray.push({
            data: result,
            color: get(tagColors, index, '#07D889'),
        });
    });

    return seriesArray;
};
