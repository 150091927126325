import React, {FC, useCallback, useContext} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {ResourceFieldFormTypes} from 'appRedux/actions/resourceFields/types';
import {GET_WORKFLOWS_STATUS_LIST} from 'appRedux/actions/workflow';
import {WorkflowItemTypes, WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSchema} from 'components/Forms/ResourceField/validation';
import {
    resourceFieldTypes,
    resourceFieldScopeTypes,
    ResourceFieldOptionType,
    isImpossibleForSelectedScopeType,
} from 'components/Forms/ResourceField/helper';

import {getTranslatedWorkflowStatus} from 'pages/admin/workflows/helper';

import {getLanguageById} from 'helpers/translationsHelper';

interface ResourceFieldFormType {
    initialValues: ResourceFieldFormTypes;
    onSubmitClicked: (values: ResourceFieldFormTypes) => void;
    isCreate?: boolean;
}

const ResourceFieldForm: FC<ResourceFieldFormType> = ({initialValues, onSubmitClicked, isCreate}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        admin: {languageList},
        profile: {profile},
        workflow: {organizationWorkflows, workflowStatusesList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getWorkflowStatuses = useCallback(
        data => dispatch({type: GET_WORKFLOWS_STATUS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    if (!profile) return null;

    const currentUserLanguage = getLanguageById(languageList, profile.languageId).name;

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ResourceFieldFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            label={t('orguser.resourceFields.title')}
                            placeholder={t('orguser.resourceFields.title')}
                            component={FormikTextInput}
                            onBlur={() => {
                                if (!isCreate) {
                                    formik.submitForm();
                                }
                            }}
                        />
                        <FormControl sx={{mt: 1}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.workflow')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.workflow')}
                                name={'workflow'}
                                value={formik.values.workflow}
                                onChange={e => {
                                    formik.handleChange(e);
                                    const selectedWorkflow = organizationWorkflows.find(
                                        organizationWorkflow => organizationWorkflow.id === Number(e.target.value),
                                    );
                                    if (selectedWorkflow) {
                                        getWorkflowStatuses({
                                            uuid: selectedWorkflow.uuid,
                                            showAlert,
                                        });
                                    }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled={!isCreate}
                                fullWidth
                            >
                                {organizationWorkflows &&
                                    organizationWorkflows.map((item: WorkflowItemTypes, index: number) => {
                                        return (
                                            <MenuItem key={`workflow-${index}`} value={item.id}>
                                                <Typography sx={{fontWeight: 600}}>{item.title}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.scope')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.scope')}
                                name={'scope'}
                                value={formik.values.scope}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled={!isCreate}
                                fullWidth
                            >
                                {resourceFieldScopeTypes &&
                                    resourceFieldScopeTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`scope-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <InputLabel>{t('orguser.resourceFields.type')}</InputLabel>
                            <Select
                                label={t('orguser.resourceFields.type')}
                                name={'type'}
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled={!isCreate}
                                fullWidth
                            >
                                {resourceFieldTypes &&
                                    resourceFieldTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        if (isImpossibleForSelectedScopeType(formik.values.scope, value)) {
                                            return null;
                                        }
                                        return (
                                            <MenuItem key={`type-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        {formik.values.workflow ? (
                            <>
                                <Divider sx={{mt: 3, mb: 3}} />
                                <Typography sx={{fontWeight: 700, mb: 1}}>{`${t(
                                    'orguser.resourceFields.statusBinding',
                                )}:`}</Typography>
                                <FormControl sx={{mt: 2}} fullWidth>
                                    <InputLabel>
                                        {t('orguser.resourceFields.statusBindingTypes.statusReservation')}
                                    </InputLabel>
                                    <Select
                                        label={t('orguser.resourceFields.statusBindingTypes.statusReservation')}
                                        name={'statusReservation'}
                                        value={formik.values.statusReservation}
                                        onChange={e => {
                                            formik.handleChange(e);
                                            if (!isCreate) {
                                                formik.submitForm();
                                            }
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                        fullWidth
                                    >
                                        {workflowStatusesList &&
                                            workflowStatusesList.map((item: WorkflowStatusItemTypes) => {
                                                const {id, uuid} = item;
                                                return (
                                                    <MenuItem key={`statusReservation-${uuid}`} value={id}>
                                                        <Typography sx={{fontWeight: 600}}>
                                                            {getTranslatedWorkflowStatus(item, currentUserLanguage)}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{mt: 2}} fullWidth>
                                    <InputLabel>
                                        {t('orguser.resourceFields.statusBindingTypes.statusBooking')}
                                    </InputLabel>
                                    <Select
                                        label={t('orguser.resourceFields.statusBindingTypes.statusBooking')}
                                        name={'statusBooking'}
                                        value={formik.values.statusBooking}
                                        onChange={e => {
                                            formik.handleChange(e);
                                            if (!isCreate) {
                                                formik.submitForm();
                                            }
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                        fullWidth
                                    >
                                        {workflowStatusesList &&
                                            workflowStatusesList.map((item: WorkflowStatusItemTypes) => {
                                                const {id, uuid} = item;
                                                return (
                                                    <MenuItem key={`statusBooking-${uuid}`} value={id}>
                                                        <Typography sx={{fontWeight: 600}}>
                                                            {getTranslatedWorkflowStatus(item, currentUserLanguage)}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{mt: 2}} fullWidth>
                                    <InputLabel>
                                        {t('orguser.resourceFields.statusBindingTypes.statusClearing')}
                                    </InputLabel>
                                    <Select
                                        label={t('orguser.resourceFields.statusBindingTypes.statusClearing')}
                                        name={'statusClearing'}
                                        value={formik.values.statusClearing}
                                        onChange={e => {
                                            formik.handleChange(e);
                                            if (!isCreate) {
                                                formik.submitForm();
                                            }
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                        fullWidth
                                    >
                                        {workflowStatusesList &&
                                            workflowStatusesList.map((item: WorkflowStatusItemTypes) => {
                                                const {id, uuid} = item;
                                                return (
                                                    <MenuItem key={`statusClearing-${uuid}`} value={id}>
                                                        <Typography sx={{fontWeight: 600}}>
                                                            {getTranslatedWorkflowStatus(item, currentUserLanguage)}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </>
                        ) : null}
                        {isCreate && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 3,
                                }}
                            >
                                <AgentSaveButton isSubmit />
                            </Box>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldForm;
