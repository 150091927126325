import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {INVITES_GET_USER_LIST} from 'appRedux/actions/invites';
import {InviteTypes} from 'appRedux/actions/invites/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {MediaContext} from 'contexts/media/context';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';
import EmptyArea from 'components/EmptyArea/EmptyArea';
import Search from 'components/ClientScreenComponents/Search';

import LanguageDropdownSwitcher from 'pages/client/formsList/LanguageDropdownSwitcher';
import InviteListItem from 'pages/client/invitesList/InviteListItem';

const InvitesListForRequesterPage: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {isMobile} = useContext(MediaContext);
    const {showAlert} = useContext(AlertContext);

    const [searchValue, setSearchValue] = useState<string>('');

    const getInvitesListForRequester = useCallback(
        data => dispatch({type: INVITES_GET_USER_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        invites: {isLoading, userInvites},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (!profile) return null;

    useEffect(() => {
        if (profile && profile.isMultiRoleUser) {
            getInvitesListForRequester({
                showAlert,
            });
        }
    }, [profile.languageId]);

    const filteredInvites = searchValue
        ? userInvites.filter(item => item.formTitle.toLowerCase().includes(searchValue))
        : userInvites;

    return (
        <ClientScreenWrapper>
            <Typography
                align="center"
                sx={{
                    fontSize: isMobile ? 24 : 36,
                    fontWeight: 600,
                    mt: isMobile ? 1 : 3,
                    mb: 3,
                }}
            >
                {t(`common.menu.invites`)}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Search searchValue={searchValue} setSearchValue={setSearchValue} />
                <EmptyArea />
                <LanguageDropdownSwitcher />
            </Box>
            {filteredInvites.length > 0
                ? filteredInvites.map((item: InviteTypes) => {
                      return <InviteListItem key={`invite-${item.uuid}`} item={item} />;
                  })
                : isLoading
                ? null
                : t('common.none')}
        </ClientScreenWrapper>
    );
};

export default InvitesListForRequesterPage;
