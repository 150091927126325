import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import Box from '@mui/material/Box';

import {FORMS_LIST, FORM_VERSION_STRUCTURE} from 'appRedux/actions/forms';
import {
    GET_RESOURCE_FIELDS_WORKFLOW,
    GET_RESOURCE_FIELD_CALENDAR_ORGANIZATION,
    GET_RESOURCE_FIELD_BUDGET_ORGANIZATION,
    GET_RESOURCE_FIELD_SLOT_ORGANIZATION,
} from 'appRedux/actions/resourceFields';
import {RootReducer} from 'appRedux/reducers';
import {FormVersionTypes} from 'appRedux/actions/forms/types';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import FormStructure from 'pages/admin/updateForm/partials/FormStructure/FormStructure';
import FormVersionInformation from 'pages/admin/formVersion/FormVersionInformation';
import FormVersionCard from 'pages/admin/formVersion/FormVersionCard';

const FormVersionComponent: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {versionUuid} = useParams();

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {setPageTitle, setBackLink, setBackLinkLabel} = useContext(RouteContext);
    const {isFormEditEnabled} = useContext(PermissionContext);

    const [isForceEditClicked, setIsForceEditClicked] = useState<boolean>(false);

    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);

    const getFormStructureByVersion = useCallback(
        data => dispatch({type: FORM_VERSION_STRUCTURE.REQUEST, payload: data}),
        [dispatch],
    );

    const getResourceFieldCalendarsByOrganization = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELD_CALENDAR_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getResourceFieldBudgetsByOrganization = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELD_BUDGET_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getResourceFieldSlotsByOrganization = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELD_SLOT_ORGANIZATION.REQUEST, payload: data}),
        [dispatch],
    );

    const getResourceFieldsListByWorkflow = useCallback(
        data => dispatch({type: GET_RESOURCE_FIELDS_WORKFLOW.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        admin: {formInfo},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const formVersions: FormVersionTypes[] = get(formInfo, 'versions', []);

    const currentVersion = formVersions.find(item => item.uuid === versionUuid);

    useEffect(() => {
        if (currentVersion) {
            setPageTitle('');
            setBackLink(null);
            setBackLinkLabel(t('common.links.backToForms'));
        }
    }, [currentVersion]);

    useEffect(() => {
        if (currentVersion) {
            getFormsList({});
            getFormStructureByVersion({
                uuid: versionUuid,
                showAlert,
            });
        }
    }, [currentVersion, versionUuid]);

    useEffect(() => {
        if (formInfo && formInfo.workflow) {
            getResourceFieldsListByWorkflow({
                id: formInfo.workflow,
                showAlert,
            });
        }
    }, [formInfo]);

    useEffect(() => {
        if (myOrganization) {
            getResourceFieldCalendarsByOrganization({
                id: myOrganization.id,
                showAlert,
            });
            getResourceFieldSlotsByOrganization({
                id: myOrganization.id,
                showAlert,
            });
            getResourceFieldBudgetsByOrganization({
                id: myOrganization.id,
                showAlert,
            });
        }
    }, [myOrganization]);

    if (!currentVersion) return null;

    return (
        <Box>
            <ClientSectionWrapper title={t('orguser.forms.versionInformation')}>
                <FormVersionInformation
                    item={currentVersion}
                    isForceEditClicked={isForceEditClicked}
                    setIsForceEditClicked={setIsForceEditClicked}
                />
            </ClientSectionWrapper>
            <ClientSectionWrapper title={t('orguser.forms.formCard')}>
                <FormVersionCard item={currentVersion} />
            </ClientSectionWrapper>
            <ClientSectionWrapper title={t('orguser.forms.formBuilder')}>
                <FormStructure
                    formId={Number(currentVersion.formId)}
                    viewOnly={!isFormEditEnabled || (currentVersion.casesQty > 0 && !isForceEditClicked)}
                />
            </ClientSectionWrapper>
        </Box>
    );
};

export default FormVersionComponent;
