import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {ResourceFieldBudgetFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormikSelectInput from 'components/AgentScreenComponents/_form/FormBuilderSelectInput';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationBudgetSchema} from 'components/Forms/ResourceField/validation';
import {
    resourceFieldBudgetTypes,
    resourceFieldBudgetResetIntervalTypes,
    ResourceFieldOptionType,
    RESOURCE_FIELD_BUDGET_TYPE_MONEY,
} from 'components/Forms/ResourceField/helper';

interface ResourceFieldBudgetFormType {
    initialValues: ResourceFieldBudgetFormTypes;
    onSubmitClicked: (values: ResourceFieldBudgetFormTypes) => void;
    isCreate?: boolean;
}

const ResourceFieldBudgetForm: FC<ResourceFieldBudgetFormType> = ({initialValues, onSubmitClicked, isCreate}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationBudgetSchema}
            onSubmit={values => {
                onSubmitClicked({
                    ...values,
                    amount:
                        values.type === RESOURCE_FIELD_BUDGET_TYPE_MONEY && typeof values.amount === 'string'
                            ? Number(parseFloat(String(values.amount).replace(/\s/g, '')).toFixed(2)) * 100
                            : values.amount,
                });
            }}
        >
            {(formik: FormikProps<ResourceFieldBudgetFormTypes>) => {
                const isMoneyType = formik.values.type === RESOURCE_FIELD_BUDGET_TYPE_MONEY;
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            label={t('orguser.resourceFields.title')}
                            placeholder={t('orguser.resourceFields.title')}
                            component={FormikTextInput}
                        />
                        <Box sx={{mt: 1}}>
                            <MdxEditorSmallField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.resourceFields.description')}
                            />
                        </Box>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <Field
                                as="select"
                                required
                                name="type"
                                label={t('orguser.resourceFields.type')}
                                placeholder={t('orguser.resourceFields.type')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {resourceFieldBudgetTypes &&
                                    resourceFieldBudgetTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`type-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Field>
                        </FormControl>
                        <Box sx={{mt: 1, mb: 1}}>
                            <Field
                                required
                                type={isMoneyType ? 'text' : 'number'}
                                name="amount"
                                label={t('orguser.resourceFields.amount')}
                                placeholder={t('orguser.resourceFields.amount')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <FormControl sx={{mt: 2}} required fullWidth>
                            <Field
                                as="select"
                                required
                                name="resetInterval"
                                label={t('orguser.resourceFields.resetInterval')}
                                placeholder={t('orguser.resourceFields.resetInterval')}
                                options
                                component={FormikSelectInput}
                                fullwidth
                            >
                                {resourceFieldBudgetResetIntervalTypes &&
                                    resourceFieldBudgetResetIntervalTypes.map((item: ResourceFieldOptionType) => {
                                        const {label, value} = item;
                                        return (
                                            <MenuItem key={`resetInterval-${value}`} value={value}>
                                                <Typography sx={{fontWeight: 600}}>{t(label)}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                            </Field>
                        </FormControl>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldBudgetForm;
