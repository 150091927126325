import {theme} from 'config/theme';

export interface TabItemType {
    title: string;
    tabNumber: number;
    isDisabled: boolean;
}

export const getTabBackgroundColor = (isActive: boolean, isDisabled: boolean): string => {
    if (isActive) {
        return theme.palette.info.main;
    }
    if (isDisabled) {
        return theme.palette.secondary.contrastText;
    }
    return theme.palette.warning.light;
};

export const getTabTextColor = (isActive: boolean, isDisabled: boolean): string => {
    if (isActive) {
        return theme.palette.background.default;
    }
    if (isDisabled) {
        return theme.palette.info.contrastText;
    }
    return theme.palette.info.main;
};
