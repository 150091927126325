import React, {FC, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import get from 'lodash/get';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import {RequesterAllCaseItemTypes, RequestCasePageType} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import FormCheckedSvgIcon from 'assets/icons/FormCheckedSvgIcon';
import FormPendingSvgIcon from 'assets/icons/FormPendingSvgIcon';
import ChatSvgIcon from 'assets/icons/ChatSvgIcon';

import {MediaContext} from 'contexts/media/context';

import {showLinkToChat} from 'components/TopBarMenu/helper';
import EmptyArea from 'components/EmptyArea';
import StatusLabel from 'components/ClientScreenComponents/StatusLabel';

import {isFormChecked} from 'pages/client/requests/helper';
import DraftAutoRemoveAlert from 'pages/client/requests/partials/DraftAutoRemoveAlert';
import PagesListItem from 'pages/client/requests/partials/PagesListItem';
import RequesterCaseCancelButton from 'pages/client/requests/partials/RequesterCaseCancelButton';

import {getFormKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {theme} from 'config/theme';
import {routes, DATE_FORMAT_CLIENT, STATUS_PREPARED} from 'config/index';

interface PagesListType {
    isCurrentOrganizationCase: boolean;
    item: RequesterAllCaseItemTypes;
}

const PagesList: FC<PagesListType> = ({item, isCurrentOrganizationCase}) => {
    const navigate = useNavigate();

    const {isMobile, isTablet} = useContext(MediaContext);

    const {
        admin: {
            formInfo: {translations},
        },
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        if (isCurrentOrganizationCase) {
            setIsOpened(previous => !previous);
        }
    };

    const {form, pages, requestCase, isAgentAssigned, notReadMessages, checkedSections} = item;
    const {id, isChatActivated, isRemovingImpossible, caseLabel, updatedAt, removedAt, statusTitle, statusColor} =
        requestCase;

    const redirectToChat = () => {
        navigate(`${routes.REQUEST}/${id}/chat`);
    };

    const isUserVerified = get(profile, 'isVerify', false);
    const formTitleKeyword = getFormKeyword(form.id, 'title');

    return (
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box data-id={`button#requests-case-${id}`} sx={{cursor: 'pointer'}} onClick={toggleIsOpened}>
                    <Typography sx={{fontSize: 11, mb: 1}}>
                        {convertDateToCurrentLocale(updatedAt, DATE_FORMAT_CLIENT)}
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2}}>
                        {isFormChecked(pages, checkedSections) ? <FormCheckedSvgIcon /> : <FormPendingSvgIcon />}
                        <Typography sx={{fontSize: 16, fontWeight: 600, ml: 1}}>
                            {`${caseLabel} | ${getFormTranslatedLabel(translations, formTitleKeyword, form.title)}`}
                        </Typography>
                    </Box>
                </Box>
                <EmptyArea />
                {isCurrentOrganizationCase && showLinkToChat(isUserVerified, isChatActivated, isAgentAssigned) && (
                    <IconButton
                        data-id={`button#requests-case-${id}-chat`}
                        onClick={redirectToChat}
                        sx={{
                            mr: 1,
                            backgroundColor: theme.palette.info.main,
                            '&:hover': {
                                backgroundColor: theme.palette.info.main,
                            },
                        }}
                    >
                        <Badge badgeContent={notReadMessages} color="error" invisible={notReadMessages === 0}>
                            <ChatSvgIcon />
                        </Badge>
                    </IconButton>
                )}
                {!isRemovingImpossible && !isMobile && !isTablet && removedAt && (
                    <DraftAutoRemoveAlert requestCase={requestCase} />
                )}
                {requestCase.status === STATUS_PREPARED && <RequesterCaseCancelButton caseId={requestCase.id} />}
                {isUserVerified && <StatusLabel status={statusTitle} color={statusColor} />}
            </Box>

            {(isMobile || isTablet) && removedAt && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                        mb: 2,
                    }}
                >
                    <DraftAutoRemoveAlert requestCase={requestCase} />
                </Box>
            )}

            {isOpened &&
                pages.map((page: RequestCasePageType, index: number) => {
                    return <PagesListItem index={index} page={page} item={item} key={`page-${index}`} />;
                })}
        </Box>
    );
};

export default PagesList;
