import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Text} from '@react-pdf/renderer';

import {FormPageTypes, FormSectionTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseItemType} from 'appRedux/actions/requestCase/types';

import SectionPdfContent from 'pages/agent/pdfExport/pdfPartials/SectionPdfContent';
import {pdfStyles} from 'pages/agent/pdfExport/pdfPartials/PdfDocumentStyles';
import {PrintSectionType} from 'pages/agent/pdfExport/PdfEditor';
import {PdfDocumentContext} from 'pages/agent/pdfExport/pdfPartials/pdfDocumentContext';

import {getPageKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

interface PagePdfContentType {
    pageNumber: number;
    page: FormPageTypes;
    result: RequesterCaseItemType | null;
    printSections?: Record<string, PrintSectionType>;
    formId?: string;
}

const PagePdfContent: FC<PagePdfContentType> = ({page, pageNumber, result, printSections, formId}) => {
    const [t] = useTranslation();

    const {translations} = useContext(PdfDocumentContext);

    const {title, id, versionId, sections} = page;

    const keyword = getPageKeyword(Number(formId), id, versionId, 'title');

    return (
        <>
            <View style={pdfStyles.pageHeader}>
                <Text>{`${t('common.page')}.${pageNumber} ${getFormTranslatedLabel(
                    translations,
                    keyword,
                    title,
                )}`}</Text>
            </View>
            {sections &&
                sections.map((section: FormSectionTypes, index: number) => {
                    if (printSections && !printSections[section.id]?.printSection) return <></>;
                    return (
                        <View style={pdfStyles.section} key={`section-${index}`}>
                            <SectionPdfContent
                                pageId={id}
                                section={section}
                                result={result}
                                key={`section-${index}`}
                                formId={formId}
                                printComments={printSections && printSections[section.id].printComments}
                            />
                        </View>
                    );
                })}
        </>
    );
};

export default PagePdfContent;
