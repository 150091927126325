import {get, reduce} from 'lodash';
import {Range, utils, WorkSheet} from 'xlsx-js-style';

import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseTypes} from 'appRedux/actions/requestCase/types';

import {decryptStringWithKey} from 'helpers/cryptoApiHelper';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

type ColumnType = {
    letter: string;
    index: number;
};

const nextChar = (char: string, increment: number) => {
    return String.fromCharCode(char.charCodeAt(0) + increment);
};

const sheet_to_aoa = (sheet: WorkSheet) => {
    const FS = '\t';
    return utils
        .sheet_to_csv(sheet, {FS})
        .split('\n')
        .map(r => r.split(FS));
};

const fitToColumn = (arrayOfArray: string[][]) => {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(...arrayOfArray.map(a2 => (a2[i] ? a2[i].toString().length : 0))),
    }));
};

const increment = (column: ColumnType, increment: number) => {
    return {
        letter: nextChar(column.letter, increment),
        index: column.index + increment,
    };
};

const setCellBold = (sheet: WorkSheet, i: number, j: number) => {
    const cell = sheet[utils.encode_cell({r: i, c: j})];
    if (!cell) {
        return;
    }
    // Create new style if cell doesnt have a style yet
    if (!cell.s) {
        cell.s = {};
    }
    if (!cell.s.font) {
        cell.s.font = {};
    }
    // Set bold
    cell.s.font.bold = true;
};

export const fillFormData = async (
    formPages: FormPageTypes[],
    currentCase: RequesterCaseTypes,
    sheet: WorkSheet,
    unwrappedCaseKey: CryptoKey | null,
) => {
    const merge: Range[] = [];
    const {result, id, userName, userEmail, createdAt, files} = currentCase;

    utils.sheet_add_aoa(sheet, [['Requester information']], {origin: 'A1'});
    merge.push({s: {r: 0, c: 0}, e: {r: 1, c: 3}});
    utils.sheet_add_aoa(
        sheet,
        [
            ['ID', 'Last name, First name', 'Email', 'Created At'],
            [id, userName, userEmail, createdAt],
        ],
        {origin: 'A3'},
    );

    let column: ColumnType = {letter: 'E', index: 4};
    let sectionColumn: ColumnType = {letter: 'E', index: 4};
    let sectionFieldCoumn: ColumnType = {letter: 'E', index: 4};

    for (let i = 0; i < formPages.length; i++) {
        const page = formPages[i];
        const pageKeyword = `page-${page.id}`;
        const pageFields = reduce(
            page.sections,
            (sum, section) => {
                return sum + section.fields.length;
            },
            0,
        );
        const pagePopups = reduce(
            page.sections,
            (sum, section) => {
                return sum + section.popups.length;
            },
            0,
        );
        const pageElementsNumber = pageFields + pagePopups;

        const firstCol = column;
        utils.sheet_add_aoa(sheet, [[`Page ${i + 1}: ${page.title}`]], {origin: `${firstCol.letter}1`});

        if (pageElementsNumber > 0) {
            column = increment(column, pageElementsNumber - 1);
            const lastCol = column;
            merge.push({s: {r: 0, c: firstCol.index}, e: {r: 0, c: lastCol.index}});
        }

        for (let s = 0; s < page.sections.length; s++) {
            const section = page.sections[s];
            const sectionKeyword = `section-${section.id}`;
            const sectionElementsNumber = section.fields.length + section.popups.length;
            const firstSectionCol = sectionColumn;

            utils.sheet_add_aoa(sheet, [[`Section ${s + 1}: ${section.title}`]], {
                origin: `${firstSectionCol.letter}2`,
            });

            if (pageElementsNumber > 0) {
                sectionColumn = increment(sectionColumn, sectionElementsNumber - 1);
                const lastSectionCol = sectionColumn;
                merge.push({s: {r: 1, c: firstSectionCol.index}, e: {r: 1, c: lastSectionCol.index}});
            }

            for (let f = 0; f < section.fields.length; f++) {
                const field = section.fields[f];
                const fieldKeyword = `field-${field.id}`;

                let content = get(result, [pageKeyword, sectionKeyword, fieldKeyword], '');

                if (currentCase.isEncryptInfo && unwrappedCaseKey && content) {
                    content = await decryptStringWithKey(content, unwrappedCaseKey);
                }

                if (!content) {
                    const fieldFiles = files.filter(
                        ({pageId, fieldSectionId, fieldId}) =>
                            pageId === page.id && fieldId === field.id && fieldSectionId === section.id,
                    );

                    content = fieldFiles.length ? 'Yes' : 'No';
                }

                utils.sheet_add_aoa(sheet, [[field.label], [content]], {
                    origin: `${sectionFieldCoumn.letter}3`,
                });
                sectionFieldCoumn = increment(sectionFieldCoumn, 1);
            }

            for (let p = 0; p < section.popups.length; p++) {
                const popup = section.popups[p];
                const popupKeyword = `popup-${popup.id}`;

                const popupVal = get(result, [pageKeyword, sectionKeyword, popupKeyword], '');
                let content = '';
                if (popupVal) {
                    const popupPromises = popupVal.map(async (value: object, i: number) => {
                        const fields = popup.fields;
                        let acc = `${i + 1}. `;

                        const addFieldPromises = fields.map(async field => {
                            const fieldKeyword = `field-${field.id}`;
                            let fieldData = get(value, [fieldKeyword], '');
                            if (currentCase.isEncryptInfo && unwrappedCaseKey && fieldData) {
                                fieldData = await decryptStringWithKey(fieldData, unwrappedCaseKey);
                            }
                            const fieldRecord = `${field.label}: ${fieldData}; `;
                            acc += fieldRecord;
                        });
                        await Promise.all(addFieldPromises);

                        acc += `| `;
                        return acc;
                    });
                    content = (await Promise.all(popupPromises)).join('');
                }
                utils.sheet_add_aoa(sheet, [[popup.title], [content]], {
                    origin: `${sectionFieldCoumn.letter}3`,
                });
                sectionFieldCoumn = increment(sectionFieldCoumn, 1);
            }

            sectionColumn = increment(sectionColumn, 1);
        }

        column = increment(column, 1);
    }

    const arrayOfArray = sheet_to_aoa(sheet);

    // Count columns number by items in 4-th row:
    const colsNumber = arrayOfArray[3].length;

    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < colsNumber; j++) {
            setCellBold(sheet, i, j);
        }
    }

    sheet['!merges'] = merge;
    sheet['!cols'] = fitToColumn(arrayOfArray);
};

export const fillAdditionalData = async (
    currentCase: RequesterCaseTypes,
    sheet: WorkSheet,
    unwrappedCaseKey: CryptoKey | null,
) => {
    const {agentName, status, tags, createdAt, updatedAt, comments} = currentCase;

    utils.sheet_add_aoa(
        sheet,
        [
            ['Agent:', agentName],
            ['Status:', status],
            ['Tags:', tags.map(tag => tag.tag).join('; ')],
            ['Created:', createdAt],
            ['Last updated:', updatedAt],
            ['Comments'],
        ],
        {origin: 'A1'},
    );

    if (comments.length) {
        utils.sheet_add_aoa(sheet, [['Author', 'Date', 'Section', 'Text']], {origin: 'A8'});

        let commentRow = 9;

        for (let c = 0; c < comments.length; c++) {
            const comment = comments[c];

            let text = get(comment, ['text'], '');
            if (currentCase.isEncryptInfo && unwrappedCaseKey && text) {
                text = await decryptStringWithKey(text, unwrappedCaseKey);
            }
            await utils.sheet_add_aoa(
                sheet,
                [[comment.author, convertDateToCurrentLocale(comment.dateAndTime), comment.section, text]],
                {origin: `A${commentRow}`},
            );
            commentRow++;
        }
    }

    for (let r = 0; r < 7; r++) {
        setCellBold(sheet, r, 0);
    }

    for (let c = 0; c < 4; c++) {
        setCellBold(sheet, 7, c);
    }

    const arrayOfArray = sheet_to_aoa(sheet);
    sheet['!cols'] = fitToColumn(arrayOfArray);
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
};
