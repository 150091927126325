import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';

import {DashboardItemsTypes, PanelItemsTypes} from 'appRedux/actions/analytics/types';
import {CHANGE_DASHBOARD_PRIORITY} from 'appRedux/actions/analytics';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import DraggableTableRow from 'components/AdminScreenComponents/DraggableTableRow';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import EditDashboardButton from 'pages/admin/analytics/dashboards/EditDashboardButton';
import DeleteDashboardButton from 'pages/admin/analytics/dashboards/DeleteDashboardButton';

interface DashboardsListRowType {
    item: DashboardItemsTypes;
    dragItem?: string;
    setDragItem: React.Dispatch<React.SetStateAction<string>>;
    dropTo?: number;
    setDropTo: React.Dispatch<React.SetStateAction<number>>;
}

const DashboardsListRow: FC<DashboardsListRowType> = ({item, dragItem, setDragItem, dropTo, setDropTo}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isAnalyticEditEnabled} = useContext(PermissionContext);

    const {title, description, panels} = item;

    const changePriority = useCallback(
        data => dispatch({type: CHANGE_DASHBOARD_PRIORITY.REQUEST, payload: data}),
        [dispatch],
    );

    const onDragEnd = () => {
        changePriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
            callback: () => setDragItem(''),
        });
    };

    if (isAnalyticEditEnabled) {
        return (
            <DraggableTableRow
                item={item}
                dragItem={dragItem}
                setDragItem={setDragItem}
                dropTo={dropTo}
                setDropTo={setDropTo}
                onDragEnd={onDragEnd}
            >
                <TableCell>
                    <Typography>{title}</Typography>
                </TableCell>
                <TableCell>
                    <MarkdownWithShowAll text={description} />
                </TableCell>
                <TableCell>
                    {panels.map((panel: PanelItemsTypes) => {
                        return <Typography key={`panel-item-${panel.uuid}`}>{panel.title}</Typography>;
                    })}
                </TableCell>
                <TableCell>
                    <EditDashboardButton item={item} />
                    <DeleteDashboardButton item={item} />
                </TableCell>
            </DraggableTableRow>
        );
    }
    return (
        <TableRow>
            <TableCell>
                <Typography>{title}</Typography>
            </TableCell>
            <TableCell>
                <MarkdownWithShowAll text={description} />
            </TableCell>
            <TableCell>
                {panels.map((panel: PanelItemsTypes) => {
                    return <Typography key={`panel-item-${panel.uuid}`}>{panel.title}</Typography>;
                })}
            </TableCell>
        </TableRow>
    );
};

export default DashboardsListRow;
