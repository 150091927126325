import React, {ReactNode, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import {getAlertBackgroundColor, getAlertIcon} from 'contexts/alert/helper';
import {MediaContext} from 'contexts/media/context';
import {AlertContext, AlertContextType} from 'contexts/alert/context';

import EmptyArea from 'components/EmptyArea';

import {ALERT_TYPE_SUCCESS, ALERT_SUCCESS_MESSAGE} from 'config/index';
import {theme} from 'config/theme';

interface ContextType {
    children: ReactNode;
}

const AlertContextWrapper: React.FC<ContextType> = ({children}) => {
    const [t] = useTranslation();

    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('');
    const [alertType, setAlertType] = useState<string>('');

    const {isMobile} = useContext(MediaContext);

    const toggleAlert = (value: boolean): void => {
        setOpenAlert(value);
    };

    const showAlert = (type: string, message?: string) => {
        setOpenAlert(true);
        setAlertType(type);
        const defaultMessage =
            type === ALERT_TYPE_SUCCESS ? ALERT_SUCCESS_MESSAGE : 'messages.error.somethingWentWrong';
        setAlertText(message || defaultMessage);
    };

    const context: AlertContextType = {
        showAlert,
    };

    const Icon = getAlertIcon(alertType);

    return (
        <AlertContext.Provider value={context}>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={openAlert}
                autoHideDuration={3000}
                onClose={() => toggleAlert(false)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        minHeight: 48,
                        borderRadius: 3,
                        minWidth: isMobile ? '100vw' : '25vw',
                        p: 2,
                        backgroundColor: getAlertBackgroundColor(alertType),
                    }}
                >
                    <Icon isActive />
                    <Typography
                        sx={{
                            ml: 2,
                            textTransform: 'none',
                            color: theme.palette.background.default,
                        }}
                    >
                        {t(alertText)}
                    </Typography>
                    <EmptyArea />
                    <CloseIcon
                        sx={{ml: 2, cursor: 'pointer', color: theme.palette.background.default}}
                        onClick={() => setOpenAlert(false)}
                    />
                </Box>
            </Snackbar>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContextWrapper;
