import {isEmpty, get} from 'lodash';

import {ActivityElementType, RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';
import {
    FormFieldTypes,
    FormTranslationItemTypes,
    FormPageTypes,
    FormPopupTypes,
    FormSectionTypes,
} from 'appRedux/actions/forms/types';

import {
    isSelectionFields,
    FORM_FIELD_CHECKBOXES,
    FORM_FIELD_IMAGE_CHECK,
    OPTIONS_SEPARATOR,
} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {
    getPageKeyword,
    getSectionKeyword,
    getFieldKeyword,
    getPopupKeyword,
    getOptionKeyword,
    getFormTranslatedLabel,
} from 'helpers/translationsHelper';

export type FieldsDifferenceType = {
    page: string;
    section: string;
    field?: string;
    popup?: string;
    oldValue?: string | null;
    newValue?: string | null;
    added?: string | null;
    removed?: string | null;
};

export type ActivityItemType = {
    [key: string]: string | ActivityItemType;
};

export const getResultsDifference = (oldObject: any, newObject: any): string | ActivityItemType => {
    const result: ActivityItemType = {};
    if (Object.is(oldObject, newObject)) {
        return {};
    }
    if (!newObject || typeof newObject !== 'object') {
        return newObject;
    }

    Object.keys(oldObject || {})
        .concat(Object.keys(newObject || {}))
        .forEach(key => {
            if (newObject[key] !== oldObject[key] && !Object.is(oldObject[key], newObject[key])) {
                result[key] = newObject[key];
            }
            if (typeof newObject[key] === 'object' && typeof oldObject[key] === 'object') {
                const value = getResultsDifference(oldObject[key], newObject[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });

    return result;
};

export const getFieldDifferences = (
    formId: number,
    pages: FormPageTypes[],
    translations: FormTranslationItemTypes[],
    element: ActivityElementType,
): FieldsDifferenceType[] => {
    const oldObject = JSON.parse(element.oldValue) ?? {};
    const newObject = JSON.parse(element.newValue) ?? {};

    const oldValueDifference = getResultsDifference(newObject, oldObject);
    const newValueDifference = getResultsDifference(oldObject, newObject);

    const results: FieldsDifferenceType[] = [];

    pages.forEach((page: FormPageTypes) => {
        const keywordPage = getPageKeyword(formId, page.id, page.versionId, 'title');
        const translatedPageTitle = getFormTranslatedLabel(translations, keywordPage, page.title);
        const sections = page.sections;
        sections.forEach((section: FormSectionTypes) => {
            const keywordSection = getSectionKeyword(formId, section.id, 'title');
            const translatedSectionTitle = getFormTranslatedLabel(translations, keywordSection, section.title);
            const fields = section.fields;

            fields.forEach((field: FormFieldTypes) => {
                const pageKeyword = `page-${page.id}`;
                const sectionKeyword = `section-${section.id}`;
                const fieldKeyword = `field-${field.id}`;

                const oldFieldValue = get(oldValueDifference, [pageKeyword, sectionKeyword, fieldKeyword], null);
                const newFieldValue = get(newValueDifference, [pageKeyword, sectionKeyword, fieldKeyword], null);
                if (oldFieldValue !== newFieldValue) {
                    const isOptionSelectedField = isSelectionFields(field.type);
                    const keywordField = getFieldKeyword(formId, field.id, 'label');
                    results.push({
                        page: translatedPageTitle,
                        section: translatedSectionTitle,
                        field: getFormTranslatedLabel(translations, keywordField, field.label),
                        oldValue: isOptionSelectedField
                            ? getOptionValue(formId, field, oldFieldValue, translations)
                            : oldFieldValue,
                        newValue: isOptionSelectedField
                            ? getOptionValue(formId, field, newFieldValue, translations)
                            : newFieldValue,
                    });
                }
            });
        });
    });

    return results;
};

export const getPopupDifferences = (
    formId: number,
    popupId: number,
    pages: FormPageTypes[],
    translations: FormTranslationItemTypes[],
    element: ActivityElementType,
): FieldsDifferenceType[] => {
    const oldObject = JSON.parse(element.oldValue) ?? {};
    const newObject = JSON.parse(element.newValue) ?? {};

    const oldValueDifference = getResultsDifference(newObject, oldObject);
    const newValueDifference = getResultsDifference(oldObject, newObject);

    const results: FieldsDifferenceType[] = [];

    pages.forEach((page: FormPageTypes) => {
        const keywordPage = getPageKeyword(formId, page.id, page.versionId, 'title');
        const translatedPageTitle = getFormTranslatedLabel(translations, keywordPage, page.title);
        const sections = page.sections;
        sections.forEach((section: FormSectionTypes) => {
            const keywordSection = getSectionKeyword(formId, section.id, 'title');
            const translatedSectionTitle = getFormTranslatedLabel(translations, keywordSection, section.title);
            const popups = section.popups;
            popups.forEach((popup: FormPopupTypes) => {
                if (popupId === popup.id) {
                    const fields = popup.fields;
                    fields.forEach(field => {
                        const keywordField = getFieldKeyword(formId, field.id, 'label');
                        const oldValue = get(oldValueDifference, `field-${field.id}`, null);
                        const newValue = get(newValueDifference, `field-${field.id}`, null);
                        if (oldValue || newValue) {
                            const fieldKeyword = `field-${field.id}`;
                            const keywordPopup = getPopupKeyword(formId, popup.id, 'title');
                            const isOptionSelectedField = isSelectionFields(field.type);
                            if (isOptionSelectedField) {
                                results.push({
                                    page: translatedPageTitle,
                                    section: translatedSectionTitle,
                                    popup: getFormTranslatedLabel(translations, keywordPopup, popup.title),
                                    field: getFormTranslatedLabel(translations, keywordField, field.label),
                                    oldValue: oldValue
                                        ? getOptionValue(formId, field, String(oldValue), translations)
                                        : '',
                                    newValue: newValue
                                        ? getOptionValue(formId, field, String(newValue), translations)
                                        : '',
                                });
                            } else {
                                results.push({
                                    page: translatedPageTitle,
                                    section: translatedSectionTitle,
                                    popup: getFormTranslatedLabel(translations, keywordPopup, popup.title),
                                    field: getFormTranslatedLabel(translations, keywordField, field.label),
                                    oldValue: oldValue ? String(oldValue) : '',
                                    newValue: newValue ? String(newValue) : '',
                                });
                            }
                        }
                    });
                }
            });
        });
    });

    return results;
};

const getOptionValue = (
    formId: number,
    field: FormFieldTypes,
    value: string,
    translations: FormTranslationItemTypes[],
): string => {
    const {fieldOptions, type} = field;
    if (!fieldOptions) return '';

    if (type === FORM_FIELD_CHECKBOXES || type === FORM_FIELD_IMAGE_CHECK) {
        const optionIds = value.split(OPTIONS_SEPARATOR);
        const filteredOptions = fieldOptions.filter(item => optionIds.includes(String(item.id)));
        const results: string[] = [];
        filteredOptions.forEach(item => {
            const keywordOption = getOptionKeyword(formId, item.id, 'title');
            const translatedOptionTitle = getFormTranslatedLabel(translations, keywordOption, item.title);
            results.push(translatedOptionTitle);
        });
        return results.join(', ');
    }

    const selectedOption = fieldOptions.find(item => String(item.id) === String(value));
    if (selectedOption) {
        const keywordOption = getOptionKeyword(formId, selectedOption.id, 'title');
        return getFormTranslatedLabel(translations, keywordOption, selectedOption.title);
    }
    return '';
};

const getChangedPopupItem = (items: any[]) => {
    let result = {};
    items.forEach((item: any) => {
        if (!isEmpty(item)) {
            result = item;
        }
    });
    return result;
};

const getPopupField = (item: any, fields: FormFieldTypes[]) => {
    let result = '';
    fields.forEach((field: FormFieldTypes) => {
        const value = get(item, `field-${field.id}`, null);
        if (value) {
            result = field.label;
        }
    });
    return result;
};

const getPopupRow = (item: any, fields: FormFieldTypes[]) => {
    let result = '';
    fields.forEach((field: FormFieldTypes) => {
        const value = get(item, `field-${field.id}`, null);
        if (value) {
            result += `${field.label}: "${value}"; `;
        }
    });
    return result;
};

export const getSearchedActivities = (
    items: RequesterCaseActivitiesTypes[],
    search: string | null,
): RequesterCaseActivitiesTypes[] => {
    if (search && search.length >= 3) {
        return items.filter((item: RequesterCaseActivitiesTypes) => {
            const {person, element} = item;
            const searchLowerCase = search.toLowerCase();
            const parameter = get(element, 'parameter', '');
            const oldValue = get(element, 'oldValue', '');
            const newValue = get(element, 'newValue', '');
            return (
                (person && person.toLowerCase().includes(searchLowerCase)) ||
                String(parameter).toLowerCase().includes(searchLowerCase) ||
                String(oldValue).toLowerCase().includes(searchLowerCase) ||
                String(newValue).toLowerCase().includes(searchLowerCase)
            );
        });
    }
    return items;
};
