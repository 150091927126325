import get from 'lodash/get';

import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';
import {
    GET_REQUESTER_CASE_COMMENTS,
    ADD_REQUESTER_CASE_COMMENT,
    REMOVE_REQUESTER_CASE_COMMENT,
    UPDATE_REQUESTER_CASE_COMMENT,
} from 'appRedux/actions/comments';

import {
    RequesterCaseTypes,
    RequesterAllCaseTypes,
    RequesterAllCaseItemTypes,
    RequesterCaseItemType,
    RequesterCaseTagTypes,
    RequesterCaseActivitiesTypes,
    RequesterCaseAttachmentsForArchiveTypes,
    CaseWatcherTypes,
    RequesterCaseApproveTypes,
    InviteAdditionalFormTypes,
    SubFormRequestTypes,
    CaseHasTasksTypes,
    RequesterCaseUpdateResultsResponse,
    RequesterCaseFilesTypes,
    ChatFileType,
    RequesterCaseDrawerTypes,
    RequesterCaseHasCalendarTypes,
    RequesterCaseHasSlotTypes,
    RequesterCaseHasBudgetTypes,
    RequesterCasePopupsResponseTypes,
    RequesterCasePopupType,
} from 'appRedux/actions/requestCase/types';
import {
    GET_ALL_REQUESTER_CASES,
    GET_REQUESTER_CASE_ALL_FILES,
    GET_REQUESTER_CASE_CHAT_FILES,
    GET_REQUESTER_CASE_DRAWER,
    CREATE_REQUESTER_CASE,
    UPDATE_REQUESTER_CASE,
    UPDATE_REQUESTER_CASE_OPTION,
    UPDATE_CHECKED_SECTIONS,
    UPDATE_REQUESTER_CASE_POPUP_OPTION,
    GET_REQUESTER_CASE,
    UPLOAD_REQUESTER_DOCUMENT,
    DELETE_REQUESTER_DOCUMENT,
    REQUESTER_PREPARED_STATUS,
    REQUEST_CASE_CHAT_ACTIVATE,
    REQUEST_CASE_PINNING,
    REQUEST_CASE_ASSIGN_AGENT,
    REQUEST_CASE_UNASSIGNED_AGENT,
    REQUEST_CASE_CHANGE_STATUS,
    REQUEST_CASE_ADD_TAG,
    REQUEST_CASE_REMOVE_TAG,
    REQUEST_CASE_ARCHIVE,
    REQUEST_CASE_QUESTION,
    REQUEST_CASE_ACTIVITIES,
    REQUEST_CASE_ACTIVITIES_EXPORT,
    REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE,
    CREATE_CASE_WATCHER,
    DELETE_CASE_WATCHER,
    REQUESTER_CASE_EMAIL_REQUEST,
    EDIT_REQUESTER_DOCUMENT,
    UPLOAD_REQUESTER_SIGNATURE,
    UPDATE_STATUS_CHANGE_APPROVE_REQUEST,
    UNSET_STATUS_CHANGE_APPROVE_REQUEST,
    DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS,
    DELETE_REQUESTER_SIGNATURE,
    CREATE_ADDITIONAL_FORM_INVITE,
    DELETE_ADDITIONAL_FORM_INVITE,
    CREATE_SUBFORM_REQUEST,
    SUBMIT_SUBFORM_REQUEST,
    DELETE_SUBFORM_REQUEST,
    IGNORE_CASE_HAS_TASK,
    REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY,
    UPDATE_CHECKED_SECTIONS_FOR_STACK,
    REQUESTER_CASE_SLOTS_LIST,
    CREATE_REQUESTER_CASE_HAS_SLOT,
    DELETE_REQUESTER_CASE_HAS_SLOT,
    REQUESTER_CASE_BUDGETS_LIST,
    CREATE_REQUESTER_CASE_BUDGET,
    UPDATE_REQUESTER_CASE_BUDGET,
    DELETE_REQUESTER_CASE_BUDGET,
    REQUEST_CASE_CANCEL,
    REQUESTER_CASE_CALENDARS_LIST,
    CREATE_REQUESTER_CASE_CALENDAR,
    UPDATE_REQUESTER_CASE_CALENDAR,
    DELETE_REQUESTER_CASE_CALENDAR,
    REFRESH_CASE_INFORMATION,
    GET_REQUESTER_CASE_POPUPS,
    CREATE_REQUESTER_POPUP,
    UPDATE_REQUESTER_POPUP,
    DELETE_REQUESTER_POPUP,
} from 'appRedux/actions/requestCase';

import {
    UPDATE_CLIENT_TRANSLATION,
    DELETE_CLIENT_TRANSLATION,
    AUTO_CLIENT_TRANSLATION,
    UPDATE_CLIENT_TRANSLATION_ITEM,
} from 'appRedux/actions/clientTranslation';
import {ClientTranslationTypes} from 'appRedux/actions/clientTranslation/types';

import {RequesterDocumentResponseType} from 'appRedux/actions/profile/types';

export interface RequestCaseState {
    isLoading: boolean;
    isLoadingBackground: boolean;
    isLoadingFilesList: boolean;
    isLoadingChatFilesList: boolean;
    isLoadingCaseDrawer: boolean;
    isLoadingCaseResourceFieldInformation: boolean;
    isCaseLoading: boolean;
    isCaseUpdateLoading: boolean;
    isEmailRequestLoading: boolean;
    allCases: RequesterAllCaseTypes[];
    currentCase: RequesterCaseTypes;
    currentCaseCalendars: RequesterCaseHasCalendarTypes[];
    currentCaseBudgets: RequesterCaseHasBudgetTypes[];
    currentCaseDrawer: RequesterCaseDrawerTypes | null;
    currentCaseChatFiles: ChatFileType[];
    currentCaseSlots: RequesterCaseHasSlotTypes[];
    caseAttachments: RequesterCaseAttachmentsForArchiveTypes[];
    error: IErrors | undefined;
}

const initialCurrentCaseState: RequesterCaseTypes = {
    id: null,
    userName: null,
    userEmail: null,
    agentName: null,
    agentEmail: null,
    readAt: new Date(),
    updatedAt: new Date(),
    createdAt: new Date(),
    deletedAt: new Date(),
    status: '',
    statusColor: '#6EC181',
    statusUuid: '',
    statusInitial: false,
    statusTitle: '',
    statusPermittedIds: '',
    formId: null,
    formTitle: '',
    formTags: [],
    formPrefix: '',
    result: {},
    isAssigned: false,
    isFormChatActivated: false,
    isEncryptInfo: false,
    noEditForm: false,
    isChatActivatedByAgent: false,
    isPinned: false,
    isAnonymousAccount: false,
    hasNotApprovedStatusRequestChanges: false,
    hasRejectedStatusChangingRequest: false,
    rejectedStatusIds: [],
    notReadMessages: 0,
    totalFilesNumber: 0,
    tags: [],
    options: [],
    popupItems: [],
    approves: [],
    checkedSections: [],
    files: [],
    signatures: [],
    comments: [],
    activities: [],
    translations: [],
    watchers: [],
    invites: [],
    subFormRequests: [],
    tasks: [],
    caseLabel: '',
    versionUuid: '',
    versionTitle: '',
    cancelReason: null,
    canceledAt: null,
};

export const initialState: RequestCaseState = {
    isLoading: false,
    isLoadingBackground: false,
    isLoadingFilesList: false,
    isLoadingChatFilesList: false,
    isLoadingCaseDrawer: false,
    isLoadingCaseResourceFieldInformation: false,
    isCaseLoading: false,
    isCaseUpdateLoading: false,
    isEmailRequestLoading: false,
    allCases: [],
    currentCase: initialCurrentCaseState,
    currentCaseCalendars: [],
    currentCaseBudgets: [],
    currentCaseChatFiles: [],
    currentCaseDrawer: null,
    currentCaseSlots: [],
    caseAttachments: [],
    error: undefined,
};

const requestCase = (
    state = initialState,
    action: IActionType<
        | RequesterCaseTypes
        | RequesterAllCaseTypes[]
        | RequesterAllCaseItemTypes
        | string[]
        | RequesterDocumentResponseType[]
        | RequesterCaseTagTypes[]
        | RequesterCaseCommentsTypes[]
        | RequesterCaseActivitiesTypes[]
        | ClientTranslationTypes[]
        | CaseWatcherTypes[]
        | RequesterCaseApproveTypes[]
        | RequesterCaseAttachmentsForArchiveTypes[]
        | InviteAdditionalFormTypes[]
        | SubFormRequestTypes[]
        | CaseHasTasksTypes[]
        | ChatFileType[]
        | RequesterCaseHasCalendarTypes[]
        | RequesterCaseHasBudgetTypes[]
        | RequesterCaseHasSlotTypes[]
        | RequesterCaseUpdateResultsResponse
        | RequesterCaseFilesTypes
        | RequesterCaseDrawerTypes
        | RequesterCasePopupType[]
        | IErrors
    >,
): RequestCaseState => {
    switch (action.type) {
        case GET_ALL_REQUESTER_CASES.REQUEST:
        case CREATE_REQUESTER_CASE.REQUEST: // case UPDATE_REQUESTER_CASE_OPTION.REQUEST:
        case UPDATE_CHECKED_SECTIONS.REQUEST:
        case UPDATE_CHECKED_SECTIONS_FOR_STACK.REQUEST:
        case UPLOAD_REQUESTER_DOCUMENT.REQUEST:
        case EDIT_REQUESTER_DOCUMENT.REQUEST:
        case UPLOAD_REQUESTER_SIGNATURE.REQUEST:
        case DELETE_REQUESTER_SIGNATURE.REQUEST:
        case GET_REQUESTER_CASE_POPUPS.REQUEST: // case CREATE_REQUESTER_POPUP.REQUEST:   // case UPDATE_REQUESTER_POPUP.REQUEST:
        case DELETE_REQUESTER_POPUP.REQUEST: // case UPDATE_REQUESTER_CASE_POPUP_OPTION.REQUEST:
        case DELETE_REQUESTER_DOCUMENT.REQUEST:
        case REQUESTER_PREPARED_STATUS.REQUEST:
        case REQUEST_CASE_ASSIGN_AGENT.REQUEST:
        case REQUEST_CASE_UNASSIGNED_AGENT.REQUEST:
        case REQUEST_CASE_CHANGE_STATUS.REQUEST:
        case REQUEST_CASE_CHAT_ACTIVATE.REQUEST:
        case REQUEST_CASE_PINNING.REQUEST:
        case REQUEST_CASE_ADD_TAG.REQUEST:
        case REQUEST_CASE_REMOVE_TAG.REQUEST:
        case REQUEST_CASE_ARCHIVE.REQUEST:
        case REQUEST_CASE_QUESTION.REQUEST:
        case REQUEST_CASE_ACTIVITIES.REQUEST:
        case REQUEST_CASE_ACTIVITIES_EXPORT.REQUEST:
        case GET_REQUESTER_CASE_COMMENTS.REQUEST:
        case ADD_REQUESTER_CASE_COMMENT.REQUEST:
        case UPDATE_REQUESTER_CASE_COMMENT.REQUEST:
        case REMOVE_REQUESTER_CASE_COMMENT.REQUEST:
        case CREATE_CASE_WATCHER.REQUEST:
        case DELETE_CASE_WATCHER.REQUEST:
        case UPDATE_CLIENT_TRANSLATION.REQUEST:
        case UPDATE_CLIENT_TRANSLATION_ITEM.REQUEST:
        case DELETE_CLIENT_TRANSLATION.REQUEST:
        case AUTO_CLIENT_TRANSLATION.REQUEST:
        case REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.REQUEST:
        case CREATE_ADDITIONAL_FORM_INVITE.REQUEST:
        case DELETE_ADDITIONAL_FORM_INVITE.REQUEST:
        case CREATE_SUBFORM_REQUEST.REQUEST:
        case SUBMIT_SUBFORM_REQUEST.REQUEST:
        case DELETE_SUBFORM_REQUEST.REQUEST:
        case UPDATE_STATUS_CHANGE_APPROVE_REQUEST.REQUEST:
        case UNSET_STATUS_CHANGE_APPROVE_REQUEST.REQUEST:
        case DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.REQUEST:
        case REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY.REQUEST:
        case IGNORE_CASE_HAS_TASK.REQUEST:
        case REQUEST_CASE_CANCEL.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case REFRESH_CASE_INFORMATION.REQUEST: {
            return {
                ...state,
                isLoading: false,
                currentCase: initialCurrentCaseState,
                currentCaseBudgets: [],
                currentCaseChatFiles: [],
                currentCaseDrawer: null,
                currentCaseSlots: [],
                caseAttachments: [],
            };
        }

        case REQUESTER_CASE_SLOTS_LIST.REQUEST:
        case CREATE_REQUESTER_CASE_HAS_SLOT.REQUEST:
        case DELETE_REQUESTER_CASE_HAS_SLOT.REQUEST:
        case REQUESTER_CASE_BUDGETS_LIST.REQUEST:
        case CREATE_REQUESTER_CASE_BUDGET.REQUEST:
        case UPDATE_REQUESTER_CASE_BUDGET.REQUEST:
        case DELETE_REQUESTER_CASE_BUDGET.REQUEST:
        case REQUESTER_CASE_CALENDARS_LIST.REQUEST:
        case CREATE_REQUESTER_CASE_CALENDAR.REQUEST:
        case UPDATE_REQUESTER_CASE_CALENDAR.REQUEST:
        case DELETE_REQUESTER_CASE_CALENDAR.REQUEST: {
            return {
                ...state,
                isLoadingCaseResourceFieldInformation: true,
            };
        }

        case GET_REQUESTER_CASE_ALL_FILES.REQUEST: {
            return {
                ...state,
                isLoadingFilesList: true,
            };
        }

        case GET_REQUESTER_CASE_CHAT_FILES.REQUEST: {
            return {
                ...state,
                isLoadingChatFilesList: true,
            };
        }

        case GET_REQUESTER_CASE_DRAWER.REQUEST: {
            return {
                ...state,
                isLoadingCaseDrawer: true,
            };
        }

        case GET_ALL_REQUESTER_CASES.REQUEST_BACKGROUND:
        case GET_REQUESTER_CASE.REQUEST_BACKGROUND: {
            return {
                ...state,
                isLoadingBackground: true,
            };
        }

        case UPDATE_REQUESTER_CASE.REQUEST: {
            return {
                ...state,
                isCaseUpdateLoading: true,
            };
        }

        case GET_REQUESTER_CASE.REQUEST: {
            return {
                ...state,
                isLoading: true,
                isCaseLoading: true,
            };
        }

        case REQUESTER_CASE_EMAIL_REQUEST.REQUEST: {
            return {
                ...state,
                isEmailRequestLoading: true,
            };
        }

        case UPDATE_REQUESTER_CASE_OPTION.SUCCESS:
        case UPDATE_REQUESTER_CASE_POPUP_OPTION.SUCCESS:
        case REQUEST_CASE_ACTIVITIES_EXPORT.SUCCESS:
        case REQUEST_CASE_ARCHIVE.SUCCESS:
        case REQUEST_CASE_QUESTION.SUCCESS:
        case CREATE_REQUESTER_CASE.SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case REQUESTER_CASE_EMAIL_REQUEST.SUCCESS: {
            return {
                ...state,
                isEmailRequestLoading: false,
            };
        }

        case GET_ALL_REQUESTER_CASES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoadingBackground: false,
                allCases: action.payload as RequesterAllCaseTypes[],
            };
        }

        case GET_REQUESTER_CASE_ALL_FILES.SUCCESS: {
            const {caseId, organization, files, chatFiles} = action.payload as RequesterCaseFilesTypes;
            const currentOrganizationCases: RequesterAllCaseItemTypes[] = get(state.allCases, organization, []);
            const updatedAllCases: RequesterAllCaseItemTypes[] = [];
            currentOrganizationCases.forEach(item => {
                if (Number(item.caseId) === Number(caseId)) {
                    const newItem: RequesterAllCaseItemTypes = {
                        ...item,
                        files,
                        chatFiles,
                    };
                    updatedAllCases.push(newItem);
                } else {
                    updatedAllCases.push(item);
                }
            });
            return {
                ...state,
                isLoadingFilesList: false,
                allCases: {
                    ...state.allCases,
                    [organization]: updatedAllCases,
                },
            };
        }

        case GET_REQUESTER_CASE_CHAT_FILES.SUCCESS: {
            return {
                ...state,
                isLoadingChatFilesList: false,
                currentCaseChatFiles: action.payload as ChatFileType[],
            };
        }

        case GET_REQUESTER_CASE_DRAWER.SUCCESS: {
            return {
                ...state,
                isLoadingCaseDrawer: false,
                currentCaseDrawer: action.payload as RequesterCaseDrawerTypes,
            };
        }

        case REQUEST_CASE_CANCEL.SUCCESS:
        case REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY.SUCCESS: {
            const requesterCase = action.payload as RequesterAllCaseItemTypes;
            const newCases: RequesterAllCaseTypes[] = [];
            for (const [organization] of Object.entries(state.allCases)) {
                const casesList: RequesterAllCaseItemTypes[] = get(state.allCases, organization, []);
                const newCaseItems: RequesterAllCaseItemTypes[] = [];
                casesList.forEach(item => {
                    if (item.caseId === requesterCase.caseId) {
                        newCaseItems.push(requesterCase);
                    } else {
                        newCaseItems.push(item);
                    }
                });
                state.allCases[organization] = newCaseItems;
            }
            return {
                ...state,
                isLoading: false,
                allCases: state.allCases,
            };
        }

        case REQUEST_CASE_ASSIGN_AGENT.SUCCESS:
        case REQUEST_CASE_UNASSIGNED_AGENT.SUCCESS:
        case REQUEST_CASE_CHAT_ACTIVATE.SUCCESS:
        case REQUEST_CASE_PINNING.SUCCESS:
        case REQUEST_CASE_CHANGE_STATUS.SUCCESS:
        case UPDATE_STATUS_CHANGE_APPROVE_REQUEST.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    ...(action.payload as RequesterCaseTypes),
                },
            };
        }

        case GET_REQUESTER_CASE.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                isLoadingBackground: false,
                isCaseLoading: false,
                currentCase: {
                    ...currentCase,
                    ...(action.payload as RequesterCaseTypes),
                },
            };
        }

        case REQUESTER_PREPARED_STATUS.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    result: action.payload as RequesterCaseItemType,
                },
            };
        }

        case UPDATE_REQUESTER_CASE.SUCCESS: {
            const currentCase = state.currentCase;
            const resultData = get(action.payload as RequesterCaseUpdateResultsResponse, 'result', '{}');
            const options = get(action.payload as RequesterCaseUpdateResultsResponse, 'options', []);
            return {
                ...state,
                isCaseUpdateLoading: false,
                currentCase: {
                    ...currentCase,
                    result: JSON.parse(resultData),
                    options,
                },
            };
        }

        case UPDATE_CHECKED_SECTIONS.SUCCESS:
        case UPDATE_CHECKED_SECTIONS_FOR_STACK.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    checkedSections: action.payload as string[],
                },
            };
        }

        case REQUEST_CASE_ADD_TAG.SUCCESS:
        case REQUEST_CASE_REMOVE_TAG.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    tags: action.payload as RequesterCaseTagTypes[],
                },
            };
        }

        case CREATE_CASE_WATCHER.SUCCESS:
        case DELETE_CASE_WATCHER.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    watchers: action.payload as CaseWatcherTypes[],
                },
            };
        }

        case UNSET_STATUS_CHANGE_APPROVE_REQUEST.SUCCESS:
        case DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    approves: action.payload as RequesterCaseApproveTypes[],
                },
            };
        }

        case EDIT_REQUESTER_DOCUMENT.SUCCESS:
        case DELETE_REQUESTER_DOCUMENT.SUCCESS:
        case UPLOAD_REQUESTER_DOCUMENT.SUCCESS:
        case UPLOAD_REQUESTER_SIGNATURE.SUCCESS:
        case DELETE_REQUESTER_SIGNATURE.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    ...(action.payload as RequesterDocumentResponseType[]),
                },
            };
        }

        case GET_REQUESTER_CASE_POPUPS.SUCCESS:
        case CREATE_REQUESTER_POPUP.SUCCESS:
        case UPDATE_REQUESTER_POPUP.SUCCESS:
        case DELETE_REQUESTER_POPUP.SUCCESS: {
            const currentCase = state.currentCase;

            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    popupItems: action.payload as RequesterCasePopupType[],
                },
            };
        }

        case GET_REQUESTER_CASE_COMMENTS.SUCCESS:
        case ADD_REQUESTER_CASE_COMMENT.SUCCESS:
        case UPDATE_REQUESTER_CASE_COMMENT.SUCCESS:
        case REMOVE_REQUESTER_CASE_COMMENT.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    comments: action.payload as RequesterCaseCommentsTypes[],
                },
            };
        }

        case REQUEST_CASE_ACTIVITIES.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    activities: action.payload as RequesterCaseActivitiesTypes[],
                },
            };
        }

        case AUTO_CLIENT_TRANSLATION.SUCCESS:
        case DELETE_CLIENT_TRANSLATION.SUCCESS:
        case UPDATE_CLIENT_TRANSLATION_ITEM.SUCCESS:
        case UPDATE_CLIENT_TRANSLATION.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    translations: action.payload as ClientTranslationTypes[],
                },
            };
        }

        case CREATE_SUBFORM_REQUEST.SUCCESS:
        case SUBMIT_SUBFORM_REQUEST.SUCCESS:
        case DELETE_SUBFORM_REQUEST.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    subFormRequests: action.payload as SubFormRequestTypes[],
                },
            };
        }

        case CREATE_ADDITIONAL_FORM_INVITE.SUCCESS:
        case DELETE_ADDITIONAL_FORM_INVITE.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    invites: action.payload as InviteAdditionalFormTypes[],
                },
            };
        }

        case IGNORE_CASE_HAS_TASK.SUCCESS: {
            const currentCase = state.currentCase;
            return {
                ...state,
                isLoading: false,
                currentCase: {
                    ...currentCase,
                    tasks: action.payload as CaseHasTasksTypes[],
                },
            };
        }

        case REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                caseAttachments: action.payload as RequesterCaseAttachmentsForArchiveTypes[],
            };
        }

        case REQUESTER_CASE_CALENDARS_LIST.SUCCESS:
        case CREATE_REQUESTER_CASE_CALENDAR.SUCCESS:
        case UPDATE_REQUESTER_CASE_CALENDAR.SUCCESS:
        case DELETE_REQUESTER_CASE_CALENDAR.SUCCESS: {
            return {
                ...state,
                isLoadingCaseResourceFieldInformation: false,
                currentCaseCalendars: action.payload as RequesterCaseHasCalendarTypes[],
            };
        }

        case REQUESTER_CASE_SLOTS_LIST.SUCCESS:
        case CREATE_REQUESTER_CASE_HAS_SLOT.SUCCESS:
        case DELETE_REQUESTER_CASE_HAS_SLOT.SUCCESS: {
            return {
                ...state,
                isLoadingCaseResourceFieldInformation: false,
                currentCaseSlots: action.payload as RequesterCaseHasSlotTypes[],
            };
        }

        case REQUESTER_CASE_BUDGETS_LIST.SUCCESS:
        case CREATE_REQUESTER_CASE_BUDGET.SUCCESS:
        case UPDATE_REQUESTER_CASE_BUDGET.SUCCESS:
        case DELETE_REQUESTER_CASE_BUDGET.SUCCESS: {
            return {
                ...state,
                isLoadingCaseResourceFieldInformation: false,
                currentCaseBudgets: action.payload as RequesterCaseHasBudgetTypes[],
            };
        }

        case GET_ALL_REQUESTER_CASES.ERROR:
        case GET_REQUESTER_CASE_ALL_FILES.ERROR:
        case GET_REQUESTER_CASE_CHAT_FILES.ERROR:
        case GET_REQUESTER_CASE_DRAWER.ERROR:
        case GET_REQUESTER_CASE.ERROR:
        case UPDATE_REQUESTER_CASE_OPTION.ERROR:
        case CREATE_REQUESTER_CASE.ERROR:
        case UPDATE_CHECKED_SECTIONS.ERROR:
        case UPDATE_CHECKED_SECTIONS_FOR_STACK.ERROR:
        case UPLOAD_REQUESTER_DOCUMENT.ERROR:
        case EDIT_REQUESTER_DOCUMENT.ERROR:
        case UPLOAD_REQUESTER_SIGNATURE.ERROR:
        case DELETE_REQUESTER_SIGNATURE.ERROR:
        case GET_REQUESTER_CASE_POPUPS.ERROR:
        case CREATE_REQUESTER_POPUP.ERROR:
        case UPDATE_REQUESTER_POPUP.ERROR:
        case DELETE_REQUESTER_POPUP.ERROR:
        case UPDATE_REQUESTER_CASE_POPUP_OPTION.ERROR:
        case DELETE_REQUESTER_DOCUMENT.ERROR:
        case REQUEST_CASE_CHAT_ACTIVATE.ERROR:
        case REQUEST_CASE_CHANGE_STATUS.ERROR:
        case REQUEST_CASE_ASSIGN_AGENT.ERROR:
        case REQUEST_CASE_UNASSIGNED_AGENT.ERROR:
        case REQUEST_CASE_ARCHIVE.ERROR:
        case REQUESTER_PREPARED_STATUS.ERROR:
        case UPDATE_REQUESTER_CASE.ERROR:
        case REQUEST_CASE_ADD_TAG.ERROR:
        case REQUEST_CASE_QUESTION.ERROR:
        case REQUEST_CASE_ACTIVITIES.ERROR:
        case REQUEST_CASE_ACTIVITIES_EXPORT.ERROR:
        case GET_REQUESTER_CASE_COMMENTS.ERROR:
        case REQUEST_CASE_PINNING.ERROR:
        case ADD_REQUESTER_CASE_COMMENT.ERROR:
        case UPDATE_REQUESTER_CASE_COMMENT.ERROR:
        case REMOVE_REQUESTER_CASE_COMMENT.ERROR:
        case CREATE_CASE_WATCHER.ERROR:
        case DELETE_CASE_WATCHER.ERROR:
        case UPDATE_CLIENT_TRANSLATION.ERROR:
        case UPDATE_CLIENT_TRANSLATION_ITEM.ERROR:
        case DELETE_CLIENT_TRANSLATION.ERROR:
        case AUTO_CLIENT_TRANSLATION.ERROR:
        case REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.ERROR:
        case REQUEST_CASE_REMOVE_TAG.ERROR:
        case CREATE_ADDITIONAL_FORM_INVITE.ERROR:
        case DELETE_ADDITIONAL_FORM_INVITE.ERROR:
        case CREATE_SUBFORM_REQUEST.ERROR:
        case SUBMIT_SUBFORM_REQUEST.ERROR:
        case DELETE_SUBFORM_REQUEST.ERROR:
        case UPDATE_STATUS_CHANGE_APPROVE_REQUEST.ERROR:
        case UNSET_STATUS_CHANGE_APPROVE_REQUEST.ERROR:
        case DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.ERROR:
        case REQUESTER_CASE_EMAIL_REQUEST.ERROR:
        case REQUEST_CASE_TOGGLE_REMOVING_POSSIBILITY.ERROR:
        case REQUESTER_CASE_SLOTS_LIST.ERROR:
        case CREATE_REQUESTER_CASE_HAS_SLOT.ERROR:
        case DELETE_REQUESTER_CASE_HAS_SLOT.ERROR:
        case REQUESTER_CASE_BUDGETS_LIST.ERROR:
        case CREATE_REQUESTER_CASE_BUDGET.ERROR:
        case UPDATE_REQUESTER_CASE_BUDGET.ERROR:
        case DELETE_REQUESTER_CASE_BUDGET.ERROR:
        case IGNORE_CASE_HAS_TASK.ERROR:
        case REQUEST_CASE_CANCEL.ERROR:
        case REQUESTER_CASE_CALENDARS_LIST.ERROR:
        case CREATE_REQUESTER_CASE_CALENDAR.ERROR:
        case UPDATE_REQUESTER_CASE_CALENDAR.ERROR:
        case DELETE_REQUESTER_CASE_CALENDAR.ERROR: {
            return {
                ...state,
                isLoading: false,
                isLoadingBackground: false,
                isLoadingFilesList: false,
                isLoadingChatFilesList: false,
                isLoadingCaseDrawer: false,
                isEmailRequestLoading: false,
                isCaseUpdateLoading: false,
                isLoadingCaseResourceFieldInformation: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default requestCase;
