import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';

import {GET_DASHBOARDS_LIST, GET_PANELS_LIST} from 'appRedux/actions/analytics';
import {DashboardItemsTypes} from 'appRedux/actions/analytics/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';
import {RouteContext} from 'contexts/route/context';

import ClientSectionWrapper from 'components/ClientScreenComponents/SectionWrapper';

import DashboardsListRow from 'pages/admin/analytics/dashboards/DashboardsListRow';

const DashboardsList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isAnalyticViewEnabled, isAnalyticEditEnabled} = useContext(PermissionContext);
    const {refreshPageInformation} = useContext(RouteContext);

    const [dragItem, setDragItem] = useState<string>('');
    const [dropTo, setDropTo] = useState<number>(0);

    const getPanelsList = useCallback(data => dispatch({type: GET_PANELS_LIST.REQUEST, payload: data}), [dispatch]);
    const getDashboardsList = useCallback(
        data => dispatch({type: GET_DASHBOARDS_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        analytics: {isLoading, dashboards},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        refreshPageInformation();
        if (isAnalyticViewEnabled) {
            getPanelsList({showAlert});
            getDashboardsList({showAlert});
        }
    }, []);

    return (
        <Box>
            <ClientSectionWrapper>
                {dashboards && dashboards.length > 0 ? (
                    <Table>
                        <TableHead>
                            {isAnalyticEditEnabled && <TableCell></TableCell>}
                            <TableCell width={230}>
                                <Typography sx={{fontWeight: 600}}>
                                    {t('orguser.analytics.dashboards.title')}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{fontWeight: 600}}>
                                    {t('orguser.analytics.dashboards.description')}
                                </Typography>
                            </TableCell>
                            <TableCell width={300}>
                                <Typography sx={{fontWeight: 600}}>
                                    {t('orguser.analytics.dashboards.panelsList')}
                                </Typography>
                            </TableCell>
                            {isAnalyticEditEnabled && (
                                <TableCell width={100}>
                                    <Typography sx={{fontWeight: 600}}>
                                        {t('orguser.analytics.dashboards.action')}
                                    </Typography>
                                </TableCell>
                            )}
                        </TableHead>
                        <TableBody>
                            {dashboards &&
                                dashboards.map((dashboard: DashboardItemsTypes, index: number) => {
                                    return (
                                        <DashboardsListRow
                                            key={`dashboard-${index}`}
                                            item={dashboard}
                                            dragItem={dragItem}
                                            setDragItem={setDragItem}
                                            dropTo={dropTo}
                                            setDropTo={setDropTo}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography sx={{mt: 2}}>
                        {t(isLoading ? 'common.pleaseWait' : 'orguser.analytics.dashboards.noneDashboards')}
                    </Typography>
                )}
            </ClientSectionWrapper>
        </Box>
    );
};

export default DashboardsList;
