import {FormGdprFieldsType} from 'appRedux/actions/forms/types';

export const getIsNoneGdprField = (gdprFields: FormGdprFieldsType): boolean => {
    for (const [key, value] of Object.entries(gdprFields)) {
        if (value > 0) {
            return true;
        }
    }
    return false;
};
