import React, {FC, useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {ResourceFieldCalendarItemTypes} from 'appRedux/actions/resourceFields/types';
import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {CREATE_REQUESTER_CASE_CALENDAR, UPDATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {TAB_STEP_TWO} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';
import {STATUS_OPENING} from 'config/index';

interface CalendarItemType {
    field: FormFieldTypes;
    item: ResourceFieldCalendarItemTypes;
    currentCalendar?: RequesterCaseHasCalendarTypes;
    isRequesterSelected?: boolean;
    setCurrentTab: (value: number) => void;
}

const CalendarItem: FC<CalendarItemType> = ({field, item, currentCalendar, isRequesterSelected, setCurrentTab}) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const {showAlert} = useContext(AlertContext);

    const {
        requestCase: {
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {title, id} = item;

    const isAlreadySelected = currentCalendar && currentCalendar.resourceFieldCalendar === id;

    const createRequesterCaseCalendar = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const updateRequesterCaseCalendar = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const onCalendarClick = () => {
        if (!currentCalendar) {
            createRequesterCaseCalendar({
                id: Number(requestCase),
                formField: field.id,
                resourceField: field.resourceField,
                resourceFieldCalendar: id,
                showAlert,
                callback: () => {
                    setTimeout(() => {
                        setCurrentTab(TAB_STEP_TWO);
                    }, 500);
                },
            });
        } else if (status === STATUS_OPENING && isRequesterSelected) {
            updateRequesterCaseCalendar({
                uuid: currentCalendar.uuid,
                formField: field.id,
                resourceField: field.resourceField,
                resourceFieldCalendar: id,
                showAlert,
                callback: () => {
                    setTimeout(() => {
                        setCurrentTab(TAB_STEP_TWO);
                    }, 500);
                },
            });
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                mb: 1,
                backgroundColor: isAlreadySelected ? MAIN_CLIENT_COLOR : theme.palette.secondary.contrastText,
                borderRadius: 2,
                cursor: isAlreadySelected || status !== STATUS_OPENING ? 'initial' : 'pointer',
                '&:hover': {
                    backgroundColor: isAlreadySelected
                        ? MAIN_CLIENT_COLOR
                        : status === STATUS_OPENING
                        ? theme.palette.info.main
                        : theme.palette.secondary.contrastText,
                    '& p': {
                        color:
                            status === STATUS_OPENING || isAlreadySelected
                                ? theme.palette.background.default
                                : theme.palette.info.main,
                    },
                },
            }}
            onClick={onCalendarClick}
        >
            <Typography
                sx={{
                    fontWeight: 700,
                    color: isAlreadySelected ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default CalendarItem;
