import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {ResourceFieldSlotClientItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import SlotSelectorItem from 'pages/client/form/fieldTypes/resourceFieldSlot/SlotSelectorItem';

interface SlotsSelectorType {
    sectionId: number;
    field: FormFieldTypes;
}

const SlotsSelector: FC<SlotsSelectorType> = ({sectionId, field}) => {
    const [t] = useTranslation();

    const {
        requestCase: {currentCaseSlots},
        resourceFields: {allSlotFormItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentFieldSlots: ResourceFieldSlotClientItemTypes[] = allSlotFormItems.filter(
        item => item.resourceFieldId === field.resourceField,
    );

    return (
        <Box>
            <Typography sx={{fontSize: 14, mb: 1}}>{`${t('requester.casePage.selectAvailableSlots')}:`}</Typography>
            {currentFieldSlots.map((slot: ResourceFieldSlotClientItemTypes) => {
                const currentFieldSlot = currentCaseSlots.find(item => item.slotId === slot.id);
                return (
                    <SlotSelectorItem
                        key={slot.uuid}
                        sectionId={sectionId}
                        item={slot}
                        currentFieldSlot={currentFieldSlot}
                    />
                );
            })}
        </Box>
    );
};

export default SlotsSelector;
