import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {ResourceFieldBookframeItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import CalendarBookframeItem from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarBookframeItem';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {DATE_LABEL_FORMAT} from 'config/index';

interface CalendarStepFourType {
    currentCalendar: RequesterCaseHasCalendarTypes;
    setCurrentTab: (value: number) => void;
}

const CalendarStepFour: FC<CalendarStepFourType> = ({currentCalendar, setCurrentTab}) => {
    const [t] = useTranslation();

    const {calendarDate, resourceFieldCalendar} = currentCalendar;

    const {
        resourceFields: {allCalendarFormItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const calendarInfo = allCalendarFormItems.find(item => item.id === resourceFieldCalendar);

    if (!calendarInfo || !calendarDate) return null;

    const bookframes = calendarInfo.bookframes;
    const calendarDateObject = new Date(calendarDate);
    const currentWeekday = calendarDateObject.getDay();
    const selectedSlot = calendarInfo.slots.find(slot => slot.id === currentCalendar.resourceFieldCalendarSlot);

    if (!selectedSlot) return null;

    return (
        <Box>
            {calendarDateObject && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('requester.casePage.selectedDate')}:`}</Typography>
                    <Typography>{convertDateToCurrentLocale(calendarDateObject, DATE_LABEL_FORMAT)}</Typography>
                </Box>
            )}
            {bookframes.length > 0 ? (
                bookframes.map((bookframe: ResourceFieldBookframeItemTypes) => {
                    if (bookframe.weekday !== currentWeekday || selectedSlot.duration > bookframe.duration) {
                        return null;
                    }
                    return (
                        <CalendarBookframeItem
                            key={`calendar-bookframe-${bookframe.uuid}`}
                            currentCalendar={currentCalendar}
                            bookframe={bookframe}
                            setCurrentTab={setCurrentTab}
                        />
                    );
                })
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
        </Box>
    );
};

export default CalendarStepFour;
