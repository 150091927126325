import React, {FC, MouseEvent, useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import {InviteFormsTypes} from 'appRedux/actions/invites/types';
import {RootReducer} from 'appRedux/reducers';

import {PermissionContext} from 'contexts/permission/context';

import AgentAddMultipleButton from 'components/AgentScreenComponents/_buttons/AgentAddMultipleButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import InviteForm from 'components/Forms/Invites/InviteForm/InviteForm';
import SendInviteForm from 'components/Forms/Invites/SendInviteForm/SendInviteForm';
import InviteForUserForm from 'components/Forms/Invites/InviteForUserForm/InviteForUserForm';
import {getAdminsWithRequesterModePossiblePresented} from 'components/Invites/helper';

import {theme} from 'config/theme';

const AddInvite: FC = () => {
    const [t] = useTranslation();
    const {isInviteCreateEnabled, filtersInviteCreateAllowed} = useContext(PermissionContext);

    const {
        admin: {formsList, organizationAdminList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const inviteForms: InviteFormsTypes[] | undefined = useMemo(
        () =>
            formsList
                ?.filter(form => {
                    const isInviteCreateEnabledForForm =
                        isInviteCreateEnabled && filtersInviteCreateAllowed.includes(Number(form.id));
                    return form.isOnlyInvite && form.isActive && isInviteCreateEnabledForForm;
                })
                .map(form => ({formId: form.id, formTitle: form.title})),
        [formsList],
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const [showNewModal, setShowNewModal] = useState<boolean>(false);
    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
    const [showForUserModal, setShowForUserModal] = useState<boolean>(false);

    const toggleNewModal = () => {
        setShowNewModal(previous => !previous);
    };

    const toggleEmailModal = () => {
        setShowEmailModal(previous => !previous);
    };

    const toggleForUserModal = () => {
        setShowForUserModal(previous => !previous);
    };

    if (!inviteForms?.length) return null;

    const agentsWithPossibleRequesterMode = organizationAdminList
        ? getAdminsWithRequesterModePossiblePresented(organizationAdminList)
        : [];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                mb: 2,
            }}
        >
            <AgentAddMultipleButton title={t('orguser.invites.addInvite')} onClick={handleClick} open={open} />
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        mt: 1,
                        pl: 2,
                        pr: 2,
                        pt: 1,
                        pb: 1,
                        minWidth: 250,
                        borderRadius: 2,
                        overflow: 'visible',
                        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
                        backgroundColor: theme.palette.background.default,
                        '& ul': {
                            padding: 0,
                        },
                        overflowX: 'hidden',
                    },
                }}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            >
                <MenuItem onClick={toggleNewModal}>
                    <Typography>{t('orguser.invites.createInvite')}</Typography>
                </MenuItem>
                <MenuItem onClick={toggleEmailModal}>
                    <Typography>{t('orguser.invites.inviteByEmail')}</Typography>
                </MenuItem>
                {agentsWithPossibleRequesterMode.length > 0 && (
                    <MenuItem onClick={toggleForUserModal}>
                        <Typography>{t('orguser.invites.inviteForUser')}</Typography>
                    </MenuItem>
                )}
            </Menu>
            <ModalWrapper
                isShowModal={showNewModal}
                toggleModal={toggleNewModal}
                title={t('orguser.invites.createInvite')}
            >
                <InviteForm forms={inviteForms} setIsOpened={setShowNewModal} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showEmailModal}
                toggleModal={toggleEmailModal}
                title={t('orguser.invites.inviteByEmail')}
            >
                <SendInviteForm forms={inviteForms} setIsOpened={setShowEmailModal} />
            </ModalWrapper>
            <ModalWrapper
                isShowModal={showForUserModal}
                toggleModal={toggleForUserModal}
                title={t('orguser.invites.inviteForUser')}
            >
                <InviteForUserForm
                    forms={inviteForms}
                    users={agentsWithPossibleRequesterMode}
                    setIsOpened={setShowForUserModal}
                />
            </ModalWrapper>
        </Box>
    );
};

export default AddInvite;
