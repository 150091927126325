import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import TranslateSvgIcon from 'assets/icons/buttons/TranslateSvgIcon';

import {CustomAttributesItemTypes, CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';
import {RootReducer} from 'appRedux/reducers';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import CustomAttributeTranslationForm from 'components/Forms/CustomAttributes/CustomAttributeTranslationForm';
import {
    CUSTOM_ATTRIBUTE_TYPE_DROPDOWN,
    getCustomAttributeTranslationKeyword,
    getCustomAttributeOptionTranslationKeyword,
} from 'components/Forms/CustomAttributes/helper';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';

import {getLanguagesWithBadgeIdsArray} from 'pages/admin/customAttributes/helper';

import {DEFAULT_LANGUAGE_ID} from 'config/index';

interface TranslateAttributeButtonType {
    item: CustomAttributesItemTypes;
}

const TranslateAttributeButton: FC<TranslateAttributeButtonType> = ({item}) => {
    const [t] = useTranslation();

    const {id, title, type, options, translations, uuid} = item;

    const {
        admin: {languageList},
        profile: {profile},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [languageId, setLanguageId] = useState<number>(profile ? profile.languageId : DEFAULT_LANGUAGE_ID);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const keywordTitle = getCustomAttributeTranslationKeyword(id, 'title');
    const keywordDescription = getCustomAttributeTranslationKeyword(id, 'description');

    return (
        <>
            <IconButton
                title={t('common.buttons.translate')}
                onClick={() => setIsOpened(true)}
                disabled={Boolean(item.parentId)}
            >
                <TranslateSvgIcon isDisabled={Boolean(item.parentId)} />
            </IconButton>
            <ModalWrapper
                title={t('common.buttons.translate')}
                isShowModal={isOpened}
                toggleModal={toggleIsOpened}
                isWideModal
            >
                <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <LanguageSelector
                        languageId={languageId}
                        setLanguageId={setLanguageId}
                        languagesWithBadgeIds={getLanguagesWithBadgeIdsArray(
                            languageList,
                            translations,
                            options.length + 2,
                        )}
                    />
                </Box>
                <CustomAttributeTranslationForm
                    uuid={uuid}
                    title={t('superadmin.customAttributes.title')}
                    keyword={keywordTitle}
                    language={languageId}
                    currentTranslation={translations.find(
                        translation => translation.language === languageId && translation.keyword === keywordTitle,
                    )}
                    defaultValue={title}
                />
                {type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN && options.length > 0 && (
                    <Box>
                        <Typography sx={{fontWeight: 700, mb: 1}}>{`${t(
                            'superadmin.customAttributes.optionsList',
                        )}:`}</Typography>
                        {options.map((option: CustomAttributeOptionTypes, index: number) => {
                            const keywordOptionTitle = getCustomAttributeOptionTranslationKeyword(id, option.id);
                            return (
                                <CustomAttributeTranslationForm
                                    key={`option-translation-${option.uuid}`}
                                    uuid={uuid}
                                    title={`${t('superadmin.customAttributes.option')} ${index + 1}`}
                                    keyword={keywordOptionTitle}
                                    language={languageId}
                                    currentTranslation={translations.find(
                                        translation =>
                                            translation.language === languageId &&
                                            translation.keyword === keywordOptionTitle,
                                    )}
                                    defaultValue={option.title}
                                />
                            );
                        })}
                    </Box>
                )}
            </ModalWrapper>
        </>
    );
};

export default TranslateAttributeButton;
