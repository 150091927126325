import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Markdown from 'react-markdown';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {FORM_FIELD_COPY, FORM_FIELD_DELETE, FORM_FIELD_DOWN, FORM_FIELD_UP} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {RESOURCE_FIELD_TYPE_CALENDAR, RESOURCE_FIELD_TYPE_SLOT_TICKET} from 'components/Forms/ResourceField/helper';

import TopPanel from 'pages/admin/updateForm/partials/FormStructure/TopPanel';
import {
    formValidationTypes,
    getFieldTitle,
    FORM_FIELD_RESOURCE_FIELD,
} from 'pages/admin/updateForm/partials/FormStructure/helper';
import {
    UPDATE_FIELD_KEYWORD,
    UPDATE_FIELD_POPUP_KEYWORD,
    REPLACE_FIELD_POPUP_KEYWORD,
} from 'pages/admin/updateForm/partials/constants';

import {CLIENT_BACKGROUND_COLOR} from 'config/theme';

interface FormFieldInformationType {
    isFirst: boolean;
    isLast: boolean;
    item: FormFieldTypes;
    setModalTitle: (value: string) => void;
    setEntityId: (value: number) => void;
    toggleModal: () => void;
    isPopup?: boolean;
    viewOnly: boolean;
}

const FormFieldInformation: FC<FormFieldInformationType> = ({
    item,
    isFirst,
    isLast,
    setModalTitle,
    setEntityId,
    toggleModal,
    isPopup,
    viewOnly,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        label,
        description,
        isRequired,
        id,
        validationType,
        type,
        resourceField,
        resourceFieldType,
        resourceFieldCalendar,
        resourceFieldSlot,
        resourceFieldBudget,
    } = item;

    const {
        resourceFields: {resourceFields, allCalendarItems, allSlotItems, allBudgetItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleFieldRemove = useCallback(
        data => dispatch({type: FORM_FIELD_DELETE.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldCopy = useCallback(data => dispatch({type: FORM_FIELD_COPY.REQUEST, payload: data}), [dispatch]);

    const handleFieldUpPriority = useCallback(
        data => dispatch({type: FORM_FIELD_UP.REQUEST, payload: data}),
        [dispatch],
    );

    const handleFieldDownPriority = useCallback(
        data => dispatch({type: FORM_FIELD_DOWN.REQUEST, payload: data}),
        [dispatch],
    );

    const onEditClick = () => {
        toggleModal();
        setModalTitle(isPopup ? UPDATE_FIELD_POPUP_KEYWORD : UPDATE_FIELD_KEYWORD);
        setEntityId(id);
    };

    const onCopyClick = () => {
        handleFieldCopy({
            id,
            showAlert,
        });
    };

    const onReplaceClick = () => {
        toggleModal();
        setModalTitle(REPLACE_FIELD_POPUP_KEYWORD);
        setEntityId(id);
    };

    const onDeleteClick = () => {
        handleFieldRemove({
            id,
            showAlert,
        });
    };

    const onUpClick = () => {
        handleFieldUpPriority({
            id,
            showAlert,
        });
    };

    const onDownClick = () => {
        handleFieldDownPriority({
            id,
            showAlert,
        });
    };

    const selectedResourceField =
        type === FORM_FIELD_RESOURCE_FIELD
            ? resourceFields.find(resourceFieldItem => resourceFieldItem.id === Number(resourceField))
            : null;

    const selectedResourceFieldCalendar =
        type === FORM_FIELD_RESOURCE_FIELD
            ? allCalendarItems.find(
                  resourceFieldCalendarItem => resourceFieldCalendarItem.id === Number(resourceFieldCalendar),
              )
            : null;

    const selectedResourceFieldSlot =
        type === FORM_FIELD_RESOURCE_FIELD
            ? allSlotItems.find(resourceFieldSlotItem => resourceFieldSlotItem.id === Number(resourceFieldSlot))
            : null;

    const selectedResourceFieldBudget =
        type === FORM_FIELD_RESOURCE_FIELD
            ? allBudgetItems.find(resourceFieldBudgetItem => resourceFieldBudgetItem.id === Number(resourceFieldBudget))
            : null;

    return (
        <Box
            sx={{
                my: 1,
                borderColor: CLIENT_BACKGROUND_COLOR,
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: 'solid',
                minHeight: 35,
            }}
        >
            <TopPanel
                isFirst={isFirst}
                isLast={isLast}
                entityId={id}
                typeTitle={t(getFieldTitle(item))}
                title={label}
                validationType={validationType ? t((formValidationTypes as any)[validationType] || '') : ''}
                isRequired={isRequired}
                onEditClick={onEditClick}
                onCopyClick={onCopyClick}
                onReplaceClick={onReplaceClick}
                onDeleteClick={onDeleteClick}
                onUpClick={onUpClick}
                onDownClick={onDownClick}
                viewOnly={viewOnly}
                showReplaceButton={!isPopup}
                isCopyDenied={type === FORM_FIELD_RESOURCE_FIELD}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    px: 1,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        '& p': {
                            px: 1,
                            fontSize: 14,
                            fontWeight: 300,
                        },
                    }}
                >
                    <Markdown>{description}</Markdown>
                </Box>
            </Box>
            {type === FORM_FIELD_RESOURCE_FIELD && selectedResourceField && (
                <Box sx={{p: 2, pt: 1}}>
                    <Divider sx={{mb: 2}} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 1,
                        }}
                    >
                        <Typography variant="body2" sx={{fontWeight: 700, mr: 1}}>
                            {`${t('orguser.forms.formField.resourceField')}:`}
                        </Typography>
                        <Typography variant="body2">{selectedResourceField.title}</Typography>
                    </Box>
                    {resourceFieldType === RESOURCE_FIELD_TYPE_CALENDAR && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Typography variant="body2" sx={{fontWeight: 700, mr: 1}}>
                                {`${t('orguser.forms.formField.resourceFieldCalendar')}:`}
                            </Typography>
                            <Typography variant="body2">
                                {selectedResourceFieldCalendar
                                    ? selectedResourceFieldCalendar.title
                                    : t('orguser.forms.formField.resourceFieldCalendarRequesterSelected')}
                            </Typography>
                        </Box>
                    )}
                    {resourceFieldType === RESOURCE_FIELD_TYPE_SLOT_TICKET && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Typography variant="body2" sx={{fontWeight: 700, mr: 1}}>
                                {`${t('orguser.forms.formField.resourceFieldSlot')}:`}
                            </Typography>
                            <Typography variant="body2">
                                {selectedResourceFieldSlot
                                    ? selectedResourceFieldSlot.title
                                    : t('orguser.forms.formField.resourceFieldSlotRequesterSelected')}
                            </Typography>
                        </Box>
                    )}
                    {selectedResourceFieldBudget && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                mb: 1,
                            }}
                        >
                            <Typography variant="body2" sx={{fontWeight: 700, mr: 1}}>
                                {`${t('orguser.forms.formField.resourceFieldBudget')}:`}
                            </Typography>
                            <Typography variant="body2">{selectedResourceFieldBudget.title}</Typography>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default FormFieldInformation;
