import moment from 'moment';
import {TFunction} from 'i18next';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {ResourceFieldBookframeItemTypes} from 'appRedux/actions/resourceFields/types';

import {TabItemType} from 'components/TabsWrapper/helper';
import {getResourceFieldWeekdayLabel} from 'components/Forms/ResourceField/helper';

import {DATE_TIME_LABEL_FORMAT} from 'config/index';

export const TAB_STEP_ONE = 1;
export const TAB_STEP_TWO = 2;
export const TAB_STEP_THREE = 3;
export const TAB_STEP_FOUR = 4;
export const TAB_STEP_FIVE = 5;

export const getTabsList = (currentCalendar?: RequesterCaseHasCalendarTypes): TabItemType[] => {
    return [
        {
            title: 'requester.casePage.calendarSteps.stepOne',
            tabNumber: TAB_STEP_ONE,
            isDisabled: false,
        },
        {
            title: 'requester.casePage.calendarSteps.stepTwo',
            tabNumber: TAB_STEP_TWO,
            isDisabled: !currentCalendar || !currentCalendar.resourceFieldCalendar,
        },
        {
            title: 'requester.casePage.calendarSteps.stepThree',
            tabNumber: TAB_STEP_THREE,
            isDisabled: !currentCalendar || !currentCalendar.resourceFieldCalendarSlot,
        },
        {
            title: 'requester.casePage.calendarSteps.stepFour',
            tabNumber: TAB_STEP_FOUR,
            isDisabled: !currentCalendar || !currentCalendar.calendarDate,
        },
        {
            title: 'requester.casePage.calendarSteps.stepFive',
            tabNumber: TAB_STEP_FIVE,
            isDisabled: !currentCalendar || !currentCalendar.resourceFieldCalendarBookframe,
        },
    ];
};

export const getInitialStepTab = (currentCalendar?: RequesterCaseHasCalendarTypes): number => {
    if (currentCalendar && currentCalendar.resourceFieldCalendarBookframe) {
        return TAB_STEP_FIVE;
    }
    if (currentCalendar && currentCalendar.calendarDate) {
        return TAB_STEP_FOUR;
    }
    if (currentCalendar && currentCalendar.resourceFieldCalendarSlot) {
        return TAB_STEP_THREE;
    }
    if (currentCalendar && currentCalendar.resourceFieldCalendar) {
        return TAB_STEP_TWO;
    }
    return TAB_STEP_ONE;
};

export const getCalendarStepTitle = (step: number, currentCalendar?: RequesterCaseHasCalendarTypes): string => {
    switch (step) {
        case TAB_STEP_ONE:
            return 'requester.casePage.calendarStepTitles.stepOne';
        case TAB_STEP_TWO:
            return 'requester.casePage.calendarStepTitles.stepTwo';
        case TAB_STEP_THREE:
            return 'requester.casePage.calendarStepTitles.stepThree';
        case TAB_STEP_FOUR:
            return 'requester.casePage.calendarStepTitles.stepFour';
        case TAB_STEP_FIVE:
            return currentCalendar ? getCalendarStatusLabel(currentCalendar.status) : '';
        default:
            return '';
    }
};

export const REQUESTER_CASE_HAS_CALENDAR_CREATED = 0;
export const REQUESTER_CASE_HAS_CALENDAR_RESERVED = 1;
export const REQUESTER_CASE_HAS_CALENDAR_BOOKED = 2;
export const REQUESTER_CASE_HAS_CALENDAR_CLEARED = 3;

export const getCalendarStatusLabel = (status: number): string => {
    switch (status) {
        case REQUESTER_CASE_HAS_CALENDAR_CREATED:
            return 'requester.casePage.calendarStatus.created';
        case REQUESTER_CASE_HAS_CALENDAR_RESERVED:
            return 'requester.casePage.calendarStatus.reserved';
        case REQUESTER_CASE_HAS_CALENDAR_BOOKED:
            return 'requester.casePage.calendarStatus.booked';
        case REQUESTER_CASE_HAS_CALENDAR_CLEARED:
            return 'requester.casePage.calendarStatus.cleared';
    }
    return '';
};

export const getCalendarStatusDateTime = (budget: RequesterCaseHasCalendarTypes): string => {
    const {status, createdAt, reservedAt, bookedAt, clearedAt} = budget;
    switch (status) {
        case REQUESTER_CASE_HAS_CALENDAR_CREATED:
            return moment(createdAt).format(DATE_TIME_LABEL_FORMAT);
        case REQUESTER_CASE_HAS_CALENDAR_RESERVED:
            return reservedAt ? moment(reservedAt).format(DATE_TIME_LABEL_FORMAT) : '';
        case REQUESTER_CASE_HAS_CALENDAR_BOOKED:
            return bookedAt ? moment(bookedAt).format(DATE_TIME_LABEL_FORMAT) : '';
        case REQUESTER_CASE_HAS_CALENDAR_CLEARED:
            return clearedAt ? moment(clearedAt).format(DATE_TIME_LABEL_FORMAT) : '';
    }
    return '';
};

export const getDateSelectionPossibleWeekdays = (bookframes: ResourceFieldBookframeItemTypes[]): number[] => {
    const results: number[] = [];
    bookframes.forEach(item => {
        results.push(item.weekday);
    });
    return results;
};

export const getDateSelectionPossibleWeekdaysString = (t: TFunction, weekdays: number[]): string => {
    const results: string[] = [];
    weekdays.forEach(item => {
        const result = t(getResourceFieldWeekdayLabel(item));
        if (!results.includes(result)) {
            results.push(result);
        }
    });
    return results.join(', ');
};
