import React, {FC, useState} from 'react';

import {tabs, TAB_FIELD_DATA} from 'components/Forms/FormBuilder/FieldForm/helper';
import FormFieldPopupContent from 'components/Forms/FormBuilder/FieldForm/partials/FormFieldPopupContent';
import TabsWrapper from 'components/TabsWrapper/TabsWrapper';

interface UpdateFormFieldType {
    fieldId: number;
    isPopup?: boolean;
}

const UpdateFormField: FC<UpdateFormFieldType> = ({fieldId, isPopup}) => {
    const [currentTab, setCurrentTab] = useState<number>(TAB_FIELD_DATA);
    return (
        <TabsWrapper tabItems={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab}>
            <FormFieldPopupContent fieldId={fieldId} isPopup={isPopup} currentTab={currentTab} />
        </TabsWrapper>
    );
};

export default UpdateFormField;
