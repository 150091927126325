import React, {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {PermissionContext} from 'contexts/permission/context';

import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import DashboardCreateForm from 'components/Forms/Dashboard/DashboardCreateForm';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';

const AddDashboardButton: FC = () => {
    const [t] = useTranslation();

    const {isAnalyticCreateEnabled} = useContext(PermissionContext);

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleModal = () => {
        setIsOpened(previous => !previous);
    };

    if (!isAnalyticCreateEnabled) return null;

    return (
        <Box
            sx={{
                mb: 2,
            }}
        >
            <AgentInverseButton title={t('orguser.analytics.dashboards.addDashboard')} onClick={toggleModal} />
            <ModalWrapper
                isShowModal={isOpened}
                toggleModal={toggleModal}
                title={t('orguser.analytics.dashboards.addDashboard')}
                isWideModal
            >
                <DashboardCreateForm closeModal={() => setIsOpened(false)} />
            </ModalWrapper>
        </Box>
    );
};

export default AddDashboardButton;
