import React, {ReactNode, useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import get from 'lodash/get';

import {PermissionContext, PermissionContextType} from 'contexts/permission/context';

import {LOCAL_STORAGE_TOKEN} from 'services/localStorage';

interface ContextType {
    children: ReactNode;
}

const PermissionContextWrapper: React.FC<ContextType> = ({children}) => {
    const [isOrganizationViewEnabled, setIsOrganizationViewEnabled] = useState<boolean>(false);
    const [isOrganizationEditEnabled, setIsOrganizationEditEnabled] = useState<boolean>(false);

    const [isAnalyticViewEnabled, setIsAnalyticViewEnabled] = useState<boolean>(false);
    const [isAnalyticCreateEnabled, setIsAnalyticCreateEnabled] = useState<boolean>(false);
    const [isAnalyticEditEnabled, setIsAnalyticEditEnabled] = useState<boolean>(false);
    const [isAnalyticDeleteEnabled, setIsAnalyticDeleteEnabled] = useState<boolean>(false);

    const [filtersAnalyticViewAllowed, setFiltersAnalyticViewAllowed] = useState<number[]>([]);
    const [filtersAnalyticCreateAllowed, setFiltersAnalyticCreateAllowed] = useState<number[]>([]);
    const [filtersAnalyticEditAllowed, setFiltersAnalyticEditAllowed] = useState<number[]>([]);
    const [filtersAnalyticDeleteAllowed, setFiltersAnalyticDeleteAllowed] = useState<number[]>([]);

    const [isRoleViewEnabled, setIsRoleViewEnabled] = useState<boolean>(false);
    const [isRoleCreateEnabled, setIsRoleCreateEnabled] = useState<boolean>(false);
    const [isRoleEditEnabled, setIsRoleEditEnabled] = useState<boolean>(false);
    const [isRoleDeleteEnabled, setIsRoleDeleteEnabled] = useState<boolean>(false);

    const [isUserViewEnabled, setIsUserViewEnabled] = useState<boolean>(false);
    const [isUserCreateEnabled, setIsUserCreateEnabled] = useState<boolean>(false);
    const [isUserEditEnabled, setIsUserEditEnabled] = useState<boolean>(false);
    const [isUserDeleteEnabled, setIsUserDeleteEnabled] = useState<boolean>(false);

    const [isFormViewEnabled, setIsFormViewEnabled] = useState<boolean>(false);
    const [isFormCreateEnabled, setIsFormCreateEnabled] = useState<boolean>(false);
    const [isFormEditEnabled, setIsFormEditEnabled] = useState<boolean>(false);
    const [isFormDeleteEnabled, setIsFormDeleteEnabled] = useState<boolean>(false);

    const [filtersFormViewAllowed, setFiltersFormViewAllowed] = useState<number[]>([]);
    const [filtersFormEditAllowed, setFiltersFormEditAllowed] = useState<number[]>([]);
    const [filtersFormDeleteAllowed, setFiltersFormDeleteAllowed] = useState<number[]>([]);

    const [isTranslationViewEnabled, setIsTranslationViewEnabled] = useState<boolean>(false);
    const [isTranslationEditEnabled, setIsTranslationEditEnabled] = useState<boolean>(false);
    const [isTranslationDeleteEnabled, setIsTranslationDeleteEnabled] = useState<boolean>(false);

    const [filtersTranslationViewAllowed, setFiltersTranslationViewAllowed] = useState<number[]>([]);
    const [filtersTranslationEditAllowed, setFiltersTranslationEditAllowed] = useState<number[]>([]);
    const [filtersTranslationDeleteAllowed, setFiltersTranslationDeleteAllowed] = useState<number[]>([]);

    const [isInviteViewEnabled, setIsInviteViewEnabled] = useState<boolean>(false);
    const [isInviteCreateEnabled, setIsInviteCreateEnabled] = useState<boolean>(false);
    const [isInviteDeleteEnabled, setIsInviteDeleteEnabled] = useState<boolean>(false);

    const [filtersInviteViewAllowed, setFiltersInviteViewAllowed] = useState<number[]>([]);
    const [filtersInviteCreateAllowed, setFiltersInviteCreateAllowed] = useState<number[]>([]);
    const [filtersInviteDeleteAllowed, setFiltersInviteDeleteAllowed] = useState<number[]>([]);

    const [isCaseViewEnabled, setIsCaseViewEnabled] = useState<boolean>(false);
    const [isCaseEditEnabled, setIsCaseEditEnabled] = useState<boolean>(false);

    const [filtersCaseViewAllowed, setFiltersCaseViewAllowed] = useState<number[]>([]);
    const [filtersCaseEditAllowed, setFiltersCaseEditAllowed] = useState<number[]>([]);

    const [isBoardViewEnabled, setIsBoardViewEnabled] = useState<boolean>(false);

    const [isWorkWithBoardEnabled, setIsWorkWithBoardEnabled] = useState<boolean>(false);

    const [isModeSwitchPossible, setIsModeSwitchPossible] = useState<boolean>(false);

    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const toggleIsTokenUpdated = () => {
        setIsUpdated(previous => !previous);
    };

    useEffect(() => {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
        if (token) {
            const decodedToken = jwt_decode(token);

            console.log('decodedToken', decodedToken);

            setIsOrganizationViewEnabled(get(decodedToken, 'isOrganizationViewEnabled', false));
            setIsOrganizationEditEnabled(get(decodedToken, 'isOrganizationEditEnabled', false));

            setIsAnalyticViewEnabled(get(decodedToken, 'isAnalyticViewEnabled', false));
            setIsAnalyticCreateEnabled(get(decodedToken, 'isAnalyticCreateEnabled', false));
            setIsAnalyticEditEnabled(get(decodedToken, 'isAnalyticEditEnabled', false));
            setIsAnalyticDeleteEnabled(get(decodedToken, 'isAnalyticDeleteEnabled', false));

            setFiltersAnalyticViewAllowed(get(decodedToken, 'filtersAnalyticViewAllowed', []));
            setFiltersAnalyticCreateAllowed(get(decodedToken, 'filtersAnalyticCreateAllowed', []));
            setFiltersAnalyticEditAllowed(get(decodedToken, 'filtersAnalyticEditAllowed', []));
            setFiltersAnalyticDeleteAllowed(get(decodedToken, 'filtersAnalyticDeleteAllowed', []));

            setIsRoleViewEnabled(get(decodedToken, 'isRoleViewEnabled', false));
            setIsRoleCreateEnabled(get(decodedToken, 'isRoleCreateEnabled', false));
            setIsRoleEditEnabled(get(decodedToken, 'isRoleEditEnabled', false));
            setIsRoleDeleteEnabled(get(decodedToken, 'isRoleDeleteEnabled', false));

            setIsUserViewEnabled(get(decodedToken, 'isUserViewEnabled', false));
            setIsUserCreateEnabled(get(decodedToken, 'isUserCreateEnabled', false));
            setIsUserEditEnabled(get(decodedToken, 'isUserEditEnabled', false));
            setIsUserDeleteEnabled(get(decodedToken, 'isUserDeleteEnabled', false));

            setIsFormViewEnabled(get(decodedToken, 'isFormViewEnabled', false));
            setIsFormCreateEnabled(get(decodedToken, 'isFormCreateEnabled', false));
            setIsFormEditEnabled(get(decodedToken, 'isFormEditEnabled', false));
            setIsFormDeleteEnabled(get(decodedToken, 'isFormDeleteEnabled', false));

            setFiltersFormViewAllowed(get(decodedToken, 'filtersFormViewAllowed', []));
            setFiltersFormEditAllowed(get(decodedToken, 'filtersFormEditAllowed', []));
            setFiltersFormDeleteAllowed(get(decodedToken, 'filtersFormDeleteAllowed', []));

            setIsTranslationViewEnabled(get(decodedToken, 'isTranslationViewEnabled', false));
            setIsTranslationEditEnabled(get(decodedToken, 'isTranslationEditEnabled', false));
            setIsTranslationDeleteEnabled(get(decodedToken, 'isTranslationDeleteEnabled', false));

            setFiltersTranslationViewAllowed(get(decodedToken, 'filtersTranslationViewAllowed', []));
            setFiltersTranslationEditAllowed(get(decodedToken, 'filtersTranslationEditAllowed', []));
            setFiltersTranslationDeleteAllowed(get(decodedToken, 'filtersTranslationDeleteAllowed', []));

            setIsInviteViewEnabled(get(decodedToken, 'isInviteViewEnabled', false));
            setIsInviteCreateEnabled(get(decodedToken, 'isInviteCreateEnabled', false));
            setIsInviteDeleteEnabled(get(decodedToken, 'isInviteDeleteEnabled', false));

            setFiltersInviteViewAllowed(get(decodedToken, 'filtersInviteViewAllowed', []));
            setFiltersInviteCreateAllowed(get(decodedToken, 'filtersInviteCreateAllowed', []));
            setFiltersInviteDeleteAllowed(get(decodedToken, 'filtersInviteDeleteAllowed', []));

            setIsCaseViewEnabled(get(decodedToken, 'isCaseViewEnabled', false));
            setIsCaseEditEnabled(get(decodedToken, 'isCaseEditEnabled', false));

            setFiltersCaseViewAllowed(get(decodedToken, 'filtersCaseViewAllowed', []));
            setFiltersCaseEditAllowed(get(decodedToken, 'filtersCaseEditAllowed', []));

            const isUserCanWorkWithBoard =
                get(decodedToken, 'isOrganizationEditEnabled', false) &&
                get(decodedToken, 'isUserEditEnabled', false) &&
                get(decodedToken, 'isFormEditEnabled', false);

            setIsBoardViewEnabled(get(decodedToken, 'isCaseViewEnabled', false) || isUserCanWorkWithBoard);
            setIsWorkWithBoardEnabled(isUserCanWorkWithBoard);

            setIsModeSwitchPossible(get(decodedToken, 'isModeSwitchPossible', false));
        }
    }, [isUpdated]);

    const context: PermissionContextType = {
        isOrganizationViewEnabled,
        isOrganizationEditEnabled,
        isAnalyticViewEnabled,
        isAnalyticCreateEnabled,
        isAnalyticEditEnabled,
        isAnalyticDeleteEnabled,
        filtersAnalyticViewAllowed,
        filtersAnalyticCreateAllowed,
        filtersAnalyticEditAllowed,
        filtersAnalyticDeleteAllowed,
        isRoleViewEnabled,
        isRoleCreateEnabled,
        isRoleEditEnabled,
        isRoleDeleteEnabled,
        isUserViewEnabled,
        isUserCreateEnabled,
        isUserEditEnabled,
        isUserDeleteEnabled,
        isFormViewEnabled,
        isFormCreateEnabled,
        isFormEditEnabled,
        isFormDeleteEnabled,
        filtersFormViewAllowed,
        filtersFormEditAllowed,
        filtersFormDeleteAllowed,
        isTranslationViewEnabled,
        isTranslationEditEnabled,
        isTranslationDeleteEnabled,
        filtersTranslationViewAllowed,
        filtersTranslationEditAllowed,
        filtersTranslationDeleteAllowed,
        isInviteViewEnabled,
        isInviteCreateEnabled,
        isInviteDeleteEnabled,
        filtersInviteViewAllowed,
        filtersInviteCreateAllowed,
        filtersInviteDeleteAllowed,
        isCaseViewEnabled,
        isCaseEditEnabled,
        filtersCaseViewAllowed,
        filtersCaseEditAllowed,
        isBoardViewEnabled,
        isWorkWithBoardEnabled,
        isModeSwitchPossible,
        toggleIsTokenUpdated,
    };

    return <PermissionContext.Provider value={context}>{children}</PermissionContext.Provider>;
};

export default PermissionContextWrapper;
