import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import {CREATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';
import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';
import {ClientFormContext} from 'contexts/clientForm/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import CalendarSteps from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarSteps';

import {STATUS_OPENING} from 'config/index';

interface ResourceFieldCalendarType {
    formId: number;
    field: FormFieldTypes;
}

const ResourceFieldCalendar: FC<ResourceFieldCalendarType> = ({formId, field}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const ref = useRef<HTMLDivElement | undefined>();

    const {showAlert} = useContext(AlertContext);
    const {errorField} = useContext(ClientFormContext);

    const [showCalendarSteps, setShowCalendarSteps] = useState<boolean>(false);

    const {id, resourceField, resourceFieldCalendar} = field;

    const createRequesterCaseCalendar = useCallback(
        data => dispatch({type: CREATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestCase: {
            currentCaseCalendars,
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const currentCalendar = currentCaseCalendars.find(item => item.formField === id);

    const onReserveClick = () => {
        if (resourceFieldCalendar) {
            createRequesterCaseCalendar({
                id: Number(requestCase),
                formField: id,
                resourceField,
                resourceFieldCalendar,
                showAlert,
            });
        } else {
            setShowCalendarSteps(true);
        }
    };

    return (
        <FormFieldWrapper wrapperRef={ref} formId={formId} field={field}>
            {currentCalendar || showCalendarSteps ? (
                <CalendarSteps field={field} currentCalendar={currentCalendar} />
            ) : (
                <Box sx={{width: '100%'}}>
                    <AgentSaveButton
                        isSubmit={false}
                        isDisabled={status !== STATUS_OPENING}
                        title={t('common.buttons.reserve')}
                        onClick={onReserveClick}
                    />
                </Box>
            )}
        </FormFieldWrapper>
    );
};

export default ResourceFieldCalendar;
