import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import {CREATE_PANEL, CREATE_PANEL_FOR_FORM_VERSION} from 'appRedux/actions/analytics';
import {PanelFormTypes, PanelHasStatusItemTypes, PanelHasFieldItemTypes} from 'appRedux/actions/analytics/types';

import {AlertContext} from 'contexts/alert/context';
import {PermissionContext} from 'contexts/permission/context';

import {initialValues} from 'components/Forms/Dashboard/validation';
import PanelForm from 'components/Forms/Dashboard/PanelForm';

interface PanelCreateFormType {
    closeModal: () => void;
}

const PanelCreateForm: FC<PanelCreateFormType> = ({closeModal}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);
    const {isAnalyticCreateEnabled} = useContext(PermissionContext);

    const [statuses, setStatuses] = useState<PanelHasStatusItemTypes[]>([]);
    const [panelFields, setPanelFields] = useState<PanelHasFieldItemTypes[]>([]);

    const createPanel = useCallback(data => dispatch({type: CREATE_PANEL.REQUEST, payload: data}), [dispatch]);
    const createPanelForFormVersion = useCallback(
        data => dispatch({type: CREATE_PANEL_FOR_FORM_VERSION.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: PanelFormTypes) => {
        if (isAnalyticCreateEnabled) {
            const versionId = values.version === 0 ? null : values.version;
            if (versionId) {
                createPanelForFormVersion({
                    ...values,
                    id: versionId,
                    showAlert,
                    callback: closeModal,
                    dashboardPanelHasStatuses: statuses,
                    dashboardPanelHasFields: panelFields,
                });
            } else {
                createPanel({
                    ...values,
                    version: null,
                    showAlert,
                    callback: closeModal,
                    dashboardPanelHasStatuses: statuses,
                    dashboardPanelHasFields: panelFields,
                });
            }
        }
    };

    return (
        <PanelForm
            initialValues={initialValues}
            onSubmitClicked={onSubmitClicked}
            statuses={statuses}
            setStatuses={setStatuses}
            panelFields={panelFields}
            setPanelFields={setPanelFields}
        />
    );
};

export default PanelCreateForm;
