import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseActivitiesTypes} from 'appRedux/actions/requestCase/types';

import ActivityReviewPopup from 'pages/agent/activityPage/ActivityReviewPopup';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {theme} from 'config/theme';

interface ActivitiesLogItemType {
    item: RequesterCaseActivitiesTypes;
}

const ActivitiesLogItem: FC<ActivitiesLogItemType> = ({item}) => {
    const [t] = useTranslation();

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleIsOpened = () => {
        setIsOpened(previous => !previous);
    };

    const {caseLabel, element, createdAt} = item;

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.default,
                width: '100%',
                mb: 1,
                borderRadius: 4,
                p: 2,
                cursor: 'pointer',
            }}
            onClick={toggleIsOpened}
        >
            <Typography sx={{fontWeight: 600, mb: 1}}>{caseLabel}</Typography>
            <Typography>{t(element ? `orguser.activities.types.${element.parameter}` : 'common.none')}</Typography>
            <Typography sx={{fontSize: 12, textAlign: 'end'}}>{convertDateToCurrentLocale(createdAt)}</Typography>
            <ActivityReviewPopup
                isOpened={isOpened}
                pages={[]}
                toggleIsOpened={toggleIsOpened}
                item={item}
                activityLogsList
            />
        </Box>
    );
};

export default ActivitiesLogItem;
