import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';

import TabsWrapper from 'components/TabsWrapper/TabsWrapper';

import {
    getTabsList,
    getInitialStepTab,
    getCalendarStepTitle,
    TAB_STEP_ONE,
    TAB_STEP_TWO,
    TAB_STEP_THREE,
    TAB_STEP_FOUR,
    TAB_STEP_FIVE,
} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';
import CalendarStepOne from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepOne';
import CalendarStepTwo from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepTwo';
import CalendarStepThree from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepThree';
import CalendarStepFour from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepFour';
import CalendarStepFive from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarStepFive';

interface CalendarStepsType {
    field: FormFieldTypes;
    currentCalendar?: RequesterCaseHasCalendarTypes;
}

const CalendarSteps: FC<CalendarStepsType> = ({currentCalendar, field}) => {
    const [t] = useTranslation();

    const [currentTab, setCurrentTab] = useState<number>(getInitialStepTab(currentCalendar));

    return (
        <TabsWrapper tabItems={getTabsList(currentCalendar)} currentTab={currentTab} setCurrentTab={setCurrentTab}>
            <Typography sx={{fontWeight: 700, mb: 3}}>
                {t(getCalendarStepTitle(currentTab, currentCalendar))}
            </Typography>
            <Box>
                {currentTab === TAB_STEP_ONE && (
                    <CalendarStepOne field={field} currentCalendar={currentCalendar} setCurrentTab={setCurrentTab} />
                )}
                {currentTab === TAB_STEP_TWO && currentCalendar && (
                    <CalendarStepTwo currentCalendar={currentCalendar} setCurrentTab={setCurrentTab} />
                )}
                {currentTab === TAB_STEP_THREE && currentCalendar && (
                    <CalendarStepThree currentCalendar={currentCalendar} setCurrentTab={setCurrentTab} />
                )}
                {currentTab === TAB_STEP_FOUR && currentCalendar && (
                    <CalendarStepFour currentCalendar={currentCalendar} setCurrentTab={setCurrentTab} />
                )}
                {currentTab === TAB_STEP_FIVE && currentCalendar && (
                    <CalendarStepFive currentCalendar={currentCalendar} />
                )}
            </Box>
        </TabsWrapper>
    );
};

export default CalendarSteps;
