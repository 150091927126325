export const OPTION_ERROR_REPEAT = 1;
export const OPTION_ERROR_HTML_TAG = 2;

export const getOptionsListError = (optionsErrorType: number | null): string => {
    switch (optionsErrorType) {
        case OPTION_ERROR_REPEAT:
            return 'superadmin.customAttributes.optionsValidationTypes.optionsRepeatValidation';
        case OPTION_ERROR_HTML_TAG:
            return 'superadmin.customAttributes.optionsValidationTypes.htmlTagsDenied';
    }
    return '';
};
