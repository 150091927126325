import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';
import {FormFieldTypes} from 'appRedux/actions/forms/types';

import {TYPE_BUDGET_DEFINITION_AGENT, TYPE_BUDGET_DEFINITION_BOTH} from 'components/Forms/FormBuilder/FieldForm/helper';

import {
    getBudgetStatusLabel,
    getBudgetStatusDateTime,
    REQUESTER_CASE_HAS_BUDGET_CREATED,
    REQUESTER_CASE_HAS_BUDGET_RESERVED,
} from 'pages/client/form/fieldTypes/resourceFieldBudget/helper';

import RequesterCaseHasBudgetAmount from 'pages/agent/requesterPage/resourceFields/RequesterCaseHasBudgetAmount';
import UpdateReservedBudget from 'pages/agent/requesterPage/resourceFields/UpdateReservedBudget';
import BudgetTitle from 'pages/agent/requesterPage/resourceFields/BudgetTitle';
import BudgetAskedAmount from 'pages/agent/requesterPage/resourceFields/BudgetAskedAmount';

interface CurrentBudgetInformationType {
    field: FormFieldTypes;
    currentBudget: RequesterCaseHasBudgetTypes;
}

const CurrentBudgetInformation: FC<CurrentBudgetInformationType> = ({field, currentBudget}) => {
    const [t] = useTranslation();
    const {caseId} = useParams();

    const {status} = currentBudget;

    const isAmountChangeByAgent = [TYPE_BUDGET_DEFINITION_AGENT, TYPE_BUDGET_DEFINITION_BOTH].includes(
        Number(field.typeBudgetDefinition),
    );

    const showAmount = status !== REQUESTER_CASE_HAS_BUDGET_CREATED;

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <BudgetTitle currentBudget={currentBudget} />
            <Divider sx={{mt: 2, mb: 2}} />
            {(status === REQUESTER_CASE_HAS_BUDGET_CREATED || status === REQUESTER_CASE_HAS_BUDGET_RESERVED) &&
            isAmountChangeByAgent &&
            field.resourceFieldBudgetInformation ? (
                <>
                    <UpdateReservedBudget
                        caseId={Number(caseId)}
                        currentBudget={currentBudget}
                        field={field}
                        resourceFieldBudgetInformation={field.resourceFieldBudgetInformation}
                    />
                </>
            ) : showAmount ? (
                <RequesterCaseHasBudgetAmount field={field} currentBudget={currentBudget} />
            ) : null}
            {field.typeBudgetDefinition === TYPE_BUDGET_DEFINITION_BOTH && (
                <>
                    <Divider sx={{mt: 2, mb: 2}} />
                    <BudgetAskedAmount field={field} currentBudget={currentBudget} />
                </>
            )}
            <Divider sx={{mt: 2, mb: 2}} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                    getBudgetStatusLabel(currentBudget.status),
                )}:`}</Typography>
                <Typography>{getBudgetStatusDateTime(currentBudget)}</Typography>
            </Box>
        </Box>
    );
};

export default CurrentBudgetInformation;
