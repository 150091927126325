import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import {GridColDef} from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import {PublicKeyOrganizationTypes} from 'appRedux/actions/crypto/types';

import {RouteContext} from 'contexts/route/context';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';

import FullNameWithTooltip from 'pages/agent/tableView/FullNameWithTooltip';
import OrganizationPublicKeyDeleteButton from 'pages/admin/publicKeys/OrganizationPublicKeyDeleteButton';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

interface OrganizationPublicKeysDataGridType {
    keysList: PublicKeyOrganizationTypes[];
}

const OrganizationPublicKeysDataGrid: FC<OrganizationPublicKeysDataGridType> = ({keysList}) => {
    const [t] = useTranslation();

    const {isSuperAdminPage} = useContext(RouteContext);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('superadmin.publicKeys.name'),
            minWidth: 250,
            valueGetter: ({row: {firstName, lastName, email}}) =>
                firstName && lastName ? `${lastName}, ${firstName}` : email,

            renderCell: ({value, row}) => {
                const {avatarLink, avatarType, firstName, lastName, email} = row;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <AvatarImage
                            size={32}
                            avatarLink={avatarLink}
                            avatarType={avatarType}
                            avatarTitle={getAvatarTitle(firstName, lastName, email)}
                        />
                        <FullNameWithTooltip name={value} />
                    </Box>
                );
            },
        },
        {
            field: 'title',
            headerName: t('superadmin.publicKeys.title'),
            minWidth: 150,
            flex: 0.8,
        },
        ...(isSuperAdminPage
            ? [
                  {
                      field: 'role',
                      headerName: t('superadmin.publicKeys.role'),
                      minWidth: 150,
                  } as GridColDef,
              ]
            : []),
        {
            field: 'createdAt',
            headerName: t('superadmin.publicKeys.createdAt'),
            minWidth: 150,
            valueGetter: ({value}) => convertDateToCurrentLocale(value),
        },
        {
            field: 'close',
            headerName: t('superadmin.publicKeys.action'),
            width: 100,
            renderCell: ({row}) => <OrganizationPublicKeyDeleteButton item={row} />,
        },
    ];

    return (
        <CustomDataGrid
            rows={keysList}
            columns={columns}
            getRowId={row => row.uuid}
            checkboxSelection={false}
            enablePaging
            isLarge
        />
    );
};

export default OrganizationPublicKeysDataGrid;
