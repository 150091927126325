import * as yup from 'yup';

import {REQUESTER_MODE_NOT_SET} from 'components/Forms/RoleForm/helper';

export const roleInitialValues = {
    title: '',
    requesterMode: REQUESTER_MODE_NOT_SET,
    isActive: true,
    isOrganizationEditEnabled: false,
    isOrganizationViewEnabled: false,
    isAnalyticViewEnabled: false,
    isAnalyticCreateEnabled: false,
    isAnalyticDeleteEnabled: false,
    isAnalyticEditEnabled: false,
    isRoleViewEnabled: false,
    isRoleCreateEnabled: false,
    isRoleDeleteEnabled: false,
    isRoleEditEnabled: false,
    isUserViewEnabled: false,
    isUserCreateEnabled: false,
    isUserDeleteEnabled: false,
    isUserEditEnabled: false,
    isFormViewEnabled: false,
    isFormCreateEnabled: false,
    isFormDeleteEnabled: false,
    isFormEditEnabled: false,
    isTranslationViewEnabled: false,
    isTranslationDeleteEnabled: false,
    isTranslationEditEnabled: false,
    isInviteViewEnabled: false,
    isInviteCreateEnabled: false,
    isInviteDeleteEnabled: false,
    isCaseViewEnabled: false,
    isCaseEditEnabled: false,
    roleFilters: [],
};

export const roleValidationSchema = yup.object({
    title: yup
        .string()
        .min(2, 'Role name should be of minimum 2 characters length')
        .max(100, 'Role name should be of maximum 100 characters length')
        .required('Role name is required'),
});
