import React from 'react';

export type PermissionContextType = {
    isOrganizationViewEnabled: boolean;
    isOrganizationEditEnabled: boolean;
    isAnalyticViewEnabled: boolean;
    isAnalyticCreateEnabled: boolean;
    isAnalyticDeleteEnabled: boolean;
    isAnalyticEditEnabled: boolean;
    filtersAnalyticViewAllowed: number[];
    filtersAnalyticCreateAllowed: number[];
    filtersAnalyticEditAllowed: number[];
    filtersAnalyticDeleteAllowed: number[];
    isRoleViewEnabled: boolean;
    isRoleCreateEnabled: boolean;
    isRoleEditEnabled: boolean;
    isRoleDeleteEnabled: boolean;
    isUserViewEnabled: boolean;
    isUserCreateEnabled: boolean;
    isUserEditEnabled: boolean;
    isUserDeleteEnabled: boolean;
    isFormViewEnabled: boolean;
    isFormCreateEnabled: boolean;
    isFormEditEnabled: boolean;
    isFormDeleteEnabled: boolean;
    filtersFormViewAllowed: number[];
    filtersFormEditAllowed: number[];
    filtersFormDeleteAllowed: number[];
    isTranslationViewEnabled: boolean;
    isTranslationEditEnabled: boolean;
    isTranslationDeleteEnabled: boolean;
    filtersTranslationViewAllowed: number[];
    filtersTranslationEditAllowed: number[];
    filtersTranslationDeleteAllowed: number[];
    isInviteViewEnabled: boolean;
    isInviteCreateEnabled: boolean;
    isInviteDeleteEnabled: boolean;
    filtersInviteViewAllowed: number[];
    filtersInviteCreateAllowed: number[];
    filtersInviteDeleteAllowed: number[];
    isCaseViewEnabled: boolean;
    isCaseEditEnabled: boolean;
    filtersCaseViewAllowed: number[];
    filtersCaseEditAllowed: number[];
    isBoardViewEnabled: boolean;
    isWorkWithBoardEnabled: boolean;
    isModeSwitchPossible: boolean;
    toggleIsTokenUpdated: () => void;
};

export const PermissionContext = React.createContext({} as PermissionContextType);
