import React from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {GridColDef} from '@mui/x-data-grid';

import {getCustomAttributeTypeLabel} from 'components/Forms/CustomAttributes/helper';

import EditAttributeButton from 'pages/admin/customAttributes/EditAttributeButton';
import DeleteAttributeButton from 'pages/admin/customAttributes/DeleteAttributeButton';
import TranslateAttributeButton from 'pages/admin/customAttributes/TranslateAttributeButton';

const getAttributesColumns = (): GridColDef[] | null => {
    const [t] = useTranslation();
    return [
        {
            field: 'title',
            headerName: t('superadmin.customAttributes.title'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{value}</Typography>;
            },
        },
        {
            field: 'type',
            headerName: t('superadmin.customAttributes.type'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{t(getCustomAttributeTypeLabel(value))}</Typography>;
            },
        },
        {
            field: 'isOnlyOrganizationUser',
            headerName: t('superadmin.customAttributes.isOnlyOrganizationUser'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{t(value ? 'common.yes' : 'common.no')}</Typography>;
            },
        },
        {
            field: 'isManaged',
            headerName: t('superadmin.customAttributes.isManaged'),
            flex: 1,
            renderCell: ({value}) => {
                return <Typography>{t(value ? 'common.yes' : 'common.no')}</Typography>;
            },
        },
        {
            field: 'actions',
            headerName: t('common.action'),
            align: 'right',
            maxWidth: 120,
            flex: 1,
            renderCell: ({row}) => {
                return (
                    <Box>
                        <EditAttributeButton item={row} />
                        <TranslateAttributeButton item={row} />
                        <DeleteAttributeButton item={row} />
                    </Box>
                );
            },
        },
    ];
};

export default getAttributesColumns;
