import {ElementType} from 'react';

import SuccessSvgIcon from 'assets/icons/buttons/SuccessSvgIcon';
import ErrorSvgIcon from 'assets/icons/buttons/ErrorSvgIcon';
import InfoSvgIcon from 'assets/icons/buttons/InfoSolidSvgIcon';

import {ALERT_TYPE_SUCCESS, ALERT_TYPE_ERROR, ALERT_TYPE_INFO} from 'config/index';
import {MAIN_CLIENT_COLOR, ERROR_TEXT_COLOR, TAG_COLOR_5} from 'config/theme';

export const getAlertBackgroundColor = (type: string): string => {
    switch (type) {
        case ALERT_TYPE_SUCCESS:
            return MAIN_CLIENT_COLOR;
        case ALERT_TYPE_INFO:
            return TAG_COLOR_5;
        case ALERT_TYPE_ERROR:
            return ERROR_TEXT_COLOR;
    }
    return MAIN_CLIENT_COLOR;
};

export const getAlertIcon = (type: string): ElementType => {
    switch (type) {
        case ALERT_TYPE_SUCCESS:
            return SuccessSvgIcon;
        case ALERT_TYPE_INFO:
            return InfoSvgIcon;
        case ALERT_TYPE_ERROR:
            return ErrorSvgIcon;
    }
    return InfoSvgIcon;
};
