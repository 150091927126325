import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {ORGANIZATION_ADMIN_LIST} from 'appRedux/actions/organizationAdmin';

import {AdminContext} from 'contexts/admin/context';
import {PermissionContext} from 'contexts/permission/context';

import InvitesFormList from 'components/Invites/InvitesFormList';

const Invites: FC = () => {
    const dispatch = useDispatch();

    const {myOrganization} = useContext(AdminContext);
    const {isInviteViewEnabled} = useContext(PermissionContext);

    const getOrganizationAdminsList = useCallback(
        data => dispatch({type: ORGANIZATION_ADMIN_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        if (myOrganization) {
            getOrganizationAdminsList({
                id: myOrganization.id,
            });
        }
    }, [myOrganization]);

    if (myOrganization && myOrganization.isInviteRegistration && isInviteViewEnabled) {
        return <InvitesFormList />;
    }
    return null;
};

export default Invites;
