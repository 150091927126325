import React, {FC, useContext, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import {
    TYPE_BUDGET_TRANSPARENCY_SHOW,
    TYPE_BUDGET_TRANSPARENCY_SHOW_EXISTS,
    TYPE_BUDGET_DEFINITION_AGENT,
    TYPE_BUDGET_DEFINITION_FIXED,
} from 'components/Forms/FormBuilder/FieldForm/helper';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import BudgetAvailabilityInformation from 'pages/client/form/fieldTypes/resourceFieldBudget/BudgetAvailabilityInformation';
import BudgetDefinitionInformation from 'pages/client/form/fieldTypes/resourceFieldBudget/BudgetDefinitionInformation';
import BudgetStatusLabel from 'pages/client/form/fieldTypes/resourceFieldBudget/BudgetStatusLabel';
import BookedAmountInformation from 'pages/client/form/fieldTypes/resourceFieldBudget/BookedAmountInformation';
import {REQUESTER_CASE_HAS_BUDGET_BOOKED} from 'pages/client/form/fieldTypes/resourceFieldBudget/helper';

import {ERROR_TEXT_COLOR} from 'config/theme';
import {STATUS_OPENING} from 'config/index';

interface ResourceFieldBudgetType {
    formId: number;
    field: FormFieldTypes;
}

const ResourceFieldBudget: FC<ResourceFieldBudgetType> = ({formId, field}) => {
    const [t] = useTranslation();

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField} = useContext(ClientFormContext);

    const {
        requestCase: {
            currentCaseBudgets,
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, resourceFieldBudgetInformation} = field;

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    const currentBudget = currentCaseBudgets.find(item => item.formField === id);

    if (!resourceFieldBudgetInformation) return null;

    const showAvailableAbsentAlert = !currentBudget && resourceFieldBudgetInformation.amount === 0;

    const showAvailabilityInformation =
        field.typeBudgetTransparency === TYPE_BUDGET_TRANSPARENCY_SHOW ||
        (field.typeBudgetTransparency === TYPE_BUDGET_TRANSPARENCY_SHOW_EXISTS &&
            resourceFieldBudgetInformation.amount > 0);

    return (
        <FormFieldWrapper
            wrapperRef={ref}
            formId={formId}
            field={field}
            hasDivider={[TYPE_BUDGET_DEFINITION_AGENT, TYPE_BUDGET_DEFINITION_FIXED].includes(
                Number(field.typeBudgetDefinition),
            )}
        >
            <Box sx={{position: 'relative'}}>
                {status === STATUS_OPENING && showAvailabilityInformation && (
                    <BudgetAvailabilityInformation resourceFieldBudgetInformation={resourceFieldBudgetInformation} />
                )}
                {showAvailableAbsentAlert ? (
                    <Alert sx={{mt: 1, mb: 1}} severity={'error'}>
                        <Typography sx={{fontSize: 14}}>{t('requester.casePage.availableBudgetAbsent')}</Typography>
                    </Alert>
                ) : !currentBudget || currentBudget.status !== REQUESTER_CASE_HAS_BUDGET_BOOKED ? (
                    <BudgetDefinitionInformation
                        field={field}
                        currentBudget={currentBudget}
                        budgetType={resourceFieldBudgetInformation.type}
                    />
                ) : null}
                {currentBudget && currentBudget.status === REQUESTER_CASE_HAS_BUDGET_BOOKED && (
                    <BookedAmountInformation
                        typeBudgetDefinition={Number(field.typeBudgetDefinition)}
                        currentBudget={currentBudget}
                        budgetType={resourceFieldBudgetInformation.type}
                    />
                )}
                {currentBudget ? <BudgetStatusLabel currentBudget={currentBudget} /> : null}
                {id === errorField && (
                    <Typography sx={{color: ERROR_TEXT_COLOR, fontSize: 12}}>
                        {t('requester.casePage.availableBudgetsAbsentSubmitImpossible')}
                    </Typography>
                )}
            </Box>
        </FormFieldWrapper>
    );
};

export default ResourceFieldBudget;
