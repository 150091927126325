import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Form, Formik, FormikProps} from 'formik';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import {Autocomplete, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';

import {RootReducer} from 'appRedux/reducers';
import {CaseWatcherCreatingFormTypes} from 'appRedux/actions/requestCase/types';
import {CREATE_CASE_WATCHER} from 'appRedux/actions/requestCase';

import {AlertContext} from 'contexts/alert/context';

import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';
import AgentInverseButton from 'components/AgentScreenComponents/_buttons/AgentInverseButton';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

interface CaseWatcherFormType {
    closeModal: () => void;
}

const CaseWatcherForm: FC<CaseWatcherFormType> = ({closeModal}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const {caseId} = useParams();

    const {showAlert} = useContext(AlertContext);

    const {
        crypto: {agentsWithCaseAccess},
        requestCase: {isLoading, currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const createCaseWatcher = useCallback(
        data => dispatch({type: CREATE_CASE_WATCHER.REQUEST, payload: data}),
        [dispatch],
    );

    const {watchers} = currentCase;

    const agentsFilteredList =
        agentsWithCaseAccess && watchers
            ? agentsWithCaseAccess
                  .filter(item => {
                      return !watchers.some(watcher => Number(watcher.watcherId) === Number(item.id));
                  })
                  .sort((a, b) => a.firstName?.localeCompare(b.firstName || '') || 0)
            : [];

    return (
        <Formik
            initialValues={{
                watcher: 0,
            }}
            onSubmit={values => {
                createCaseWatcher({
                    ...values,
                    id: Number(caseId),
                    callback: closeModal,
                    showAlert,
                });
            }}
        >
            {(formik: FormikProps<CaseWatcherCreatingFormTypes>) => {
                return (
                    <Form>
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                value={agentsFilteredList.find(agent => agent.id === formik.values.watcher) || null}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('watcher', newValue ? newValue.id : '');
                                }}
                                options={agentsFilteredList}
                                getOptionLabel={option =>
                                    option.firstName && option.lastName
                                        ? `${option.firstName} ${option.lastName}`
                                        : option.email
                                }
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={(props, option) => {
                                    const {avatarType, avatarLink, firstName, lastName, email} = option;
                                    const avatarTitle = getAvatarTitle(firstName, lastName, email);

                                    return (
                                        <li {...props}>
                                            <AvatarImage
                                                size={25}
                                                avatarType={avatarType}
                                                avatarLink={avatarLink}
                                                avatarTitle={avatarTitle}
                                            />
                                            <Typography sx={{ml: 1, fontWeight: 600}}>
                                                {firstName && lastName ? `${firstName} ${lastName}` : email}
                                            </Typography>
                                        </li>
                                    );
                                }}
                                renderInput={params => {
                                    const selectedAgent = agentsFilteredList.find(
                                        agent => agent.id === formik.values.watcher,
                                    );
                                    const avatarTitle = selectedAgent
                                        ? getAvatarTitle(
                                              selectedAgent.firstName,
                                              selectedAgent.lastName,
                                              selectedAgent.email,
                                          )
                                        : null;

                                    return (
                                        <TextField
                                            {...params}
                                            name="watcher"
                                            variant="standard"
                                            fullWidth
                                            label="Select Watcher"
                                            onChange={formik.handleChange}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: selectedAgent && avatarTitle && (
                                                    <Box sx={{mr: 1}}>
                                                        <AvatarImage
                                                            size={25}
                                                            avatarType={selectedAgent.avatarType}
                                                            avatarLink={selectedAgent.avatarLink}
                                                            avatarTitle={avatarTitle}
                                                        />
                                                    </Box>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <AgentInverseButton title={t('common.buttons.cancel')} onClick={closeModal} />
                            <AgentSaveButton isLoading={isLoading} isSubmit title={t('common.buttons.confirm')} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CaseWatcherForm;
