import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasSlotTypes} from 'appRedux/actions/requestCase/types';

import DescriptionPopover from 'components/DescriptionPopover/DescriptionPopover';

import {getSlotStatusLabel, getSlotStatusDateTime} from 'pages/client/form/fieldTypes/resourceFieldSlot/helper';

interface SlotInformationItemType {
    currentSlot: RequesterCaseHasSlotTypes;
}

const SlotInformationItem: FC<SlotInformationItemType> = ({currentSlot}) => {
    const [t] = useTranslation();
    return (
        <Box key={`selectedSlot-${currentSlot.uuid}`}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.title')}:`}</Typography>
                <Typography>{currentSlot.title}</Typography>
                {currentSlot.description && <DescriptionPopover text={currentSlot.description} />}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t('orguser.resourceFields.eventName')}:`}</Typography>
                <Typography>{currentSlot.eventName}</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(getSlotStatusLabel(currentSlot.status))}:`}</Typography>
                <Typography>{getSlotStatusDateTime(currentSlot)}</Typography>
            </Box>
        </Box>
    );
};

export default SlotInformationItem;
