import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseHasSlotTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import InformationWrapper from 'pages/agent/requesterPage/wrappers/InformationWrapper';
import SlotInformationItem from 'pages/agent/requesterPage/resourceFields/SlotInformationItem';

import {getFormTranslatedLabel} from 'helpers/translationsHelper';

interface RequesterCaseHasSelectedSlotsInformationType {
    field: FormFieldTypes;
    keywordTitle: string;
    keywordDescription: string;
}

const RequesterCaseHasSelectedSlotsInformation: FC<RequesterCaseHasSelectedSlotsInformationType> = ({
    field,
    keywordTitle,
    keywordDescription,
}) => {
    const [t] = useTranslation();

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCaseSlots},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const selectedSlots = currentCaseSlots
        ? currentCaseSlots.filter(slot => slot.resourceFieldId === field.resourceField)
        : [];

    return (
        <InformationWrapper
            title={getFormTranslatedLabel(translations, keywordTitle, field.label)}
            description={getFormTranslatedLabel(translations, keywordDescription, field.description)}
        >
            {selectedSlots.length === 0 ? (
                <Typography>{t('common.none')}</Typography>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    {selectedSlots.map((currentSlot: RequesterCaseHasSlotTypes, index: number) => {
                        return (
                            <Box key={`slot-container-${currentSlot.uuid}`}>
                                <SlotInformationItem currentSlot={currentSlot} />
                                {index + 1 < selectedSlots.length && (
                                    <Divider
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                        }}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Box>
            )}
        </InformationWrapper>
    );
};

export default RequesterCaseHasSelectedSlotsInformation;
