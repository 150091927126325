import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {ResourceFieldCalendarSlotItemTypes} from 'appRedux/actions/resourceFields/types';
import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {UPDATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {TAB_STEP_THREE} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';
import {STATUS_OPENING} from 'config/index';

interface CalendarSlotItemType {
    currentCalendar: RequesterCaseHasCalendarTypes;
    slot: ResourceFieldCalendarSlotItemTypes;
    setCurrentTab: (value: number) => void;
}

const CalendarSlotItem: FC<CalendarSlotItemType> = ({slot, currentCalendar, setCurrentTab}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {formField, resourceField, resourceFieldCalendar} = currentCalendar;

    const {showAlert} = useContext(AlertContext);

    const {
        requestCase: {
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, name, duration} = slot;

    const updateRequesterCaseCalendar = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const isAlreadySelected = currentCalendar.resourceFieldCalendarSlot === id;

    const onCalendarSlotClick = () => {
        if (status === STATUS_OPENING) {
            updateRequesterCaseCalendar({
                uuid: currentCalendar.uuid,
                formField,
                resourceField,
                resourceFieldCalendar,
                resourceFieldCalendarSlot: id,
                calendarDate: null,
                resourceFieldCalendarBookframe: null,
                showAlert,
                callback: () => {
                    setTimeout(() => {
                        setCurrentTab(TAB_STEP_THREE);
                    }, 500);
                },
            });
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                mb: 1,
                backgroundColor: isAlreadySelected ? MAIN_CLIENT_COLOR : theme.palette.secondary.contrastText,
                borderRadius: 2,
                cursor: isAlreadySelected || status !== STATUS_OPENING ? 'initial' : 'pointer',
                '&:hover': {
                    backgroundColor: isAlreadySelected
                        ? MAIN_CLIENT_COLOR
                        : status === STATUS_OPENING
                        ? theme.palette.info.main
                        : theme.palette.secondary.contrastText,
                    '& p': {
                        color:
                            status === STATUS_OPENING || isAlreadySelected
                                ? theme.palette.background.default
                                : theme.palette.info.main,
                    },
                },
            }}
            onClick={onCalendarSlotClick}
        >
            <Typography
                sx={{
                    fontWeight: 700,
                    color: isAlreadySelected ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {name}
            </Typography>
            <Typography
                sx={{
                    color: isAlreadySelected ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {`${duration} ${t('common.units.minutes')}`}
            </Typography>
        </Box>
    );
};

export default CalendarSlotItem;
