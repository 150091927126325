import moment from 'moment';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';

export const getInitialDate = (initialValues: RequesterCaseHasCalendarTypes) => {
    const {calendarDate} = initialValues;

    if (!calendarDate) return new Date();

    const dateInFormat = moment(calendarDate).format('YYYY-MM-DD');
    const dateObject = new Date(dateInFormat);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth();
    const date = dateObject.getDate();

    return new Date(year, month, date);
};
