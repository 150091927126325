import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {GET_USER_SESSIONS} from 'appRedux/actions/auth';
import {RootReducer} from 'appRedux/reducers';
import {UserSessionTypes} from 'appRedux/actions/auth/types';

import {AlertContext} from 'contexts/alert/context';

import UserSessionListRow from 'components/UserSessionsList/UserSessionListRow';
import TableResponsive from 'components/TableResponsive/TableResponsive';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

const UserSessionsList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getUserSessions = useCallback(data => dispatch({type: GET_USER_SESSIONS.REQUEST, payload: data}), [dispatch]);

    const {
        auth: {userSessions},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    useEffect(() => {
        getUserSessions({
            showAlert,
        });
    }, []);

    const columns = [
        {title: t('common.security.userAgent'), name: 'userAgent'},
        {title: t('common.security.createdAt'), name: 'createdAt'},
        {title: t('common.security.expiredAt'), name: 'expiredAt'},
        {title: t('common.security.action'), name: 'action', align: 'center'},
    ];

    const rows = userSessions?.map((item: UserSessionTypes) => {
        const {userAgent, createdAt, expiredAt} = item;
        return {
            userAgent,
            createdAt: convertDateToCurrentLocale(createdAt),
            expiredAt: convertDateToCurrentLocale(expiredAt),
            action: <UserSessionListRow item={item} />,
        };
    });

    return <TableResponsive columns={columns} rows={rows} />;
};

export default UserSessionsList;
