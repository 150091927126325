import React, {FC, useEffect} from 'react';
import {Navigate, useLocation, useParams, useSearchParams} from 'react-router-dom';

import {LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_TOKEN} from 'services/localStorage';

interface RedirectProps {
    to: string;
}

const Redirect: FC<RedirectProps> = ({to}) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const {hash} = useLocation();

    useEffect(() => {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    }, []);

    if (hash) {
        return <Navigate to={`${to}${hash}`} replace />;
    }
    return <Navigate to={`${to}/${params['*']}?${searchParams}`} replace />;
};

export default Redirect;
