import React, {FC, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import {NotificationType} from 'appRedux/actions/notifications/types';
import {REVIEW_NOTIFICATION} from 'appRedux/actions/notifications';

import {AlertContext} from 'contexts/alert/context';

import {getIconByNotificationType} from 'components/Notifications/helper';
import DeleteNotificationButton from 'components/Notifications/DeleteNotificationButton';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {CLIENT_BACKGROUND_COLOR, theme} from 'config/theme';

interface NotificationMenuItemType {
    item: NotificationType;
    closePanel: () => void;
}

const NotificationMenuItem: FC<NotificationMenuItemType> = ({item, closePanel}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {showAlert} = useContext(AlertContext);

    const {text, relatedLink, createdAt, type, uuid, isReviewed} = item;

    const reviewNotification = useCallback(
        data => dispatch({type: REVIEW_NOTIFICATION.REQUEST, payload: data}),
        [dispatch],
    );

    const onClickAction = () => {
        if (isReviewed && relatedLink) {
            navigate(relatedLink);
            closePanel();
        } else {
            reviewNotification({
                uuid,
                showAlert,
                callback: () => {
                    if (relatedLink) {
                        navigate(relatedLink);
                        closePanel();
                    }
                },
            });
        }
    };

    const Icon = getIconByNotificationType(type);

    return (
        <MenuItem
            sx={{
                p: 2,
                borderBottomColor: isReviewed ? theme.palette.secondary.contrastText : CLIENT_BACKGROUND_COLOR,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                backgroundColor: isReviewed ? CLIENT_BACKGROUND_COLOR : theme.palette.background.default,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '80%',
                    }}
                    onClick={onClickAction}
                >
                    <IconButton sx={{mt: 1, backgroundColor: theme.palette.info.main}}>
                        <Icon isActive />
                    </IconButton>
                    <Box sx={{width: 290, ml: 2}}>
                        <Typography sx={{whiteSpace: 'break-spaces', width: 270}} align={'justify'}>
                            {text}
                        </Typography>
                        <Typography sx={{fontSize: 12, fontWeight: 600, mt: 2}} align={'right'}>
                            {convertDateToCurrentLocale(createdAt)}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '20%',
                    }}
                >
                    <DeleteNotificationButton item={item} />
                </Box>
            </Box>
        </MenuItem>
    );
};

export default NotificationMenuItem;
