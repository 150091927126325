import React, {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {TabItemType, getTabBackgroundColor, getTabTextColor} from 'components/TabsWrapper/helper';

import {theme} from 'config/theme';

interface TabsWrapperType {
    tabItems: TabItemType[];
    currentTab: number;
    setCurrentTab: (value: number) => void;
    children: ReactNode;
}

const TabsWrapper: FC<TabsWrapperType> = ({tabItems, currentTab, setCurrentTab, children}) => {
    const [t] = useTranslation();

    const onTabClick = (tabItem: TabItemType) => {
        const {tabNumber, isDisabled} = tabItem;
        if (!isDisabled) {
            setCurrentTab(tabNumber);
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    pl: 2,
                    pr: 2,
                }}
            >
                {tabItems.map((tabItem: TabItemType, index: number) => {
                    const {title, tabNumber, isDisabled} = tabItem;
                    const isActive = currentTab === tabNumber;
                    return (
                        <Box
                            key={`tab-${tabItem.title}-${index}`}
                            sx={{
                                backgroundColor: getTabBackgroundColor(isActive, isDisabled),
                                borderTopLeftRadius: 6,
                                borderTopRightRadius: 6,
                                cursor: isActive || isDisabled ? 'initial' : 'pointer',
                                mr: 1,
                                pl: 2,
                                pr: 2,
                                pt: 1,
                                pb: 1,
                            }}
                            onClick={() => onTabClick(tabItem)}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: getTabTextColor(isActive, isDisabled),
                                }}
                            >
                                {t(title)}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
            <Box
                sx={{
                    border: `2px solid ${theme.palette.info.main}`,
                    borderRadius: 4,
                    p: 2,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default TabsWrapper;
