import React, {FC, useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';
import {FORM_UPDATE} from 'appRedux/actions/forms';
import {FormConfigurationRequestTypes} from 'appRedux/actions/forms/types';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import {AlertContext} from 'contexts/alert/context';

import ConfigurationForm from 'components/Forms/FormBuilder/ConfigurationForm/Form';
import TranslationSelector from 'components/Forms/FormBuilder/ConfigurationForm/TranslationSelector';
import FormConfigurationTranslationForm from 'components/Forms/FormBuilder/FormConfigurationTranslationForm/FormConfigurationTranslationForm';
import ConfigurationSubForm from 'components/Forms/FormBuilder/ConfigurationForm/FormSub';

interface UpdateConfigurationFormType {
    formId: number;
    initialValues: FormConfigurationRequestTypes;
    organizationLanguage: LanguageItemTypes;
}

const UpdateConfigurationForm: FC<UpdateConfigurationFormType> = ({formId, initialValues, organizationLanguage}) => {
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const [currentTab, setCurrentTab] = useState<string>(organizationLanguage.name);

    const {
        admin: {isLoading},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const submitUpdateForm = useCallback(data => dispatch({type: FORM_UPDATE.REQUEST, payload: data}), [dispatch]);

    const onSubmitClicked = (values: FormConfigurationRequestTypes) => {
        submitUpdateForm({
            ...values,
            id: formId,
            showAlert,
        });
    };

    const {isSubForm} = initialValues;

    const Form = isSubForm ? ConfigurationSubForm : ConfigurationForm;

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                    gap: 2,
                }}
            >
                <TranslationSelector setSelectedLanguage={setCurrentTab} organizationLanguage={organizationLanguage} />
            </Box>
            {currentTab === organizationLanguage.name ? (
                <Form initialValues={initialValues} onSubmitClicked={onSubmitClicked} isLoading={isLoading} />
            ) : (
                <FormConfigurationTranslationForm
                    formId={formId}
                    initialValues={initialValues}
                    currentTab={currentTab}
                />
            )}
        </Box>
    );
};

export default UpdateConfigurationForm;
