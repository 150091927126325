import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RequesterCaseHasBudgetTypes} from 'appRedux/actions/requestCase/types';

import {getBudgetStatusLabel, getBudgetStatusDateTime} from 'pages/client/form/fieldTypes/resourceFieldBudget/helper';

interface BudgetStatusLabelType {
    currentBudget: RequesterCaseHasBudgetTypes;
}

const BudgetStatusLabel: FC<BudgetStatusLabelType> = ({currentBudget}) => {
    const [t] = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: 1,
            }}
        >
            <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(getBudgetStatusLabel(currentBudget.status))}:`}</Typography>
            <Typography>{getBudgetStatusDateTime(currentBudget)}</Typography>
        </Box>
    );
};

export default BudgetStatusLabel;
