import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

import {ResourceFieldCalendarItemTypes} from 'appRedux/actions/resourceFields/types';
import {FormFieldRequestType} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import FormFieldErrorMessage from 'components/AdminScreenComponents/FormFieldErrorMessage';

import {theme} from 'config/theme';

interface ResourceFieldCalendarElementsType {
    formik: FormikProps<FormFieldRequestType>;
}

const ResourceFieldCalendarElements: FC<ResourceFieldCalendarElementsType> = ({formik}) => {
    const [t] = useTranslation();

    const {
        resourceFields: {resourceFieldCalendarItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const isTouched = get(formik, ['touched', 'resourceFieldCalendar'], false);
    const errorMessage = get(formik, ['errors', 'resourceFieldCalendar'], false);

    return (
        <Box>
            <Typography variant="body2" sx={{mb: 1}}>
                {t('orguser.forms.formField.resourceFieldCalendar')}
            </Typography>
            <FormControl
                sx={{
                    backgroundColor: theme.palette.warning.contrastText,
                    borderRadius: 3,
                    pl: 3,
                    pr: 3,
                    pb: 2,
                    mb: 1,
                }}
                required
                fullWidth
            >
                <Select
                    sx={{
                        height: 50,
                    }}
                    name={'resourceFieldCalendar'}
                    value={formik.values.resourceFieldCalendar}
                    variant="standard"
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={formik.handleChange}
                >
                    {resourceFieldCalendarItems.map((resourceFieldCalendar: ResourceFieldCalendarItemTypes) => {
                        return (
                            <MenuItem
                                key={`resourceField-calendar-${resourceFieldCalendar.uuid}`}
                                value={resourceFieldCalendar.id}
                            >
                                <Typography>{resourceFieldCalendar.title}</Typography>
                            </MenuItem>
                        );
                    })}
                    <MenuItem key={`resourceField-calendar-default`} value={0}>
                        <Typography>{t('orguser.forms.formField.resourceFieldCalendarRequesterSelected')}</Typography>
                    </MenuItem>
                </Select>
                {isTouched && errorMessage && <FormFieldErrorMessage errorMessage={String(errorMessage)} />}
            </FormControl>
        </Box>
    );
};

export default ResourceFieldCalendarElements;
