import React, {FC, useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';

import {UPDATE_CUSTOM_ATTRIBUTE} from 'appRedux/actions/customAttributes';
import {CustomAttributesFormTypes, CustomAttributesItemTypes} from 'appRedux/actions/customAttributes/types';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {RouteContext} from 'contexts/route/context';

import CustomAttributeForm from 'components/Forms/CustomAttributes/CustomAttributeForm';
import {CUSTOM_ATTRIBUTE_TYPE_DROPDOWN} from 'components/Forms/CustomAttributes/helper';

interface UpdateCustomAttributeFormType {
    item: CustomAttributesItemTypes;
    closeModal: () => void;
}

const UpdateCustomAttributeForm: FC<UpdateCustomAttributeFormType> = ({item, closeModal}) => {
    const dispatch = useDispatch();

    const {title, type, isManaged, isOnlyOrganizationUser, uuid} = item;

    const {myOrganization} = useContext(AdminContext);
    const {showAlert} = useContext(AlertContext);
    const {isSuperAdminPage} = useContext(RouteContext);

    const updateCustomAttribute = useCallback(
        data => dispatch({type: UPDATE_CUSTOM_ATTRIBUTE.REQUEST, payload: data}),
        [dispatch],
    );

    const onSubmitClicked = (values: CustomAttributesFormTypes) => {
        updateCustomAttribute({
            ...values,
            organization: isSuperAdminPage || !myOrganization ? null : myOrganization.id,
            uuid,
            showAlert,
            callback: closeModal,
        });
    };

    return (
        <CustomAttributeForm
            initialValues={{
                title,
                type,
                isManaged,
                isOnlyOrganizationUser,
            }}
            onSubmitClicked={onSubmitClicked}
            item={item}
            showOptionsList={type === CUSTOM_ATTRIBUTE_TYPE_DROPDOWN}
        />
    );
};

export default UpdateCustomAttributeForm;
