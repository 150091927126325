import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {RootReducer} from 'appRedux/reducers';

import {PermissionContext} from 'contexts/permission/context';

import {CASE_STATUS_CHANGE_DEFAULT} from 'components/Forms/RequesterCase/helper';

import {getAgentFromList} from 'pages/agent/requesterPage/partials/helper';
import FormInformationRowWrapper from 'pages/agent/requesterPage/wrappers/FormInformationRowWrapper';
import ChangeAgentForm from 'pages/agent/requesterPage/formInformation/ChangeAgentForm';
import ActivateChatByAgent from 'pages/agent/requesterPage/formInformation/ActivateChatByAgent';
import Watchers from 'pages/agent/requesterPage/watchers/Watchers';
import RequesterCaseStatus from 'pages/agent/requesterPage/formInformation/status/RequesterCaseStatus';
import Approver from 'pages/agent/requesterPage/formInformation/approver/Approver';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {getFormKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {routes, DATE_TIME_LABEL_FORMAT} from 'config/index';

interface FormInformationType {
    closeChat: () => void;
}

const FormInformation: FC<FormInformationType> = ({closeChat}) => {
    const [t] = useTranslation();
    const {formId} = useParams();
    const navigate = useNavigate();

    const {isCaseEditEnabled, isFormViewEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    const {
        admin: {
            formInfo: {translations},
        },
        requestCase: {currentCase},
        crypto: {agentsWithCaseAccess},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {
        formTitle,
        agentEmail,
        createdAt,
        updatedAt,
        isChatActivatedByAgent,
        deletedAt,
        approves,
        versionUuid,
        versionTitle,
    } = currentCase;

    const initialAgent =
        agentsWithCaseAccess && agentEmail ? getAgentFromList(agentsWithCaseAccess, agentEmail) : undefined;

    const formTitleKeyword = getFormKeyword(Number(formId), 'title');

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    const approvedRequests = approves.filter(item => item.status !== CASE_STATUS_CHANGE_DEFAULT);

    const redirectToForm = () => {
        if (isFormViewEnabled) {
            navigate(`${routes.FORM}/${versionUuid}/version`);
        }
    };

    return (
        <Box sx={{p: 3}}>
            <FormInformationRowWrapper
                title={t('orguser.requesterCase.agent')}
                isSelector={!deletedAt && isEditEnabled}
                alignTop={Boolean(initialAgent)}
            >
                {deletedAt || !isEditEnabled ? (
                    <Typography sx={{fontWeight: 600}}>{initialAgent ?? t('common.none')}</Typography>
                ) : (
                    <ChangeAgentForm initialAgent={initialAgent} closeChat={closeChat} />
                )}
            </FormInformationRowWrapper>
            <Watchers />
            {!deletedAt && (
                <FormInformationRowWrapper
                    title={t('orguser.requesterCase.status')}
                    isSelector={isEditEnabled}
                    alignTop
                >
                    <RequesterCaseStatus isEditEnabled={isEditEnabled} />
                </FormInformationRowWrapper>
            )}
            <FormInformationRowWrapper title={t('orguser.requesterCase.form')}>
                <Typography
                    sx={{fontWeight: 600, cursor: isFormViewEnabled ? 'pointer' : 'initial'}}
                    onClick={redirectToForm}
                >
                    {`${getFormTranslatedLabel(translations, formTitleKeyword, formTitle)} (${versionTitle})`}
                </Typography>
            </FormInformationRowWrapper>
            {!deletedAt && initialAgent && isEditEnabled && (
                <FormInformationRowWrapper title={'orguser.requesterCase.activateChat'}>
                    <ActivateChatByAgent isChatActivated={isChatActivatedByAgent} />
                </FormInformationRowWrapper>
            )}
            <FormInformationRowWrapper title={t('orguser.requesterCase.created')}>
                <Typography sx={{fontWeight: 600}}>
                    {convertDateToCurrentLocale(createdAt, DATE_TIME_LABEL_FORMAT)}
                </Typography>
            </FormInformationRowWrapper>
            <FormInformationRowWrapper title={t('orguser.requesterCase.lastUpdate')}>
                <Typography sx={{fontWeight: 600}}>
                    {convertDateToCurrentLocale(updatedAt, DATE_TIME_LABEL_FORMAT)}
                </Typography>
            </FormInformationRowWrapper>
            {approvedRequests.length > 0 && <Approver items={approvedRequests} />}
        </Box>
    );
};

export default FormInformation;
