import get from 'lodash/get';

import {InviteTypes} from 'appRedux/actions/invites/types';
import {OrganizationAdminListTypes} from 'appRedux/actions/organizationAdmin/types';
import {RoleTypes} from 'appRedux/actions/roles/types';

import {REQUESTER_MODE_ALLOWED, REQUESTER_MODE_FORCED} from 'components/Forms/RoleForm/helper';

import {SORTING_ASC} from 'config/index';
import {TAG_COLOR_1, TAG_COLOR_2, TAG_COLOR_4, TAG_COLOR_8} from 'config/theme';

export const INVITE_STATUS_CREATED = 0;
export const INVITE_STATUS_SENT = 1;
export const INVITE_STATUS_COMPLETED = 2;

export const getInviteStatusLabel = (status: number): string => {
    switch (status) {
        case INVITE_STATUS_CREATED:
            return 'orguser.invites.statusTypes.inviteStatusCreated';
        case INVITE_STATUS_SENT:
            return 'orguser.invites.statusTypes.inviteStatusSent';
        case INVITE_STATUS_COMPLETED:
            return 'orguser.invites.statusTypes.inviteStatusCompleted';
        default:
            return '';
    }
};

export const filterSearchValue = (searchTerm: RegExp) => (row: any) => {
    const match = Object.keys(row).some((field: string) => {
        if (field === 'uuid') return false;
        if (field === 'createdAt') return false;
        if (field === 'formId') return false;

        return row[field] ? (searchTerm instanceof RegExp ? searchTerm.test(row[field].toString()) : false) : false;
    });

    return match;
};

export const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const sortInvites =
    (sortByParameter: keyof InviteTypes, sortTypeParameter: string) => (a: InviteTypes, b: InviteTypes) => {
        const strA = (
            typeof a[sortByParameter] === 'string' ? a[sortByParameter] : String(a[sortByParameter])
        ) as string;
        const strB = (
            typeof b[sortByParameter] === 'string' ? b[sortByParameter] : String(b[sortByParameter])
        ) as string;

        return sortTypeParameter === SORTING_ASC ? strA.localeCompare(strB) : strB.localeCompare(strA);
    };

export const getAdminsWithRequesterModePossiblePresented = (
    organizationAdminList: OrganizationAdminListTypes[],
): OrganizationAdminListTypes[] => {
    return organizationAdminList.filter(item => {
        const roles: RoleTypes[] = get(item, ['admin', 'roles'], []);
        return roles.some(role => [REQUESTER_MODE_ALLOWED, REQUESTER_MODE_FORCED].includes(role.requesterMode));
    });
};

export const getInviteStatusColor = (status: number): string => {
    switch (status) {
        case INVITE_STATUS_CREATED:
            return TAG_COLOR_1;
        case INVITE_STATUS_SENT:
            return TAG_COLOR_2;
        case INVITE_STATUS_COMPLETED:
            return TAG_COLOR_4;
        default:
            return TAG_COLOR_8;
    }
};
