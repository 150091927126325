import * as yup from 'yup';

import {CustomAttributeOptionTypes} from 'appRedux/actions/customAttributes/types';

export const validationSchema = () =>
    yup.object({
        title: yup.string().required('messages.validation.isRequired'),
        description: yup.string().nullable(),
    });

export const validationTranslationSchema = () =>
    yup.object({
        translation: yup.string().required('messages.validation.isRequired'),
    });

export const validationAttachmentSchema = () =>
    yup.object({
        uuid: yup.string().required('messages.validation.isRequired'),
    });
