import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {GridColDef} from '@mui/x-data-grid';
import {Box, IconButton} from '@mui/material';
import {WithStyles, withStyles, createStyles} from '@material-ui/core';
import Typography from '@mui/material/Typography';

import {CaseAccessRequestTypes} from 'appRedux/actions/crypto/types';

import EditSvgIcon from 'assets/icons/buttons/EditSvgIcon';

import {PermissionContext} from 'contexts/permission/context';

import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import {getAvatarTitle} from 'components/TopBarMenu/helper';
import AvatarImage from 'components/UserAvatar/AvatarImage';

import FullNameWithTooltip from 'pages/agent/tableView/FullNameWithTooltip';
import {getRequesterCaseName} from 'pages/agent/listView/helper';
import CaseAccessRequestDeleteButton from 'pages/admin/accessRequests/CaseAccessRequestDeleteButton';
import {CASE_ACCESS_REQUEST_CREATED, getAccessRequestStatusLabel} from 'pages/admin/accessRequests/helper';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {routes} from 'config/index';

interface GeneralTemplatesDataGridType extends WithStyles<typeof styles> {
    items: CaseAccessRequestTypes[];
}

const styles = () =>
    createStyles({
        notRead: {
            fontWeight: 'bold',
            '& p': {
                fontWeight: 'bold',
            },
        },
    });

const CaseAccessRequestsDataGrid: FC<GeneralTemplatesDataGridType> = ({items, classes}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {isCaseViewEnabled} = useContext(PermissionContext);

    const redirectToCase = (caseId: number, formId: number) => {
        if (caseId && isCaseViewEnabled) {
            navigate(`${routes.REQUESTER_CASE}/${caseId}/form/${formId}`);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'caseLabel',
            headerName: t('orguser.accessRequests.requesterCase'),
            minWidth: 200,
            renderCell: ({row}) => {
                const {caseLabel, caseId, formId} = row;
                return (
                    <Typography
                        sx={{fontSize: 14, cursor: caseId && isCaseViewEnabled ? 'pointer' : 'initial'}}
                        onClick={() => redirectToCase(caseId, formId)}
                    >
                        {caseLabel}
                    </Typography>
                );
            },
        },
        {
            field: 'firstName',
            headerName: t('orguser.accessRequests.name'),
            minWidth: 200,
            renderCell: ({row}) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <AvatarImage
                        size={32}
                        avatarLink={row.avatarLink}
                        avatarType={row.avatarType}
                        avatarTitle={getAvatarTitle(row.firstName, row.lastName, row.email)}
                    />
                    <FullNameWithTooltip name={getRequesterCaseName(t, row)} />
                </Box>
            ),
        },
        {
            field: 'isRequestFromClient',
            headerName: t('orguser.accessRequests.role'),
            minWidth: 150,
            flex: 0.7,
            valueGetter: ({value}) =>
                t(value ? 'orguser.accessRequests.roles.roleRequester' : 'orguser.accessRequests.roles.roleOrgUser'),
        },
        {
            field: 'status',
            headerName: t('orguser.accessRequests.status'),
            width: 80,
            valueGetter: ({value}) => t(getAccessRequestStatusLabel(value)),
        },
        {
            field: 'createdAt',
            headerName: t('orguser.accessRequests.createdAt'),
            width: 170,
            valueGetter: ({value}) => convertDateToCurrentLocale(value),
        },
        {
            field: 'updatedAt',
            headerName: t('orguser.accessRequests.updatedAt'),
            width: 170,
            valueGetter: ({value}) => (value ? convertDateToCurrentLocale(value) : t('common.none')),
        },
        {
            field: 'uuid',
            headerName: t('orguser.accessRequests.action'),
            width: 100,
            renderCell: ({row}) => {
                const navigate = useNavigate();

                const redirectToRequest = () => {
                    navigate(`${routes.CASE_ACCESS_REQUEST}/${row.uuid}/edit`);
                };
                return (
                    <>
                        <IconButton title={t('common.buttons.update')} onClick={redirectToRequest}>
                            <EditSvgIcon />
                        </IconButton>
                        <CaseAccessRequestDeleteButton item={row} />
                    </>
                );
            },
        },
    ];

    return (
        <CustomDataGrid
            rows={items}
            columns={columns}
            columnVisibilityModel={{invite: false}}
            getRowId={row => row.uuid}
            checkboxSelection={false}
            getRowClassName={params => `${params.row.status === CASE_ACCESS_REQUEST_CREATED ? classes?.notRead : ''}`}
            isLarge
            enablePaging
        />
    );
};

export default withStyles(styles)(CaseAccessRequestsDataGrid);
