import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {GridColDef} from '@mui/x-data-grid';

import {OrganizationPrivacyPolicyItemTypes} from 'appRedux/actions/organization/types';

import DataGridWrapper from 'components/AdminScreenComponents/DataGridWrapper';
import {CustomDataGrid} from 'components/AdminScreenComponents/CustomDataGrid';
import MarkdownWithShowAll from 'components/TextWithShowAll/MarkdownWithShowAll';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {DATE_TIME_FORMAT} from 'config/index';

interface VersionsListType {
    item: OrganizationPrivacyPolicyItemTypes;
}

const VersionsList: FC<VersionsListType> = ({item}) => {
    const [t] = useTranslation();

    const {versions} = item;

    const columns: GridColDef[] = [
        {
            field: 'test',
            headerName: t('superadmin.organizations.privacyPolicy.text'),
            renderCell: ({row}) => <MarkdownWithShowAll text={row.text} />,
        },
        {
            field: 'createdAt',
            headerName: t('superadmin.organizations.privacyPolicy.createdAt'),
            maxWidth: 200,
            valueGetter: ({value}) => convertDateToCurrentLocale(value, DATE_TIME_FORMAT),
        },
    ];

    return (
        <DataGridWrapper itemsList={versions} itemsTotalNumber={versions ? versions.length : 0}>
            {versions && (
                <CustomDataGrid
                    rows={versions}
                    columns={columns}
                    getRowId={row => row.uuid}
                    checkboxSelection={false}
                    enablePaging
                    isLarge
                />
            )}
        </DataGridWrapper>
    );
};

export default VersionsList;
