import {
    GET_RESOURCE_FIELDS_LIST,
    GET_RESOURCE_FIELDS_WORKFLOW,
    CREATE_RESOURCE_FIELD,
    UPDATE_RESOURCE_FIELD,
    DELETE_RESOURCE_FIELD,
    GET_RESOURCE_FIELD_BUDGET_ORGANIZATION,
    GET_RESOURCE_FIELD_BUDGET_LIST,
    GET_RESOURCE_FIELD_BUDGET_INFO,
    GET_RESOURCE_FIELD_BUDGET_CASES,
    CREATE_RESOURCE_FIELD_BUDGET,
    UPDATE_RESOURCE_FIELD_BUDGET,
    DELETE_RESOURCE_FIELD_BUDGET,
    GET_RESOURCE_FIELD_SLOT_ORGANIZATION,
    GET_RESOURCE_FIELD_SLOT_FORM,
    GET_RESOURCE_FIELD_SLOT_LIST,
    CREATE_RESOURCE_FIELD_SLOT,
    UPDATE_RESOURCE_FIELD_SLOT,
    DELETE_RESOURCE_FIELD_SLOT,
    GET_RESOURCE_FIELD_SLOT_INFO,
    GET_RESOURCE_FIELD_SLOT_CASES,
    GET_RESOURCE_FIELD_CALENDAR_ORGANIZATION,
    GET_RESOURCE_FIELD_CALENDAR_FORM,
    GET_RESOURCE_FIELD_CALENDAR_LIST,
    GET_RESOURCE_FIELD_CALENDAR_INFO,
    CREATE_RESOURCE_FIELD_CALENDAR,
    UPDATE_RESOURCE_FIELD_CALENDAR,
    DELETE_RESOURCE_FIELD_CALENDAR,
    CREATE_RESOURCE_FIELD_BOOKFRAME,
    UPDATE_RESOURCE_FIELD_BOOKFRAME,
    DELETE_RESOURCE_FIELD_BOOKFRAME,
    CREATE_RESOURCE_FIELD_EXCEPTION,
    UPDATE_RESOURCE_FIELD_EXCEPTION,
    DELETE_RESOURCE_FIELD_EXCEPTION,
    CREATE_RESOURCE_FIELD_CALENDAR_SLOT,
    UPDATE_RESOURCE_FIELD_CALENDAR_SLOT,
    DELETE_RESOURCE_FIELD_CALENDAR_SLOT,
} from 'appRedux/actions/resourceFields';

import {
    ResourceFieldItemTypes,
    ResourceFieldBudgetItemTypes,
    ResourceFieldSlotItemTypes,
    ResourceFieldSlotClientItemTypes,
    ResourceFieldCalendarItemTypes,
    ResourceFieldCalendarClientItemTypes,
    RequesterCaseRelatedWithSlotItems,
    RequesterCaseRelatedWithSlotTypes,
    RequesterCaseRelatedWithBudgetTypes,
} from 'appRedux/actions/resourceFields/types';

export interface ResourceFieldsState {
    isLoading: boolean;
    allCalendarItems: ResourceFieldCalendarItemTypes[];
    allCalendarFormItems: ResourceFieldCalendarClientItemTypes[];
    allBudgetItems: ResourceFieldBudgetItemTypes[];
    allSlotItems: ResourceFieldSlotItemTypes[];
    allSlotFormItems: ResourceFieldSlotClientItemTypes[];
    resourceFields: ResourceFieldItemTypes[];
    resourceFieldBudgetItems: ResourceFieldBudgetItemTypes[];
    resourceFieldSlotItems: ResourceFieldSlotItemTypes[];
    resourceFieldCalendarItems: ResourceFieldCalendarItemTypes[];
    currentCalendarItem: ResourceFieldCalendarItemTypes;
    currentBudgetItem: ResourceFieldBudgetItemTypes | null;
    currentBudgetItemRelatedCases: RequesterCaseRelatedWithBudgetTypes | null;
    currentSlotItem: ResourceFieldSlotItemTypes | null;
    currentSlotItemRelatedCases: RequesterCaseRelatedWithSlotTypes | null;
    error: IErrors | undefined;
}

export const initialState: ResourceFieldsState = {
    isLoading: false,
    allCalendarItems: [],
    allCalendarFormItems: [],
    allBudgetItems: [],
    allSlotItems: [],
    allSlotFormItems: [],
    resourceFields: [],
    resourceFieldBudgetItems: [],
    resourceFieldSlotItems: [],
    resourceFieldCalendarItems: [],
    currentCalendarItem: {
        id: 0,
        title: '',
        description: '',
        bookframes: [],
        slots: [],
        exceptions: [],
        uuid: '',
    },
    currentBudgetItem: null,
    currentBudgetItemRelatedCases: null,
    currentSlotItem: null,
    currentSlotItemRelatedCases: null,
    error: undefined,
};

const resourceFields = (
    state = initialState,
    action: IActionType<
        | ResourceFieldItemTypes[]
        | ResourceFieldBudgetItemTypes[]
        | ResourceFieldBudgetItemTypes
        | ResourceFieldSlotItemTypes[]
        | ResourceFieldSlotClientItemTypes[]
        | ResourceFieldSlotItemTypes
        | ResourceFieldCalendarItemTypes[]
        | ResourceFieldCalendarItemTypes
        | ResourceFieldCalendarClientItemTypes
        | RequesterCaseRelatedWithSlotTypes
        | RequesterCaseRelatedWithBudgetTypes
        | IErrors
    >,
): ResourceFieldsState => {
    switch (action.type) {
        case GET_RESOURCE_FIELDS_LIST.REQUEST:
        case GET_RESOURCE_FIELDS_WORKFLOW.REQUEST:
        case CREATE_RESOURCE_FIELD.REQUEST:
        case UPDATE_RESOURCE_FIELD.REQUEST:
        case DELETE_RESOURCE_FIELD.REQUEST:
        case GET_RESOURCE_FIELD_BUDGET_ORGANIZATION.REQUEST:
        case GET_RESOURCE_FIELD_BUDGET_LIST.REQUEST:
        case GET_RESOURCE_FIELD_BUDGET_INFO.REQUEST:
        case GET_RESOURCE_FIELD_BUDGET_CASES.REQUEST:
        case CREATE_RESOURCE_FIELD_BUDGET.REQUEST:
        case UPDATE_RESOURCE_FIELD_BUDGET.REQUEST:
        case DELETE_RESOURCE_FIELD_BUDGET.REQUEST:
        case GET_RESOURCE_FIELD_SLOT_ORGANIZATION.REQUEST:
        case GET_RESOURCE_FIELD_SLOT_FORM.REQUEST:
        case GET_RESOURCE_FIELD_SLOT_LIST.REQUEST:
        case GET_RESOURCE_FIELD_SLOT_INFO.REQUEST:
        case GET_RESOURCE_FIELD_SLOT_CASES.REQUEST:
        case CREATE_RESOURCE_FIELD_SLOT.REQUEST:
        case UPDATE_RESOURCE_FIELD_SLOT.REQUEST:
        case DELETE_RESOURCE_FIELD_SLOT.REQUEST:
        case GET_RESOURCE_FIELD_CALENDAR_ORGANIZATION.REQUEST:
        case GET_RESOURCE_FIELD_CALENDAR_FORM.REQUEST:
        case GET_RESOURCE_FIELD_CALENDAR_LIST.REQUEST:
        case GET_RESOURCE_FIELD_CALENDAR_INFO.REQUEST:
        case CREATE_RESOURCE_FIELD_CALENDAR.REQUEST:
        case UPDATE_RESOURCE_FIELD_CALENDAR.REQUEST:
        case DELETE_RESOURCE_FIELD_CALENDAR.REQUEST:
        case CREATE_RESOURCE_FIELD_BOOKFRAME.REQUEST:
        case UPDATE_RESOURCE_FIELD_BOOKFRAME.REQUEST:
        case DELETE_RESOURCE_FIELD_BOOKFRAME.REQUEST:
        case CREATE_RESOURCE_FIELD_EXCEPTION.REQUEST:
        case UPDATE_RESOURCE_FIELD_EXCEPTION.REQUEST:
        case DELETE_RESOURCE_FIELD_EXCEPTION.REQUEST:
        case CREATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST:
        case UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST:
        case DELETE_RESOURCE_FIELD_CALENDAR_SLOT.REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        }

        case GET_RESOURCE_FIELDS_LIST.SUCCESS:
        case GET_RESOURCE_FIELDS_WORKFLOW.SUCCESS:
        case CREATE_RESOURCE_FIELD.SUCCESS:
        case UPDATE_RESOURCE_FIELD.SUCCESS:
        case DELETE_RESOURCE_FIELD.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFields: action.payload as ResourceFieldItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_BUDGET_LIST.SUCCESS:
        case CREATE_RESOURCE_FIELD_BUDGET.SUCCESS:
        case UPDATE_RESOURCE_FIELD_BUDGET.SUCCESS:
        case DELETE_RESOURCE_FIELD_BUDGET.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFieldBudgetItems: action.payload as ResourceFieldBudgetItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_CALENDAR_ORGANIZATION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                allCalendarItems: action.payload as ResourceFieldCalendarItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_CALENDAR_FORM.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                allCalendarFormItems: action.payload as ResourceFieldCalendarClientItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_BUDGET_ORGANIZATION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                allBudgetItems: action.payload as ResourceFieldBudgetItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_SLOT_ORGANIZATION.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                allSlotItems: action.payload as ResourceFieldSlotItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_SLOT_FORM.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                allSlotFormItems: action.payload as ResourceFieldSlotClientItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_SLOT_LIST.SUCCESS:
        case CREATE_RESOURCE_FIELD_SLOT.SUCCESS:
        case UPDATE_RESOURCE_FIELD_SLOT.SUCCESS:
        case DELETE_RESOURCE_FIELD_SLOT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFieldSlotItems: action.payload as ResourceFieldSlotItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_BUDGET_INFO.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentBudgetItem: action.payload as ResourceFieldBudgetItemTypes,
            };
        }

        case GET_RESOURCE_FIELD_BUDGET_CASES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentBudgetItemRelatedCases: action.payload as RequesterCaseRelatedWithBudgetTypes,
            };
        }

        case GET_RESOURCE_FIELD_SLOT_INFO.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentSlotItem: action.payload as ResourceFieldSlotItemTypes,
            };
        }

        case GET_RESOURCE_FIELD_SLOT_CASES.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentSlotItemRelatedCases: action.payload as RequesterCaseRelatedWithSlotTypes,
            };
        }

        case GET_RESOURCE_FIELD_CALENDAR_LIST.SUCCESS:
        case CREATE_RESOURCE_FIELD_CALENDAR.SUCCESS:
        case DELETE_RESOURCE_FIELD_CALENDAR.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resourceFieldCalendarItems: action.payload as ResourceFieldCalendarItemTypes[],
            };
        }

        case GET_RESOURCE_FIELD_CALENDAR_INFO.SUCCESS:
        case UPDATE_RESOURCE_FIELD_CALENDAR.SUCCESS:
        case CREATE_RESOURCE_FIELD_BOOKFRAME.SUCCESS:
        case UPDATE_RESOURCE_FIELD_BOOKFRAME.SUCCESS:
        case DELETE_RESOURCE_FIELD_BOOKFRAME.SUCCESS:
        case CREATE_RESOURCE_FIELD_EXCEPTION.SUCCESS:
        case UPDATE_RESOURCE_FIELD_EXCEPTION.SUCCESS:
        case DELETE_RESOURCE_FIELD_EXCEPTION.SUCCESS:
        case CREATE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS:
        case UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS:
        case DELETE_RESOURCE_FIELD_CALENDAR_SLOT.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                currentCalendarItem: action.payload as ResourceFieldCalendarItemTypes,
            };
        }

        case GET_RESOURCE_FIELDS_LIST.ERROR:
        case GET_RESOURCE_FIELDS_WORKFLOW.ERROR:
        case CREATE_RESOURCE_FIELD.ERROR:
        case UPDATE_RESOURCE_FIELD.ERROR:
        case DELETE_RESOURCE_FIELD.ERROR:
        case GET_RESOURCE_FIELD_BUDGET_ORGANIZATION.ERROR:
        case GET_RESOURCE_FIELD_BUDGET_LIST.ERROR:
        case GET_RESOURCE_FIELD_BUDGET_INFO.ERROR:
        case GET_RESOURCE_FIELD_BUDGET_CASES.ERROR:
        case CREATE_RESOURCE_FIELD_BUDGET.ERROR:
        case UPDATE_RESOURCE_FIELD_BUDGET.ERROR:
        case DELETE_RESOURCE_FIELD_BUDGET.ERROR:
        case GET_RESOURCE_FIELD_SLOT_ORGANIZATION.ERROR:
        case GET_RESOURCE_FIELD_SLOT_FORM.ERROR:
        case GET_RESOURCE_FIELD_SLOT_LIST.ERROR:
        case GET_RESOURCE_FIELD_SLOT_INFO.ERROR:
        case GET_RESOURCE_FIELD_SLOT_CASES.ERROR:
        case CREATE_RESOURCE_FIELD_SLOT.ERROR:
        case UPDATE_RESOURCE_FIELD_SLOT.ERROR:
        case DELETE_RESOURCE_FIELD_SLOT.ERROR:
        case GET_RESOURCE_FIELD_CALENDAR_ORGANIZATION.ERROR:
        case GET_RESOURCE_FIELD_CALENDAR_FORM.ERROR:
        case GET_RESOURCE_FIELD_CALENDAR_LIST.ERROR:
        case GET_RESOURCE_FIELD_CALENDAR_INFO.ERROR:
        case CREATE_RESOURCE_FIELD_CALENDAR.ERROR:
        case UPDATE_RESOURCE_FIELD_CALENDAR.ERROR:
        case DELETE_RESOURCE_FIELD_CALENDAR.ERROR:
        case CREATE_RESOURCE_FIELD_BOOKFRAME.ERROR:
        case UPDATE_RESOURCE_FIELD_BOOKFRAME.ERROR:
        case DELETE_RESOURCE_FIELD_BOOKFRAME.ERROR:
        case CREATE_RESOURCE_FIELD_EXCEPTION.ERROR:
        case UPDATE_RESOURCE_FIELD_EXCEPTION.ERROR:
        case DELETE_RESOURCE_FIELD_EXCEPTION.ERROR:
        case CREATE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR:
        case UPDATE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR:
        case DELETE_RESOURCE_FIELD_CALENDAR_SLOT.ERROR: {
            return {
                ...state,
                isLoading: false,
                error: (action.payload as IErrors) || {},
            };
        }

        default:
            return state;
    }
};

export default resourceFields;
