import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {ActivityElementType} from 'appRedux/actions/requestCase/types';
import {FormPageTypes} from 'appRedux/actions/forms/types';
import {RootReducer} from 'appRedux/reducers';

import {FieldsDifferenceType, getPopupDifferences} from 'pages/agent/activityPage/helper';
import ActivityInformationResultsItem from 'pages/agent/activityPage/ActivityInformationResultsItem';

interface ActivityInformationPopupResultsType {
    popupId: number;
    pages: FormPageTypes[];
    isEncryptInfo?: boolean;
    element: ActivityElementType;
    encodedCaseKey?: string;
}

const ActivityInformationPopupResultsList: FC<ActivityInformationPopupResultsType> = ({
    popupId,
    element,
    pages,
    isEncryptInfo,
    encodedCaseKey,
}) => {
    const {
        admin: {
            formInfo: {id, translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const results = getPopupDifferences(Number(id), popupId, pages, translations, element);

    return (
        <Box sx={{mb: 1}}>
            {results &&
                results.map((item: FieldsDifferenceType, index: number) => {
                    return (
                        <ActivityInformationResultsItem
                            key={`result-${index}`}
                            item={item}
                            isEncryptInfo={isEncryptInfo}
                            encodedCaseKey={encodedCaseKey}
                        />
                    );
                })}
        </Box>
    );
};

export default ActivityInformationPopupResultsList;
