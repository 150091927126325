import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

import {PublicKeyTypes} from 'appRedux/actions/crypto/types';
import {RootReducer} from 'appRedux/reducers';
import {GET_USER_PUBLIC_KEYS} from 'appRedux/actions/crypto';

import {AlertContext} from 'contexts/alert/context';

import PublicKeysRowActions from 'components/PublicKeysList/PublicKeysRowActions';
import TableResponsive from 'components/TableResponsive/TableResponsive';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

const PublicKeysList: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const getUserPublicKeys = useCallback(
        data => dispatch({type: GET_USER_PUBLIC_KEYS.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        profile: {profile},
        crypto: {currentUserPublicKeys},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const userId = get(profile, 'id', null);

    useEffect(() => {
        if (userId) {
            getUserPublicKeys({
                showAlert,
            });
        }
    }, [userId]);

    const columns = [
        {title: t('common.security.title'), name: 'title'},
        {title: t('common.security.organization'), name: 'organization'},
        {title: t('common.security.createdAt'), name: 'createdAt'},
        {title: t('common.security.action'), name: 'action', align: 'right'},
    ];

    const rows = currentUserPublicKeys?.map((item: PublicKeyTypes) => {
        const {title, createdAt, organization} = item;

        return {
            title: title || t('common.none'),
            organization: organization || t('common.none'),
            createdAt: convertDateToCurrentLocale(createdAt),
            action: <PublicKeysRowActions item={item} />,
        };
    });

    return <TableResponsive columns={columns} rows={rows} />;
};

export default PublicKeysList;
