import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {FormFieldTypes} from 'appRedux/actions/forms/types';
import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {ResourceFieldCalendarItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import CalendarItem from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarItem';

interface CalendarStepOneType {
    field: FormFieldTypes;
    currentCalendar?: RequesterCaseHasCalendarTypes;
    setCurrentTab: (value: number) => void;
}

const CalendarStepOne: FC<CalendarStepOneType> = ({field, currentCalendar, setCurrentTab}) => {
    const {resourceField, resourceFieldCalendar} = field;

    const {
        resourceFields: {allCalendarFormItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    if (field.resourceFieldCalendar) {
        const calendar = allCalendarFormItems.find(
            item => item.resourceFieldId === resourceField && item.id === resourceFieldCalendar,
        );
        return calendar ? (
            <CalendarItem
                field={field}
                item={calendar}
                currentCalendar={currentCalendar}
                setCurrentTab={setCurrentTab}
            />
        ) : null;
    }

    const calendars: ResourceFieldCalendarItemTypes[] = allCalendarFormItems.filter(
        item => item.resourceFieldId === field.resourceField,
    );

    return (
        <Box>
            {calendars.map(calendar => {
                return (
                    <CalendarItem
                        key={`calendar-${field.id}-${calendar.uuid}`}
                        field={field}
                        item={calendar}
                        currentCalendar={currentCalendar}
                        setCurrentTab={setCurrentTab}
                        isRequesterSelected
                    />
                );
            })}
        </Box>
    );
};

export default CalendarStepOne;
