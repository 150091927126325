import React, {FC} from 'react';

import {getIconColor, CommonSvgIconType} from 'assets/icons/buttons/helper';

const SuccessSvgIcon: FC<CommonSvgIconType> = ({isActive, isDisabled}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12Z"
                stroke={getIconColor(isActive, isDisabled)}
                strokeWidth="1.5"
            />
            <path
                d="M17.4095 9.03745C17.7095 8.73745 17.7095 8.28745 17.4095 7.98745C17.1095 7.68745 16.6595 7.68745 16.3595 7.98745L9.90947 14.4375L7.58447 12.1125C7.28447 11.8125 6.75947 11.8125 6.53447 12.1125C6.30947 12.4125 6.30947 12.8625 6.53447 13.1625L9.38447 16.0125C9.68447 16.3125 10.1345 16.3125 10.4345 16.0125L17.4095 9.03745Z"
                fill={getIconColor(isActive, isDisabled)}
            />
        </svg>
    );
};

export default SuccessSvgIcon;
