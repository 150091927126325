import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseCommentsTypes} from 'appRedux/actions/comments/types';

import {PermissionContext} from 'contexts/permission/context';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import EmptyArea from 'components/EmptyArea';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

import RemoveComment from 'pages/agent/requesterPage/common/RemoveComment';
import UpdateComment from 'pages/agent/requesterPage/common/UpdateComment';
import {getCommentTypeLabel} from 'pages/agent/requesterPage/common/helper';
import TextWithLineBreaks from 'pages/agent/requesterPage/common/TextWithLineBreaks';

import {checkIsCaseEditEnabled} from 'helpers/permissions';
import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

import {theme} from 'config/theme';
import {DATE_TIME_LABEL_FORMAT} from 'config/index';

interface CommentRowType {
    item: RequesterCaseCommentsTypes;
    inRightPanel?: boolean;
}

const CommentRow: FC<CommentRowType> = ({item, inRightPanel}) => {
    const [t] = useTranslation();
    const {formId} = useParams();

    const {avatarLink, section, avatarType, uuid, text, dateAndTime, author, isUpdated, isPublic, isCurrentUser} = item;

    const {isCaseEditEnabled, filtersCaseEditAllowed} = useContext(PermissionContext);

    let authorFirstName: string | null = null;
    let authorLastName: string | null = null;
    const authorNameArray = author ? author.split(', ') : [];
    if (authorNameArray.length > 1) {
        authorFirstName = authorNameArray[1];
        authorLastName = authorNameArray[0];
    }

    const isEditEnabled = checkIsCaseEditEnabled(isCaseEditEnabled, filtersCaseEditAllowed, Number(formId));

    const isLineBreakPresented = text.includes('\n');

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            {!inRightPanel && (
                <AvatarImage
                    size={22}
                    avatarLink={avatarLink}
                    avatarType={avatarType}
                    avatarTitle={getAvatarTitle(authorFirstName, authorLastName, author)}
                />
            )}
            <Box
                sx={{
                    width: '100%',
                    backgroundColor:
                        section || inRightPanel ? `rgba(34, 34, 34, 0.05)` : theme.palette.background.default,
                    borderRadius: 2,
                    ml: 2,
                    mb: 2,
                    p: 2,
                    pt: 1,
                    pb: 1,
                }}
            >
                <Typography sx={{mb: 0.5, fontWeight: 600}}>{author}</Typography>
                {isLineBreakPresented ? (
                    <TextWithLineBreaks text={text} />
                ) : (
                    <Typography sx={{mb: 1}}>{text}</Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography sx={{fontWeight: 600, fontSize: 12, mr: 1}}>
                        {convertDateToCurrentLocale(dateAndTime, DATE_TIME_LABEL_FORMAT)}
                    </Typography>
                    {(isUpdated || isPublic) && (
                        <Typography sx={{ml: 1, fontSize: 12, fontWeight: 300}}>
                            {getCommentTypeLabel(t, isUpdated, isPublic)}
                        </Typography>
                    )}
                    {!inRightPanel && isCurrentUser && isEditEnabled && (
                        <>
                            <EmptyArea />
                            <UpdateComment item={item} />
                            <RemoveComment uuid={uuid} />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CommentRow;
