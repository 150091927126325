import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface TextWithLineBreaksType {
    text: string;
}

const TextWithLineBreaks: FC<TextWithLineBreaksType> = ({text}) => {
    return (
        <Box sx={{mt: 1, mb: 1}}>
            {text.split('\n').map((item: string, index: number) => (
                <Typography key={`text-row-${index}`} sx={{mb: 1}}>
                    {item}
                </Typography>
            ))}
        </Box>
    );
};

export default TextWithLineBreaks;
