import React, {FC, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DownloadSvgIcon from 'assets/icons/buttons/DownloadSvgIcon';

import {
    USER_ACCOUNT_EXPORT_LIST,
    USER_ACCOUNT_EXPORT_DOWNLOAD,
    USER_ACCOUNT_EXPORT_GENERATE,
} from 'appRedux/actions/profile';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import EmptyArea from 'components/EmptyArea/EmptyArea';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

const ExportAccountInformation: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        profile: {accountExport},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getUserAccountExports = useCallback(
        data => dispatch({type: USER_ACCOUNT_EXPORT_LIST.REQUEST, payload: data}),
        [dispatch],
    );

    const downloadExport = useCallback(
        data => dispatch({type: USER_ACCOUNT_EXPORT_DOWNLOAD.REQUEST, payload: data}),
        [dispatch],
    );

    const userAccountExportGenerate = useCallback(
        data => dispatch({type: USER_ACCOUNT_EXPORT_GENERATE.REQUEST, payload: data}),
        [dispatch],
    );

    const downloadFile = (url: string) => {
        const a = document.createElement('a');
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const onExportClick = () => {
        userAccountExportGenerate({
            showAlert,
        });
    };

    const onDownloadClick = (uuid: string) => {
        downloadExport({
            uuid,
            showAlert,
            callback: downloadFile,
        });
    };

    useEffect(() => {
        getUserAccountExports({
            showAlert,
        });
    }, []);

    return (
        <Box>
            {accountExport && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                        'common.account.exportAccountInformation.createdAt',
                    )}:`}</Typography>
                    <Typography sx={{mr: 3}}>{convertDateToCurrentLocale(accountExport.createdAt)}</Typography>
                    {accountExport.isCompleted ? (
                        <>
                            <Typography sx={{fontWeight: 700, mr: 1}}>{`${t(
                                'common.account.exportAccountInformation.expiredAt',
                            )}:`}</Typography>
                            <Typography>{convertDateToCurrentLocale(accountExport.expiredAt)}</Typography>
                        </>
                    ) : (
                        <Typography sx={{fontWeight: 700, mr: 1}}>
                            {t('common.account.exportAccountInformation.exportPreparing')}
                        </Typography>
                    )}
                    <EmptyArea />
                    <IconButton
                        disabled={!accountExport.isCompleted}
                        title={t('common.buttons.download')}
                        onClick={() => onDownloadClick(accountExport.uuid)}
                    >
                        <DownloadSvgIcon />
                    </IconButton>
                </Box>
            )}

            <Box sx={{mt: 2}}>
                <AgentSaveButton
                    isSubmit={false}
                    title={t(accountExport ? 'common.buttons.regenerateExport' : 'common.buttons.generateExport')}
                    onClick={onExportClick}
                />
            </Box>
        </Box>
    );
};

export default ExportAccountInformation;
