import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootReducer} from 'appRedux/reducers';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {IconButton} from '@mui/material';

import PopupSvgIcon from 'assets/icons/buttons/PopupSvgIcon';

import {ChatContactTypes} from 'appRedux/actions/requestChat/types';
import {RequesterCaseTagTypes} from 'appRedux/actions/requestCase/types';

import AvatarImage from 'components/UserAvatar/AvatarImage';
import {getAvatarTitle} from 'components/TopBarMenu/helper';

import {theme} from 'config/theme';
import {routes} from 'config/index';

interface ChatHeaderType {
    item: ChatContactTypes;
}

const ChatHeader: FC<ChatHeaderType> = ({item}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const {
        requestCase: {currentCase},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {caseLabel, firstName, lastName, avatarLink, avatarType} = item;

    const {id, formId, tags, status, statusTitle} = currentCase;

    const redirectToCase = () => {
        navigate(`${routes.REQUESTER_CASE}/${id}/form/${formId}`);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flex: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: 'inherit',
                pt: 1,
                pb: 2,
                borderBottom: `1px solid ${theme.palette.info.contrastText}`,
                borderRadius: 0,
                flexGrow: 1,
                mx: 2,
            }}
        >
            <AvatarImage
                size={48}
                avatarLink={avatarLink}
                avatarType={avatarType}
                avatarTitle={getAvatarTitle(firstName, lastName, '')}
            />
            <Box sx={{pl: 1.5, width: '85%'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Tooltip
                        title={caseLabel}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: 16,
                                    backgroundColor: 'rgb(97, 97, 97)',
                                },
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: 600,
                                color: theme.palette.info.main,
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mr: 1,
                            }}
                        >
                            {caseLabel}
                        </Typography>
                    </Tooltip>
                    <Box sx={{position: 'relative', width: 34, height: 34}}>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: -6,
                                width: 34,
                                height: 34,
                                borderColor: theme.palette.info.main,
                                borderWidth: 1.5,
                                borderStyle: 'solid',
                            }}
                            title={t('orguser.requesterCase.linkToCase')}
                            onClick={redirectToCase}
                        >
                            <PopupSvgIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mr: -0.5,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: theme.palette.info.main,
                            }}
                        >
                            {`${t('orguser.requesterCase.status')}: ` + statusTitle ||
                                t(`orguser.requesterCase.statusTypes.${status}`) + (tags.length ? ' |' : '')}
                        </Typography>
                    </Box>
                    {tags.map((item: RequesterCaseTagTypes, index: number) => {
                        const {tag, color} = item;
                        return (
                            <Tooltip title={tag} key={`tag-chip-${index}`}>
                                <Box
                                    sx={{
                                        width: 12,
                                        height: 12,
                                        ml: 1,
                                        borderRadius: '50%',
                                        backgroundColor: color,
                                    }}
                                />
                            </Tooltip>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default ChatHeader;
