import {PanelItemsTypes, DashboardItemsTypes} from 'appRedux/actions/analytics/types';
import {
    GET_PANELS_LIST,
    CREATE_PANEL,
    CREATE_PANEL_FOR_FORM_VERSION,
    UPDATE_PANEL,
    DELETE_PANEL,
    SET_HOMEPAGE_PANEL,
    GET_DASHBOARDS_LIST,
    CREATE_DASHBOARD,
    UPDATE_DASHBOARD,
    DELETE_DASHBOARD,
    CHANGE_DASHBOARD_PRIORITY,
} from 'appRedux/actions/analytics';

export const initialState: AnalyticsState = {
    isLoading: false,
    dashboards: [],
    panels: [],
    errors: undefined,
};

export interface AnalyticsState {
    isLoading: boolean;
    dashboards: DashboardItemsTypes[];
    panels: PanelItemsTypes[];
    errors: string[] | undefined;
}

const instance = (
    state = initialState,
    action: IActionType<PanelItemsTypes[] | DashboardItemsTypes[] | IErrors>,
): AnalyticsState => {
    switch (action.type) {
        case GET_PANELS_LIST.REQUEST:
        case CREATE_PANEL.REQUEST:
        case CREATE_PANEL_FOR_FORM_VERSION.REQUEST:
        case UPDATE_PANEL.REQUEST:
        case DELETE_PANEL.REQUEST:
        case SET_HOMEPAGE_PANEL.REQUEST:
        case GET_DASHBOARDS_LIST.REQUEST:
        case CREATE_DASHBOARD.REQUEST:
        case UPDATE_DASHBOARD.REQUEST:
        case DELETE_DASHBOARD.REQUEST:
        case CHANGE_DASHBOARD_PRIORITY.REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case GET_PANELS_LIST.SUCCESS:
        case CREATE_PANEL.SUCCESS:
        case CREATE_PANEL_FOR_FORM_VERSION.SUCCESS:
        case UPDATE_PANEL.SUCCESS:
        case DELETE_PANEL.SUCCESS:
        case SET_HOMEPAGE_PANEL.SUCCESS: {
            return {
                ...state,
                panels: action.payload as PanelItemsTypes[],
                isLoading: false,
            };
        }

        case GET_DASHBOARDS_LIST.SUCCESS:
        case CREATE_DASHBOARD.SUCCESS:
        case UPDATE_DASHBOARD.SUCCESS:
        case DELETE_DASHBOARD.SUCCESS:
        case CHANGE_DASHBOARD_PRIORITY.SUCCESS: {
            return {
                ...state,
                dashboards: action.payload as DashboardItemsTypes[],
                isLoading: false,
            };
        }

        case GET_PANELS_LIST.ERROR:
        case CREATE_PANEL.ERROR:
        case CREATE_PANEL_FOR_FORM_VERSION.ERROR:
        case UPDATE_PANEL.ERROR:
        case DELETE_PANEL.ERROR:
        case SET_HOMEPAGE_PANEL.ERROR:
        case GET_DASHBOARDS_LIST.ERROR:
        case CREATE_DASHBOARD.ERROR:
        case UPDATE_DASHBOARD.ERROR:
        case DELETE_DASHBOARD.ERROR:
        case CHANGE_DASHBOARD_PRIORITY.ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload as any,
            };
        }

        default:
            return state;
    }
};

export default instance;
