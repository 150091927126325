import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {UPDATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';
import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {ResourceFieldBookframeItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {getResourceFieldWeekdayLabel} from 'components/Forms/ResourceField/helper';

import {TAB_STEP_FIVE} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {theme, MAIN_CLIENT_COLOR} from 'config/theme';
import {STATUS_OPENING} from 'config/index';

interface CalendarBookframeItemType {
    currentCalendar: RequesterCaseHasCalendarTypes;
    bookframe: ResourceFieldBookframeItemTypes;
    setCurrentTab: (value: number) => void;
}

const CalendarBookframeItem: FC<CalendarBookframeItemType> = ({currentCalendar, bookframe, setCurrentTab}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {formField, resourceField, resourceFieldCalendar, resourceFieldCalendarSlot, calendarDate} = currentCalendar;

    const {showAlert} = useContext(AlertContext);

    const {
        requestCase: {
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, weekday, startTime, endTime} = bookframe;

    const updateRequesterCaseCalendar = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    const isAlreadySelected = currentCalendar.resourceFieldCalendarBookframe === id;

    const onCalendarBookframeClick = () => {
        if (status === STATUS_OPENING) {
            updateRequesterCaseCalendar({
                uuid: currentCalendar.uuid,
                formField,
                resourceField,
                resourceFieldCalendar,
                resourceFieldCalendarSlot,
                calendarDate: moment(calendarDate).format('YYYY-MM-DD'),
                resourceFieldCalendarBookframe: id,
                showAlert,
                callback: () => {
                    setTimeout(() => {
                        setCurrentTab(TAB_STEP_FIVE);
                    }, 500);
                },
            });
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                mb: 1,
                backgroundColor: isAlreadySelected ? MAIN_CLIENT_COLOR : theme.palette.secondary.contrastText,
                borderRadius: 2,
                cursor: isAlreadySelected || status !== STATUS_OPENING ? 'initial' : 'pointer',
                '&:hover': {
                    backgroundColor: isAlreadySelected
                        ? MAIN_CLIENT_COLOR
                        : status === STATUS_OPENING
                        ? theme.palette.info.main
                        : theme.palette.secondary.contrastText,
                    '& p': {
                        color:
                            status === STATUS_OPENING || isAlreadySelected
                                ? theme.palette.background.default
                                : theme.palette.info.main,
                    },
                },
            }}
            onClick={onCalendarBookframeClick}
        >
            <Typography
                sx={{
                    fontWeight: 700,
                    color: isAlreadySelected ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {t(getResourceFieldWeekdayLabel(weekday))}
            </Typography>
            <Typography
                sx={{
                    color: isAlreadySelected ? theme.palette.background.default : theme.palette.info.main,
                }}
            >
                {`${startTime} - ${endTime}`}
            </Typography>
        </Box>
    );
};

export default CalendarBookframeItem;
