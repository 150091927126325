import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import {FormVersionTypes} from 'appRedux/actions/forms/types';

import {PermissionContext} from 'contexts/permission/context';

import ReleaseVersionButton from 'pages/admin/formVersion/ReleaseVersionButton';
import ReleasedVersionButtonsPanel from 'pages/admin/formVersion/ReleasedVersionButtonsPanel';
import EditFormVersionButton from 'pages/admin/updateForm/partials/FormVersions/EditFormVersionButton';

import {convertDateToCurrentLocale} from 'helpers/dateTimeConvertingHelper';

interface FormVersionInformationType {
    item: FormVersionTypes;
    isForceEditClicked: boolean;
    setIsForceEditClicked: (value: boolean) => void;
}

const FormVersionInformation: FC<FormVersionInformationType> = ({item, isForceEditClicked, setIsForceEditClicked}) => {
    const [t] = useTranslation();

    const {isFormEditEnabled} = useContext(PermissionContext);

    const {title, description, releasedAt, updatedAt, casesQty} = item;

    return (
        <Box>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.forms.title')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>
                        {title}
                        <EditFormVersionButton item={item} />
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.forms.description')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>
                        {description}
                        <EditFormVersionButton item={item} />
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.forms.casesQty')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{casesQty}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.forms.releasedAt')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{releasedAt ? convertDateToCurrentLocale(releasedAt) : t('common.none')}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 2}}>
                <Grid item sm={3}>
                    <Typography sx={{fontWeight: 600}}>{t('orguser.forms.updatedAt')}</Typography>
                </Grid>
                <Grid item sm={9}>
                    <Typography>{updatedAt ? convertDateToCurrentLocale(updatedAt) : t('common.none')}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={12}>
                    {casesQty > 0 && isFormEditEnabled && (
                        <Box sx={{mt: 1, mb: 2}}>
                            <Alert severity={'error'} sx={{width: '100%'}}>
                                <Typography>{t('orguser.forms.forceVersionEditAlertText')}</Typography>
                            </Alert>
                        </Box>
                    )}
                    {isFormEditEnabled && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            {!releasedAt ? (
                                <ReleaseVersionButton item={item} />
                            ) : (
                                <ReleasedVersionButtonsPanel
                                    item={item}
                                    isForceEditClicked={isForceEditClicked}
                                    setIsForceEditClicked={setIsForceEditClicked}
                                />
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default FormVersionInformation;
