import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {RootReducer} from 'appRedux/reducers';

import RequesterCaseCalendarForm from 'components/Forms/RequesterCaseCalendarForm/RequesterCaseCalendarForm';

import {
    REQUESTER_CASE_HAS_CALENDAR_CREATED,
    getDateSelectionPossibleWeekdays,
    getDateSelectionPossibleWeekdaysString,
} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';
import {useSelector} from 'react-redux';

interface CalendarStepThreeType {
    currentCalendar: RequesterCaseHasCalendarTypes;
    setCurrentTab: (value: number) => void;
}

const CalendarStepThree: FC<CalendarStepThreeType> = ({currentCalendar, setCurrentTab}) => {
    const [t] = useTranslation();

    const {
        resourceFields: {allCalendarFormItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const calendarInfo = allCalendarFormItems.find(item => item.id === currentCalendar.resourceFieldCalendar);

    if (!calendarInfo) return null;

    const possibleWeekdays = getDateSelectionPossibleWeekdays(calendarInfo.bookframes);
    const possibleWeekdaysString = getDateSelectionPossibleWeekdaysString(t, possibleWeekdays);

    if (possibleWeekdays.length === 0) {
        return (
            <Box>
                <Typography>{t('requester.casePage.calendarSlotRegistrationImpossible')}</Typography>
            </Box>
        );
    }
    return (
        <Box>
            {currentCalendar.status === REQUESTER_CASE_HAS_CALENDAR_CREATED && (
                <Alert sx={{mb: 2}} severity={'info'}>
                    <Typography>{`${t(
                        'requester.casePage.calendarDateSelectionPossible',
                    )}: ${possibleWeekdaysString}`}</Typography>
                </Alert>
            )}
            <RequesterCaseCalendarForm
                currentCalendar={currentCalendar}
                setCurrentTab={setCurrentTab}
                possibleWeekdays={possibleWeekdays}
            />
        </Box>
    );
};

export default CalendarStepThree;
