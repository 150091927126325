import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {ResourceFieldCalendarSlotFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationCalendarSlotSchema} from 'components/Forms/ResourceField/validation';

interface ResourceFieldCalendarSlotFormType {
    initialValues: ResourceFieldCalendarSlotFormTypes;
    onSubmitClicked: (values: ResourceFieldCalendarSlotFormTypes) => void;
}

const ResourceFieldCalendarSlotForm: FC<ResourceFieldCalendarSlotFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationCalendarSlotSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ResourceFieldCalendarSlotFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="name"
                            label={t('orguser.resourceFields.title')}
                            placeholder={t('orguser.resourceFields.title')}
                            component={FormikTextInput}
                        />
                        <Field
                            required
                            name="duration"
                            type={'number'}
                            label={t('orguser.resourceFields.duration')}
                            placeholder={t('orguser.resourceFields.duration')}
                            component={FormikTextInput}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldCalendarSlotForm;
