import {FormItemBasic} from 'appRedux/actions/forms/types';
import {RoleFilterRequestTypes} from 'appRedux/actions/roles/types';

export const FILTER_FOR_FORMS_OPERATIONS = 1;
export const FILTER_FOR_TRANSLATIONS_OPERATIONS = 2;
export const FILTER_FOR_CASES_OPERATIONS = 3;
export const FILTER_FOR_INVITES_OPERATIONS = 4;
export const FILTER_FOR_ANALYTIC_OPERATIONS = 5;

export const REQUESTER_MODE_NOT_SET = 0;
export const REQUESTER_MODE_ALLOWED = 1;
export const REQUESTER_MODE_FORCED = 2;

export const getFilteredForms = (
    formsList: FormItemBasic[],
    filterType: number,
    roleFilters: RoleFilterRequestTypes[],
): string => {
    const ids: number[] = [];
    if (roleFilters && Array.isArray(roleFilters)) {
        roleFilters.forEach((roleFilter: RoleFilterRequestTypes) => {
            if (roleFilter.type === filterType) {
                ids.push(roleFilter.form);
            }
        });
    }
    const results: FormItemBasic[] = [];
    if (formsList && Array.isArray(formsList)) {
        formsList.forEach((form: FormItemBasic) => {
            if (ids.indexOf(Number(form.id)) !== -1) {
                results.push(form);
            }
        });
    }

    const filtersArray: string[] = [];
    if (results && results.length > 0) {
        results.forEach((item: FormItemBasic) => {
            filtersArray.push(item.handlePrefix ? item.handlePrefix : item.title);
        });
    }

    return filtersArray.join(', ');
};

export const requesterModes = [
    'orguser.roles.parameters.requesterModes.notSet',
    'orguser.roles.parameters.requesterModes.allowed',
    'orguser.roles.parameters.requesterModes.forced',
];

export const requesterModesExplanation = [
    'orguser.roles.parameters.requesterModesExplanation.notSet',
    'orguser.roles.parameters.requesterModesExplanation.allowed',
    'orguser.roles.parameters.requesterModesExplanation.forced',
];
