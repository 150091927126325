import {
    PanelItemsTypes,
    PanelCreateRequestTypes,
    PanelCreateForFormVersionRequestTypes,
    PanelUpdateRequestTypes,
    PanelsListRequestTypes,
    PanelHomepageRequestTypes,
    PanelDeleteRequestTypes,
    DashboardItemsTypes,
    DashboardsListRequestTypes,
    DashboardCreateRequestTypes,
    DashboardUpdateRequestTypes,
    DashboardDeleteRequestTypes,
    ChangeDashboardPriorityRequestTypes,
} from 'appRedux/actions/analytics/types';

import {requestTypeCreator} from 'helpers/index';

export const GET_PANELS_LIST = requestTypeCreator('GET_PANELS_LIST');
export const CREATE_PANEL = requestTypeCreator('CREATE_PANEL');
export const CREATE_PANEL_FOR_FORM_VERSION = requestTypeCreator('CREATE_PANEL_FOR_FORM_VERSION');
export const UPDATE_PANEL = requestTypeCreator('UPDATE_PANEL');
export const DELETE_PANEL = requestTypeCreator('DELETE_PANEL');
export const SET_HOMEPAGE_PANEL = requestTypeCreator('SET_HOMEPAGE_PANEL');

export const GET_DASHBOARDS_LIST = requestTypeCreator('GET_DASHBOARDS_LIST');
export const CREATE_DASHBOARD = requestTypeCreator('CREATE_DASHBOARD');
export const UPDATE_DASHBOARD = requestTypeCreator('UPDATE_DASHBOARD');
export const DELETE_DASHBOARD = requestTypeCreator('DELETE_DASHBOARD');
export const CHANGE_DASHBOARD_PRIORITY = requestTypeCreator('CHANGE_DASHBOARD_PRIORITY');

export const getPanelsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_PANELS_LIST.ERROR,
    }),
    request: (payload: PanelsListRequestTypes): IActionType<PanelsListRequestTypes> => ({
        payload,
        type: GET_PANELS_LIST.REQUEST,
    }),
    success: (payload: PanelItemsTypes[]): IActionType<PanelItemsTypes[]> => ({
        payload,
        type: GET_PANELS_LIST.SUCCESS,
    }),
};

export const createPanel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_PANEL.ERROR,
    }),
    request: (payload: PanelCreateRequestTypes): IActionType<PanelCreateRequestTypes> => ({
        payload,
        type: CREATE_PANEL.REQUEST,
    }),
    success: (payload: PanelItemsTypes[]): IActionType<PanelItemsTypes[]> => ({
        payload,
        type: CREATE_PANEL.SUCCESS,
    }),
};

export const createPanelForFormVersion = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_PANEL_FOR_FORM_VERSION.ERROR,
    }),
    request: (payload: PanelCreateForFormVersionRequestTypes): IActionType<PanelCreateForFormVersionRequestTypes> => ({
        payload,
        type: CREATE_PANEL_FOR_FORM_VERSION.REQUEST,
    }),
    success: (payload: PanelItemsTypes[]): IActionType<PanelItemsTypes[]> => ({
        payload,
        type: CREATE_PANEL_FOR_FORM_VERSION.SUCCESS,
    }),
};

export const updatePanel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_PANEL.ERROR,
    }),
    request: (payload: PanelUpdateRequestTypes): IActionType<PanelUpdateRequestTypes> => ({
        payload,
        type: UPDATE_PANEL.REQUEST,
    }),
    success: (payload: PanelItemsTypes[]): IActionType<PanelItemsTypes[]> => ({
        payload,
        type: UPDATE_PANEL.SUCCESS,
    }),
};

export const deletePanel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_PANEL.ERROR,
    }),
    request: (payload: PanelDeleteRequestTypes): IActionType<PanelDeleteRequestTypes> => ({
        payload,
        type: DELETE_PANEL.REQUEST,
    }),
    success: (payload: PanelItemsTypes[]): IActionType<PanelItemsTypes[]> => ({
        payload,
        type: DELETE_PANEL.SUCCESS,
    }),
};

export const setHomepagePanel = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: SET_HOMEPAGE_PANEL.ERROR,
    }),
    request: (payload: PanelHomepageRequestTypes): IActionType<PanelHomepageRequestTypes> => ({
        payload,
        type: SET_HOMEPAGE_PANEL.REQUEST,
    }),
    success: (payload: PanelItemsTypes[]): IActionType<PanelItemsTypes[]> => ({
        payload,
        type: SET_HOMEPAGE_PANEL.SUCCESS,
    }),
};

export const getDashboardsList = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: GET_DASHBOARDS_LIST.ERROR,
    }),
    request: (payload: DashboardsListRequestTypes): IActionType<DashboardsListRequestTypes> => ({
        payload,
        type: GET_DASHBOARDS_LIST.REQUEST,
    }),
    success: (payload: DashboardItemsTypes[]): IActionType<DashboardItemsTypes[]> => ({
        payload,
        type: GET_DASHBOARDS_LIST.SUCCESS,
    }),
};

export const createDashboard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CREATE_DASHBOARD.ERROR,
    }),
    request: (payload: DashboardCreateRequestTypes): IActionType<DashboardCreateRequestTypes> => ({
        payload,
        type: CREATE_DASHBOARD.REQUEST,
    }),
    success: (payload: DashboardItemsTypes[]): IActionType<DashboardItemsTypes[]> => ({
        payload,
        type: CREATE_DASHBOARD.SUCCESS,
    }),
};

export const updateDashboard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: UPDATE_DASHBOARD.ERROR,
    }),
    request: (payload: DashboardUpdateRequestTypes): IActionType<DashboardUpdateRequestTypes> => ({
        payload,
        type: UPDATE_DASHBOARD.REQUEST,
    }),
    success: (payload: DashboardItemsTypes[]): IActionType<DashboardItemsTypes[]> => ({
        payload,
        type: UPDATE_DASHBOARD.SUCCESS,
    }),
};

export const deleteDashboard = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: DELETE_DASHBOARD.ERROR,
    }),
    request: (payload: DashboardDeleteRequestTypes): IActionType<DashboardDeleteRequestTypes> => ({
        payload,
        type: DELETE_DASHBOARD.REQUEST,
    }),
    success: (payload: DashboardItemsTypes[]): IActionType<DashboardItemsTypes[]> => ({
        payload,
        type: DELETE_DASHBOARD.SUCCESS,
    }),
};

export const changeDashboardPriority = {
    error: (payload: IErrors): IActionType<IErrors> => ({
        payload,
        type: CHANGE_DASHBOARD_PRIORITY.ERROR,
    }),
    request: (payload: ChangeDashboardPriorityRequestTypes): IActionType<ChangeDashboardPriorityRequestTypes> => ({
        payload,
        type: CHANGE_DASHBOARD_PRIORITY.REQUEST,
    }),
    success: (payload: DashboardItemsTypes[]): IActionType<DashboardItemsTypes[]> => ({
        payload,
        type: CHANGE_DASHBOARD_PRIORITY.SUCCESS,
    }),
};
