import React, {FC} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';

import {ResourceFieldSlotFormTypes} from 'appRedux/actions/resourceFields/types';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import MdxEditorSmallField from 'components/MdxEditorField/MdxEditorSmallField';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {validationSlotSchema} from 'components/Forms/ResourceField/validation';

interface ResourceFieldSlotFormType {
    initialValues: ResourceFieldSlotFormTypes;
    onSubmitClicked: (values: ResourceFieldSlotFormTypes) => void;
}

const ResourceFieldSlotForm: FC<ResourceFieldSlotFormType> = ({initialValues, onSubmitClicked}) => {
    const [t] = useTranslation();

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSlotSchema}
            onSubmit={onSubmitClicked}
        >
            {(formik: FormikProps<ResourceFieldSlotFormTypes>) => {
                return (
                    <Form>
                        <Field
                            required
                            name="title"
                            label={t('orguser.resourceFields.title')}
                            placeholder={t('orguser.resourceFields.title')}
                            component={FormikTextInput}
                        />
                        <Box sx={{mt: 1}}>
                            <MdxEditorSmallField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('orguser.resourceFields.description')}
                            />
                        </Box>
                        <Field
                            required
                            name="eventName"
                            label={t('orguser.resourceFields.eventName')}
                            placeholder={t('orguser.resourceFields.eventName')}
                            component={FormikTextInput}
                        />
                        <Box sx={{mt: 1, mb: 1}}>
                            <Field
                                required
                                type="number"
                                name="qty"
                                label={t('orguser.resourceFields.qty')}
                                placeholder={t('orguser.resourceFields.qty')}
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <AgentSaveButton isSubmit />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResourceFieldSlotForm;
