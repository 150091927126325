import React, {FC, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import EnvelopmentSvgIcon from 'assets/icons/buttons/EnvelopmentSvgIcon';

import {LanguageItemTypes} from 'appRedux/actions/admin/types';
import {WorkflowStatusItemTypes} from 'appRedux/actions/workflow/types';
import {CHANGE_WORKFLOW_STATUS_PRIORITY} from 'appRedux/actions/workflow';

import {AlertContext} from 'contexts/alert/context';

import DraggableTableRow from 'components/AdminScreenComponents/DraggableTableRow';

import DeleteWorkflowStatusButton from 'pages/admin/updateWorkflow/partials/DeleteWorkflowStatusButton';
import UpdateWorkflowStatusPermissionsButton from 'pages/admin/updateWorkflow/partials/UpdateWorkflowStatusPermissionsButton';
import WorkflowStatusRulesButton from 'pages/admin/updateWorkflow/partials/rules/WorkflowStatusRulesButton';
import WorkflowStatusTabs from 'pages/admin/updateWorkflow/partials/tabs/WorkflowStatusTabs';
import WorkflowStatusTasksButton from 'pages/admin/updateWorkflow/partials/tasks/WorkflowStatusTasksButton';
import InternalNameButton from 'pages/admin/updateWorkflow/partials/InternalNameButton';
import WorkflowActionBadge from 'pages/admin/updateWorkflow/partials/WorkflowActionBadge';

import {routes} from 'config/index';

interface WorkflowStatusesListRowType {
    item: WorkflowStatusItemTypes;
    currentLanguage: string;
    dragItem?: string;
    setDragItem: React.Dispatch<React.SetStateAction<string>>;
    dropTo?: number;
    setDropTo: React.Dispatch<React.SetStateAction<number>>;
    currentOrganizationDefaultLanguage: LanguageItemTypes;
    isAdvancedMode: boolean;
}

const WorkflowStatusesListRow: FC<WorkflowStatusesListRowType> = ({
    item,
    currentLanguage,
    dragItem,
    setDragItem,
    dropTo,
    setDropTo,
    isAdvancedMode,
}) => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {uuid} = item;

    const changePriority = useCallback(
        data => dispatch({type: CHANGE_WORKFLOW_STATUS_PRIORITY.REQUEST, payload: data}),
        [dispatch],
    );

    const redirectToEvents = () => {
        navigate(`${routes.WORKFLOW}/${uuid}/events`);
    };

    const onDragEnd = () => {
        changePriority({
            uuid: dragItem,
            priority: dropTo,
            showAlert,
            callback: () => setDragItem(''),
        });
    };

    return (
        <DraggableTableRow
            item={item}
            dragItem={dragItem}
            setDragItem={setDragItem}
            dropTo={dropTo}
            setDropTo={setDropTo}
            onDragEnd={onDragEnd}
        >
            <TableCell>
                <WorkflowStatusTabs item={item} currentLanguage={String(currentLanguage)} />
            </TableCell>
            <TableCell>
                <IconButton title={t('orguser.workflows.workflowStatusEvents')} onClick={redirectToEvents}>
                    <WorkflowActionBadge badgeContent={item.templatesNumber}>
                        <EnvelopmentSvgIcon />
                    </WorkflowActionBadge>
                </IconButton>
                <UpdateWorkflowStatusPermissionsButton item={item} currentLanguage={currentLanguage} />
                <WorkflowStatusRulesButton item={item} />
                <WorkflowStatusTasksButton item={item} />
                {isAdvancedMode && <InternalNameButton item={item} />}
                <DeleteWorkflowStatusButton item={item} />
            </TableCell>
        </DraggableTableRow>
    );
};

export default WorkflowStatusesListRow;
