import React, {FC, useCallback, useEffect, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';

import {RoleCommonRequestTypes, RoleFilterRequestTypes} from 'appRedux/actions/roles/types';
import {FORMS_LIST} from 'appRedux/actions/forms';
import {RootReducer} from 'appRedux/reducers';

import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import RoleFormCheckbox from 'components/AgentScreenComponents/_form/RoleFormCheckbox';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';
import RoleFormDisabledCheckbox from 'components/AgentScreenComponents/_form/RoleFormDisabledCheckbox';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import {roleValidationSchema} from 'components/Forms/RoleForm/validation';
import AddFormForFilters from 'components/Forms/RoleForm/AddFormForFilters';
import {
    FILTER_FOR_ANALYTIC_OPERATIONS,
    FILTER_FOR_CASES_OPERATIONS,
    FILTER_FOR_FORMS_OPERATIONS,
    FILTER_FOR_INVITES_OPERATIONS,
    FILTER_FOR_TRANSLATIONS_OPERATIONS,
    requesterModes,
    requesterModesExplanation,
} from 'components/Forms/RoleForm/helper';

interface RoleFormType {
    organizationId: number;
    initialValues: RoleCommonRequestTypes;
    onSubmitClicked: (values: RoleCommonRequestTypes) => void;
    isLoading: boolean;
    isCreate?: boolean;
}

const RoleForm: FC<RoleFormType> = ({initialValues, organizationId, onSubmitClicked, isCreate}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const [roleFilters, setRoleFilters] = useState<RoleFilterRequestTypes[]>([]);

    const getFormsList = useCallback(data => dispatch({type: FORMS_LIST.REQUEST, payload: data}), [dispatch]);

    const {
        admin: {organizationList},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const currentOrganization = organizationList
        ? organizationList.find(item => Number(item.id) === Number(organizationId))
        : null;

    useEffect(() => {
        getFormsList({});
    }, []);

    useEffect(() => {
        if (initialValues) {
            setRoleFilters(initialValues.roleFilters);
        }
    }, [initialValues]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={roleValidationSchema}
            onSubmit={values => {
                onSubmitClicked({
                    ...values,
                    roleFilters: roleFilters,
                });
            }}
        >
            {(formik: FormikProps<RoleCommonRequestTypes>) => {
                const explanationText = get(requesterModesExplanation, formik.values.requesterMode, null);
                return (
                    <Form>
                        <Box sx={{width: '50%'}}>
                            <Field
                                required
                                name="title"
                                placeholder={t('orguser.roles.parameters.title')}
                                component={FormikTextInput}
                            />
                        </Box>
                        {currentOrganization && currentOrganization.isRequesterModeEnabled && (
                            <Box sx={{width: '50%'}}>
                                <Typography sx={{fontWeight: 700, mb: 1, mt: 2}}>
                                    {t('orguser.roles.parameters.requesterMode')}
                                </Typography>
                                {explanationText && (
                                    <Alert severity={'info'} sx={{mb: 1}}>
                                        <Typography>{t(explanationText)}</Typography>
                                    </Alert>
                                )}
                                <FormControl
                                    sx={{
                                        borderRadius: 3,
                                        mb: 1,
                                    }}
                                    required
                                    fullWidth
                                >
                                    <Select
                                        sx={{
                                            height: 50,
                                        }}
                                        name={'requesterMode'}
                                        value={formik.values.requesterMode}
                                        variant="standard"
                                        onChange={formik.handleChange}
                                    >
                                        {requesterModes.map((item: string, index: number) => {
                                            return (
                                                <MenuItem key={`menuItem-${index}`} value={index}>
                                                    <Typography>{t(item)}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                        <Table sx={{mt: 2}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>
                                        {t('orguser.roles.parameters.management')}
                                    </TableCell>
                                    <TableCell sx={{fontWeight: 600}}>{t('superadmin.permissions.view')}</TableCell>
                                    <TableCell sx={{fontWeight: 600}}>{t('superadmin.permissions.create')}</TableCell>
                                    <TableCell sx={{fontWeight: 600}}>{t('superadmin.permissions.update')}</TableCell>
                                    <TableCell sx={{fontWeight: 600}}>{t('superadmin.permissions.delete')}</TableCell>
                                    <TableCell sx={{fontWeight: 600}} width={400}>
                                        {t('orguser.roles.parameters.filter')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>
                                        {t('orguser.roles.roleType.organization')}
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isOrganizationViewEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RoleFormDisabledCheckbox />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isOrganizationEditEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RoleFormDisabledCheckbox />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>{t('orguser.roles.roleType.analytic')}</TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isAnalyticViewEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isAnalyticCreateEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isAnalyticEditEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isAnalyticDeleteEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <AddFormForFilters
                                            organizationId={organizationId}
                                            filterType={FILTER_FOR_ANALYTIC_OPERATIONS}
                                            roleFilters={roleFilters}
                                            setRoleFilters={setRoleFilters}
                                            formik={formik}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>{t('orguser.roles.roles')}</TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isRoleViewEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isRoleCreateEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isRoleEditEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isRoleDeleteEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>{t('orguser.roles.roleType.user')}</TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isUserViewEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isUserCreateEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isUserEditEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isUserDeleteEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>{t('orguser.roles.roleType.form')}</TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isFormViewEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isFormCreateEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isFormEditEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isFormDeleteEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <AddFormForFilters
                                            organizationId={organizationId}
                                            filterType={FILTER_FOR_FORMS_OPERATIONS}
                                            roleFilters={roleFilters}
                                            setRoleFilters={setRoleFilters}
                                            formik={formik}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>
                                        {t('orguser.roles.roleType.translation')}
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isTranslationViewEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RoleFormDisabledCheckbox />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isTranslationEditEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isTranslationDeleteEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <AddFormForFilters
                                            organizationId={organizationId}
                                            filterType={FILTER_FOR_TRANSLATIONS_OPERATIONS}
                                            roleFilters={roleFilters}
                                            setRoleFilters={setRoleFilters}
                                            formik={formik}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>{t('orguser.roles.roleType.invites')}</TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isInviteViewEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isInviteCreateEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <RoleFormDisabledCheckbox />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            type="checkbox"
                                            name="isInviteDeleteEnabled"
                                            component={RoleFormCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <AddFormForFilters
                                            organizationId={organizationId}
                                            filterType={FILTER_FOR_INVITES_OPERATIONS}
                                            roleFilters={roleFilters}
                                            setRoleFilters={setRoleFilters}
                                            formik={formik}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 600}}>{t('orguser.roles.roleType.cases')}</TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isCaseViewEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <RoleFormDisabledCheckbox />
                                    </TableCell>
                                    <TableCell>
                                        <Field type="checkbox" name="isCaseEditEnabled" component={RoleFormCheckbox} />
                                    </TableCell>
                                    <TableCell>
                                        <RoleFormDisabledCheckbox />
                                    </TableCell>
                                    <TableCell>
                                        <AddFormForFilters
                                            organizationId={organizationId}
                                            filterType={FILTER_FOR_CASES_OPERATIONS}
                                            roleFilters={roleFilters}
                                            setRoleFilters={setRoleFilters}
                                            formik={formik}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Box sx={{mt: 3}}>
                            <Field
                                type="checkbox"
                                name="isActive"
                                label={t('orguser.roles.parameters.isActive')}
                                component={FormBuilderCheckboxReverse}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                                mb: 2,
                            }}
                        >
                            <AgentSaveButton
                                isSubmit
                                title={t(isCreate ? 'common.buttons.add' : 'common.buttons.saveButton')}
                            />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RoleForm;
