import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {ResourceFieldCalendarSlotItemTypes} from 'appRedux/actions/resourceFields/types';
import {RootReducer} from 'appRedux/reducers';

import CalendarSlotItem from 'pages/client/form/fieldTypes/resourceFieldCalendar/CalendarSlotItem';

interface CalendarStepTwoType {
    currentCalendar: RequesterCaseHasCalendarTypes;
    setCurrentTab: (value: number) => void;
}

const CalendarStepTwo: FC<CalendarStepTwoType> = ({currentCalendar, setCurrentTab}) => {
    const [t] = useTranslation();

    const {
        resourceFields: {allCalendarFormItems},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const calendarInfo = allCalendarFormItems.find(item => item.id === currentCalendar.resourceFieldCalendar);

    if (!calendarInfo) return null;

    const slots = calendarInfo.slots;

    return (
        <Box>
            {slots.length > 0 ? (
                slots.map((slot: ResourceFieldCalendarSlotItemTypes) => {
                    return (
                        <CalendarSlotItem
                            key={`calendar-slot-${slot.uuid}`}
                            currentCalendar={currentCalendar}
                            slot={slot}
                            setCurrentTab={setCurrentTab}
                        />
                    );
                })
            ) : (
                <Typography>{t('common.none')}</Typography>
            )}
        </Box>
    );
};

export default CalendarStepTwo;
