import React, {FC, useCallback, useContext, useRef, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import {RequesterCaseCalendarFormTypes, RequesterCaseHasCalendarTypes} from 'appRedux/actions/requestCase/types';
import {UPDATE_REQUESTER_CASE_CALENDAR} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import {AlertContext} from 'contexts/alert/context';

import {getInitialDate} from 'components/Forms/RequesterCaseCalendarForm/helper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';

import {TAB_STEP_FOUR} from 'pages/client/form/fieldTypes/resourceFieldCalendar/helper';

import {DATEPICKER_FORMAT, STATUS_OPENING} from 'config/index';
import {ERROR_TEXT_COLOR} from 'config/theme';

const styles = () => {
    const commonStyles = {
        fontSize: 16,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        padding: 0,
        height: 42,
        width: '100%',
        '&:hover': {
            borderColor: '#222222',
            borderWidth: 1.5,
        },
        '&:focus': {
            outline: 'none',
            borderColor: '#222222',
            borderWidth: 2,
        },
    };

    return createStyles({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        datepicker: {
            ...commonStyles,
            backgroundColor: `rgba(34, 34, 34, 0.05)`,
            borderBottomColor: `rgba(34, 34, 34, 0.05)`,
        },
    });
};

interface ComponentType {
    currentCalendar: RequesterCaseHasCalendarTypes;
    setCurrentTab: (value: number) => void;
    possibleWeekdays: number[];
}

const RequesterCaseCalendarForm: FC<ComponentType & WithStyles<typeof styles>> = ({
    classes,
    currentCalendar,
    setCurrentTab,
    possibleWeekdays,
}) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const {showAlert} = useContext(AlertContext);

    const {
        requestCase: {
            currentCase: {status},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {uuid, formField, resourceField, resourceFieldCalendar, resourceFieldCalendarSlot, calendarDate} =
        currentCalendar;

    const formRef = useRef<FormikProps<RequesterCaseCalendarFormTypes> | null>(null);

    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(getInitialDate(currentCalendar));

    const handleChange = (value: Date): void => {
        setIsError(false);
        setSelectedDate(value);
        if (!possibleWeekdays.includes(value.getDay())) {
            setIsError(true);
            setErrorMessage(t('messages.error.calendarSlotWrongWeekday'));
        }
    };

    const updateRequesterCaseCalendar = useCallback(
        data => dispatch({type: UPDATE_REQUESTER_CASE_CALENDAR.REQUEST, payload: data}),
        [dispatch],
    );

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                formField,
                resourceField,
                resourceFieldCalendar,
                resourceFieldCalendarSlot,
                calendarDate,
                resourceFieldCalendarBookframe: null,
            }}
            enableReinitialize
            onSubmit={values => {
                updateRequesterCaseCalendar({
                    uuid,
                    showAlert,
                    ...values,
                    calendarDate: moment(selectedDate).format('YYYY-MM-DD'),
                    callback: () => {
                        setTimeout(() => {
                            setCurrentTab(TAB_STEP_FOUR);
                        }, 500);
                    },
                });
            }}
        >
            {(formik: FormikProps<RequesterCaseCalendarFormTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'flex-end',
                                    mt: 3,
                                    mb: 2,
                                    '& .react-datepicker-wrapper': {
                                        flex: 1,
                                    },
                                }}
                            >
                                <DatePicker
                                    className={classes.datepicker}
                                    selected={selectedDate}
                                    onChange={handleChange}
                                    dateFormat={DATEPICKER_FORMAT}
                                    disabled={status !== STATUS_OPENING}
                                    showYearDropdown
                                />
                            </Box>
                            {isError && errorMessage && (
                                <Typography sx={{color: ERROR_TEXT_COLOR, fontSize: 14, mt: 1}}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2,
                            }}
                        >
                            <AgentSaveButton isSubmit isDisabled={isError} />
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withStyles(styles)(RequesterCaseCalendarForm);
